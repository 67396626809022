import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LruRestrictedFeatureGuard  {

  constructor(private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    const routeStart = next.url.map(urlSegment => urlSegment.path)[0];
    // Lru users cannot access Images tab
    if (routeStart === 'images' && this.userService.isLru()) {
      return false;
    }
    return true;
  }
}
