import { ICounter } from 'app/core/interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  startOfToday(): Date {
    return this.startOfDay(new Date());
  }

  endOfToday(): Date {
    return this.endOfDay(new Date());
  }

  endOfDay(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }

  startOfDay(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  addYears(yearsAgo: number): Date {
    const now: Date = new Date();
    return new Date(now.setFullYear(now.getFullYear() + yearsAgo));
  }

  addDays(date: Date, days: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
  }

  utcToLocal(utcDate: Date): Date {
    return new Date(`${utcDate.toString()} UTC`);
  }

  // Used to convert saved utc hour min to valid utc date
  // That will be converted to local time
  createUtcDateFromHourMinute(hour: number, minute: number): Date {
    const now: Date = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute, 0, 0);
  }

  // Gets current time to nearest quarter
  // Eg 9:10 -> 9:15
  timeToNearestQuarter(): Date {
    return new Date(Math.floor(new Date().getTime() / 900000) * 900000);
  }

  addMinsToDate(date: Date, mins: number): Date {
    return new Date(date.getTime() + mins*60000);
  }

  // Returns PST/IST/MST ect
  usersAbbrTimeZone(): string {
    return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  }

  convertDateToTimeOnlyString(date: Date): string {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  // Returns Total seconds since UTC 0:00
  convertToSecondsFromMidnight(date: Date): number {
    if (date) {
      const today = new Date(date);
      const utcHours = today.getUTCHours();
      const utcMins = today.getUTCMinutes();
      const utcSeconds = today.getUTCSeconds();
      return ((utcHours * 3600) + (utcMins * 60) + utcSeconds);
    }
    return 0;
  }

  converToDateTimeFromMidnight(timeInSeconds: number): Date {
    if (timeInSeconds > 0) {
      const date = new Date();
      // Converting date to UTC 0:00
      date.setUTCHours(0, 0, 0, 0);
      date.setSeconds(timeInSeconds);
      return date;
    }
    return new Date();
  }

  getCurrentDateTime(): Date {
    return new Date();
  }

  getDateTimeHoursAgo(hours: number): Date {
    return new Date(this.getCurrentDateTime().setHours(this.getCurrentDateTime().getHours() - hours));
  }

  getDateTimeDaysAgo(days: number): Date {
    return new Date(this.getCurrentDateTime().setHours(this.getCurrentDateTime().getHours() - (days * 24)));
  }

  getDateTimeWeeksAgo(weeks: number): Date {
    return new Date(this.getCurrentDateTime().setHours(this.getCurrentDateTime().getHours() - (weeks * 24 * 7)));
  }

  getDateTimeMonthsAgo(months: number): Date {
    return new Date(this.getCurrentDateTime().setMonth(this.getCurrentDateTime().getMonth() - months));
  }

  getDaysDifference(startDate: Date, endDate: Date): number {
    const diff = Math.abs(startDate.getTime() - endDate.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  startOfCurrentHour(): number {
    return this.getCurrentDateTime().setMinutes(0, 0, 0);
  }

  startOfCurrentWeek(): number {
    const date = this.getCurrentDateTime();
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 0);
    date.setHours(0, 0, 0, 0);
    return date.setDate(diff);
  }

  startOfCurrentMonth(): number {
    const date = this.getCurrentDateTime();
    date.setHours(0, 0, 0, 0);
    return date.setMonth(date.getMonth(), 1);
  }

  static getDurationCounter(startTime?: string | Date, endTime?: string | Date): ICounter | undefined {
    const startTimeDate = startTime ? new Date(startTime) : undefined;
    const endTimeDate = endTime ? new Date(endTime) : undefined;
    if (!startTimeDate || !endTimeDate) {
      return undefined;
    }

    return this.dateDifference(startTimeDate, endTimeDate);
  }

  dateDifference(firstDate: Date, lastDate: Date): ICounter {
    const timeDifference = lastDate.valueOf() - firstDate.valueOf();
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 60);
    // Add minutes for each hour as we don't display hours in UI at the moment
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60) + (hours * 60);
    const seconds = Math.floor(timeDifference / 1000) % 60;

    return <ICounter>{min: minutes, sec: seconds};
  }

  static dateDifference(firstDate: Date, lastDate: Date): ICounter {
    const duration = parseFloat(((lastDate.getTime() - firstDate.getTime())/(1000 * 60)).toString());
    const minutes = Math.floor(duration).toFixed(0);
    const seconds = ((duration - parseInt(minutes)) * 60/100).toFixed(2).replace('0.', '');
    return <ICounter>{min: parseInt(minutes), sec: parseInt(seconds)}
  }

  static sumCounters(firstCounter: ICounter, secondCounter: ICounter): ICounter {
    const totalMinutes = firstCounter.min + secondCounter.min;
    const totalSeconds = firstCounter.sec + secondCounter.sec;
    const minsOverflow = totalSeconds > 60 ? totalMinutes + Math.floor(totalSeconds / 60) : undefined;
    const secondsOverflow = totalSeconds > 60 ? totalSeconds % 60 : undefined;
    return {min: minsOverflow ?? totalMinutes, sec: secondsOverflow ?? totalSeconds};
  }

  static formatCounterToString(counter: ICounter | undefined): string {
    if (!counter) {
      return 'N/A';
    }

    if (counter.sec > 59) {
      const minsToAdd = Math.floor(counter.sec / 60);
      const remainingSec = counter.sec % 60;
      counter.min += minsToAdd;
      counter.sec = remainingSec;
    }

    const seconds = counter.sec === 0 ? counter.sec : counter.sec.toString().padStart(2, '0');

    return `${counter.min}m ${seconds}s`;
  }

  static formatCounterStringToNumber(counter: string): number {
    if (counter === 'N/A') {
      return 0;
    }
    const counterSplitValue = counter.split('m ');
    const mins = counterSplitValue[0];
    const secs = counterSplitValue[1].split('s')[0];
    const strVal = `${mins}.${secs}`;
    return Number(strVal);
  }

  static haveMinutesElapsed(startingDate: Date, durationInMinutes: number): boolean {
    const now = new Date();
    const dateDiff = this.dateDifference(startingDate, now);
    return dateDiff.min <= durationInMinutes;
  }

  // Returns date in mm/dd/yyyy format
  static getFormattedDate(): string {
    const date = new Date();
    // GetMonth function is zero based
    let mm = (date.getMonth()+1).toString();
    if (mm.length === 1) {
      mm = `0${mm}`;
    }
    // GetDay function is zero based
    let dd = (date.getDay()+1).toString();
    if (dd.length === 1) {
      dd = `0${dd}`;
    }
    const yyyy = date.getFullYear().toString();
    return `${mm}/${dd}/${yyyy}`;
  }
}
