import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { IPurchaseMinutesRoute } from 'app/core/interfaces';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PurchaseMinutesService {

  // Observable sources
  private deviceListSource = new ReplaySubject<any>(1);
  private addNewJobSource = new BehaviorSubject<boolean>(false);

  // Observable streams
  // Subscribe to these to perform actions when the source changes
  devicesList$ = this.deviceListSource.asObservable();
  addNewJob$ = this.addNewJobSource.asObservable();
  // Service message commands
  // Use these when updating/changing the source for subscribers to do actions on
  routeDeviceListToMinutesPage(data: IPurchaseMinutesRoute): void {
    this.deviceListSource.next(data);
  }

  clickedOnAddNewJob(data: boolean): void {
    this.addNewJobSource.next(data);
  }
}
