<div class="landing-container">

  <div class="flex flex-col items-center justify-center">

    <!-- Get Started -->
    <div class="landing-section dark-section flex flex-col xl:flex-row gap-[4rem] p-[2rem] l:p-[5rem] w-full">
      <div class="flex flex-col gap-[28px] w-full xl:w-1/2 self-center text-left">
        <!--
        <div class="landing-chip pl-[16px] pr-[16px] pt-[8px] pb-[8px]">
          <span class="min-w-max">REAL DEVICE CLOUD</span>
        </div>
        -->
        <div class="text-[32px] l:text-[42px] xl:text-[44px] leading-[44px] xl:leading-[52px] landing-section-header">
          Welcome to the Qualcomm® Device Cloud
        </div>
        <div class="text-[16px] l:text-[21px] leading-[24px] l:leading-[32px] font-normal font-light-color">
          Our cloud-based platform lets you remotely access real devices powered by Snapdragon® and Qualcomm® platforms for seamlessly testing applications.
        </div>
        <div class="flex flex-col md:flex-row items-start gap-[20px] pt-[10px]">
          <q-button [id]="getStartedBtnLabel" qdcClick [targetUrl]="getStartedTargetUrl"
            data-testid="get-started" variant="fill" color="primary"
            [endIcon]="icons.ArrowRight" (click)="routeApp('home')" size="l">
            {{getStartedBtnLabel}}
          </q-button>
          <q-button [id]="viewDevicesBtnLabel" qdcClick [targetUrl]="viewDevicesTargetUrl"
            data-testid="learn-more" class="landing-page-opaque" variant="outline" color="neutral"
            [endIcon]="icons.ArrowRight" (click)="viewDevices()" size="l">
            {{viewDevicesBtnLabel}}
          </q-button>
        </div>
      </div>
      <div class="flex w-full xl:w-1/2 self-center">
        <div class="gradient-section-welcome w-[90%] l:w-[45%] min-h-[380px]"></div>
        <div class="flex relative intro-video w-[80vw] xl:w-full">
          <div *ngIf="!showIntroVideo" class="play-intro-video-container flex flex-col gap-[36px]">
            <q-icon class="intro-video-play self-center cursor-pointer"
              [icon]="icons.PlayCircle" [size]="56" (click)="loadBrightcoveVideo();" />
            <span class="text white larger-20 larger-fw">Introducing Device Cloud</span>
          </div>
          <img *ngIf="!showIntroVideo" class="intro-image w-full"
               src="../../../../../assets/images/landing_session.webp" alt="QDC Landing Platforms Video Image" />
          <div id="brightcovePlayer"></div>
        </div>
      </div>
    </div>

    <!-- Value Propositions -->
    <div class="landing-section flex flex-col md:flex-row  w-full justify-center items-center
        gap-[25px] md:gap-[50px] l:gap-[100px] p-[40px] md:p-[60px] l:p-[80px]">
      <ng-container *ngFor="let valueProp of valuePropositions; let i = index">
        <div class="value-prop-container flex flex-col max-w-[310px] gap-[16px]">
          <img class="landing-page-icon-img self-center" [src]="valueProp.iconImage">
          <span class="landing-page-subtitle">{{valueProp.header}}</span>
          <div class="landing-page-text">{{valueProp.content}}</div>
        </div>
        <!-- Gradient Divider Image -->
        <img
          *ngIf="i < valuePropositions.length - 1"
          class="hidden md:block pl-[16px] pr-[16px]"
          src="../../../../../assets/images/gradient-icons/gradient-divider.webp"
        >
      </ng-container>
    </div>

    <!-- Device Categories -->
    <div class="w-full"> <!-- Div to encapsulate gradient section with dark section -->
      <div class="gradient-section-devices w-full min-h-[960px]"></div>
      <div class="landing-section dark-section flex flex-col min-h-[1170px] pt-[80px] pb-[80px]">
        <!-- Inline z-index style to keep the section title above gradient -->
        <div class="flex flex-col pl-[70px] pr-[70px] gap-[16px] text-left" style="z-index: 1">
          <div class="landing-section-header text-[32px] l:text-[38px] xl:text-[42px] leading-[36px] l:leading-[42px] xl:leading-[44px]">
            Diverse category of devices
          </div>
          <div class="flex flex-col md:flex-row gap-[50px] m:gap-[100px] lg:gap-[150px]">
            <div class="flex text-[14px] l:text-[18px] xl:text-[21px] leading-[18px] l:leading-[21px] xl:leading-[32px] text-left"
              style="color: white">
              The Qualcomm® Device Cloud lets you remotely access real devices in our labs. With just a few clicks, you can seamlessly interact with these devices, accelerating your development process like never before.
            </div>
            <q-button [id]="viewDevicesBtnLabel" qdcClick [targetUrl]="viewDevicesTargetUrl"
              data-testid="view-devices" class="landing-page-opaque" variant="outline" color="neutral"
              [endIcon]="icons.ArrowRight" (click)="viewDevices()" size="l">
              {{viewDevicesBtnLabel}}
            </q-button>
          </div>
        </div>
        <p-carousel class="max-w-[95vw] pt-[36px] self-center" [value]="chipsetCategories" [numVisible]="4" [circular]="true"
          [numScroll]="1" [responsiveOptions]="responsiveCarouselOptions" aria-label="landing-page-carousel">
          <ng-template let-category pTemplate="item">
            <div class="mt-[100px]">
              <img class="category-image-content max-w-[260px] sm:max-w-[412px]" [class]="category.title | lowercase" [src]="category.src">
              <div class="device-category-card max-w-[280px] sm:max-w-[412px] pt-[220px] pl-[15px] pr-[15px] sm:pl-[40px] sm:pr-[40px] text-left">
                <div class="flex flex-col sm:w-[328px] gap-[8px] pb-[12px]">
                  <div class="category-title">{{category.title}}</div>
                  <div class="font-lighter-color h-[70px] sm:h-[55px]">{{category.subtitle}}</div>
                </div>
                <ng-container *ngFor="let content of category.content">
                  <q-divider></q-divider>
                  <div class="flex w-[328px] pt-[12px] pb-[12px] gap-[8px]  h-[100px] sm:h-[90px]  items-center">
                    <div class="text medium white max-w-[90px] sm:max-w-[100px] sm:min-w-[100px]">{{content.label}}</div>
                    <div class="font-lighter-color max-w-[160px] sm:max-w-[220px] sm:min-w-[220px]">{{content.description}}</div>
                  </div>
                </ng-container>
                <div class="pt-[38px] pb-[50px] text-center">
                  <q-button id="{{viewDevicesBtnLabel}} {{category.title}}" qdcClick
                      targetUrl="{{viewDevicesTargetUrl}}/{{category.title.toLocaleLowerCase()}}"
                      [attr.data-testid]="'view-devices-' + category.title"
                      class="landing-page-opaque" variant="outline" color="neutral" size="l"
                      [endIcon]="icons.ArrowRight" (click)="viewDevices(category.title.toLocaleLowerCase())">
                    View {{category.title}} Devices
                  </q-button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>

    <!-- Interactive Sessions -->
    <div class="landing-section flex flex-col lg:flex-row gap-8 lg:gap-12 p-4 lg:p-8 w-full">
      <div class="hidden lg:flex w-full lg:w-1/2 self-center">
        <div class="flex relative">
          <img class="landing-image max-w-[100%]" src="../../../../../assets/images/dc_interactive_image_2.webp" />
        </div>
      </div>
      <div class="flex flex-col gap-4 text-left justify-center w-full lg:w-1/2">
        <img class="landing-page-icon-img" src="../../../../../assets/images/gradient-icons/pointer-icon.webp">
        <div class="landing-page-subtitle">
          Interactive Sessions
        </div>
        <div class="landing-page-text">
          Remotely interact with your application live on the devices.
          Swipe, gesture, and interact with the devices – all in real time from within your web browser
        </div>
      </div>
      <div class="flex lg:hidden w-full lg:w-1/2 self-center">
        <div class="flex relative">
          <!-- TODO: replace with .gif and check padding between sections -->
          <img class="landing-image max-w-[100%]" src="../../../../../assets/images/dc_interactive_image_2.webp" />
        </div>
      </div>
    </div>

    <!-- Automated Testing -->
    <div class="landing-section flex flex-col lg:flex-row gap-8 lg:gap-12 p-4 lg:p-8 w-full">
      <div class="flex flex-col gap-4 text-left justify-center w-full lg:w-1/2">
        <img class="landing-page-icon-img" src="../../../../../assets/images/gradient-icons/list-checks-icon.webp">
        <div class="landing-page-subtitle">
          Automated Testing
        </div>
        <div class="landing-page-text">
          Test your applications using our many supported automated testing frameworks.
        </div>
        <div class="landing-page-text">
          We offer built-in, automated testing solutions so you don’t need to worry about writing or maintaining your own scripts.
        </div>
      </div>
      <div class="flex w-full lg:w-1/2 self-center">
        <div class="flex relative">
          <!-- TODO: replace with .gif and check padding between sections -->
          <img class="landing-image max-w-[100%]" src="../../../../../assets/images/dc_automated_image_1.webp" />
        </div>
      </div>
    </div>

    <!-- Getting Started -->
    <div class="landing-section dark-section flex flex-col gap-[15px] items-center p-[2rem] l:p-[5rem] w-full">
      <span class="landing-section-header light text-[28px] l:text-[44px] leading-7 l:leading-9">
        Getting Started
      </span>
        <span class="landing-text light text-[14px] l:text-[18px] leading-4 l:leading-5">
        Get setup and start testing in 3 simple steps
      </span>
      <div class="grid grid-cols-1 l:grid-cols-3 gap-3 l:gap-6 pt-[28px]">
        <ng-container *ngFor="let card of gettingStartedCards; let i = index">
          <p-card id="{{card.title}}" qdcClick [targetUrl]="card.redirectPath"
                  class="landing-getting-started-card" [attr.data-testid]="'getting-started-' + card.title"
                  (click)="routeApp(card.redirectPath)">
            <div class="flex flex-col gap-[56px]">
              <q-icon [icon]="card.icon" size="32" style="color: white"></q-icon>
              <div class="flex flex-col gap-[8px]">
                <span class="landing-section-subtitle light text-[18px] leading-5 text-left">{{ card.title }}</span>
                <span class="landing-text light text-[14px] leading-4 text-left">{{ card.content }}</span>
              </div>
            </div>
          </p-card>
        </ng-container>
      </div>
    </div>

    <!-- FAQ -->
    <div class="landing-section dark-section flex flex-col gap-[16px] w-full pt-[30px] l:pt-[60px] pb-[30px] l:pb-[60px]
      pl-[60px] l:pl-[90px] xl:pl-[120px] pr-[60px] l:pr-[90px] xl:pr-[120px]">
      <div class="flex justify-between">
        <div class="landing-section-header text-[32px] l:text-[38px] xl:text-[42px] leading-[38px] xl:leading-[44px]">
          FAQ
        </div>
        <q-button id="{{visitSupportSiteBtnLabel}}" qdcClick [targetUrl]="this.strings.docs.docsHome"
            data-testid="visit-support-site" class="landing-page-opaque" variant="outline" color="neutral" size="l"
            [endIcon]="icons.ArrowRight" (click)="openSupportTab()">
          {{visitSupportSiteBtnLabel}}
        </q-button>
      </div>
      <!-- TODO: implement custom accordion closed/opened icons -->
      <q-accordion class="faq-accordion" defaultActiveIndex="0">
        <ng-container *ngFor="let faqItem of faqItems">
          <q-accordion-item
            [label]="faqItem.label"
            [content]="faqItem.secondaryContent ? customContent : faqItem.content"
          >
            <ng-template #customContent>
              <div class="q-accordion-item--description pb-[26px]">{{faqItem.content}}</div>
              <div class="q-accordion-item--description">{{faqItem.secondaryContent}}</div>
            </ng-template>
          </q-accordion-item>
        </ng-container>
      </q-accordion>
      <span class="landing-text light text-[21px] leading-8 text-center pt-[75px]">
        Still have questions? Visit our
        <a id="{{visitSupportSiteBtnLabel}}" qdcClick [targetUrl]="this.strings.docs.docsHome"
            [href]="strings.docs.docsHome" target="_blank" class="support-link text-[21px]">
          Support Site
        </a>
        for more information about Qualcomm® Device Cloud
      </span>
    </div>
  </div>
</div>
