import { AAEvent, ChipsetCategory } from 'app/core/enums';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, NewFeatureService, SessionService, UserService } from 'app/core/services';
import { APIUtility } from 'app/core/utils/api-utility';
import { CommonUtility } from 'app/core/utils/common-utility';
import { IconData } from 'lucide-angular/icons/types';
import { Icons } from 'app/core/constants/icons';
import { Router } from '@angular/router';
import { Strings } from 'app/core/constants/strings';
import { environment } from 'environments/environment';

// @ts-ignore
import brightcovePlayerLoader from '@brightcove/player-loader';

@Component({
  selector: 'qdc-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {

  // Used for Adobe Analytics with qdcClick directive
  protected readonly getStartedBtnLabel = 'Get Started Free';
  protected readonly viewDevicesBtnLabel = 'View Devices';
  protected readonly visitSupportSiteBtnLabel = 'Visit Support Site';

  @Input() hideLogin = false;
  @Output() loginRequested = new EventEmitter<void>();

  chipsetCategories: {
    chipsetCategory: string, title: string, subtitle: string,
    content: {label: string, description: string }[],
    src: string, imgAlt: string
  }[] = [
      {
        chipsetCategory: ChipsetCategory[ChipsetCategory.Compute],
        title: 'Compute',
        subtitle: 'Test on laptops powered by Snapdragon® platforms and PCs with cutting-edge technology and performance on-the-go.',
        content: [
          {
            label: 'Remote Interactions',
            description: 'Effortlessly view and interact with your device’s screen directly in your browser or through Remote Desktop.',
          },
          {
            label: 'Secure Connections',
            description: 'Utilize SSH to connect to remote devices and securely transfer files with confidence.',
          },
          {
            label: 'Debugging made easy',
            description: 'Debug your application on a remote device seamlessly using Visual Studio’s powerful remote debugger.',
          },
        ],
        src: '../../../../../assets/images/chipset-categories/compute-chipset2.webp',
        imgAlt: 'Compute Platform',
      },
      {
        chipsetCategory: ChipsetCategory[ChipsetCategory.Mobile],
        title: 'Mobile',
        subtitle: 'Smartphones, tablets, and other handheld mobile devices with exceptional performance and power efficiency.',
        content: [
          {
            label: 'ADB Integration',
            description: 'Interact with remote devices using ADB as though they were connected to your local workstation.',
          },
          {
            label: 'Browser based control',
            description: 'View and manage your device’s screen effortlessly through your browser.',
          },
          {
            label: 'Android Studio Compatability',
            description: 'Develop, debug and test your applications on a remote device using Android Studio’s robust toolset.',
          },
        ],
        src: '../../../../../assets/images/chipset-categories/mobile-chipset2.webp',
        imgAlt: 'Mobile Platform',
      },
      {
        chipsetCategory: ChipsetCategory[ChipsetCategory.IOT],
        title: 'IOT',
        subtitle: 'Connect the unconnected with robotics, smart home, industrial and commercial devices.',
        content: [
          {
            label: 'Remote Interactions',
            description: 'Effortlessly view and interact with your device’s screen directly in your browser or through Remote Desktop.',
          },
          {
            label: 'Secure Connections',
            description: 'Utilize SSH to connect to remote devices and securely transfer files with confidence.',
          },
          {
            label: 'Debugging made easy',
            description: 'Debug your application on a remote device seamlessly using Visual Studio’s powerful remote debugger.',
          },
        ],
        src: '../../../../../assets/images/chipset-categories/iot-chipset.webp',
        imgAlt: 'IOT Platform',
      },
      {
        chipsetCategory: ChipsetCategory[ChipsetCategory.Automotive],
        title: 'Automotive',
        subtitle: 'Develop the next generation of autonomous, immersive, connected automobile experiences on cloud hosted SoCs.',
        content: [
          {
            label: 'SSH and ADB support',
            description: 'Interact with remote devices using ADB and SSH from your local machine.',
          },
          {
            label: 'Digital Cockpit',
            description: 'Build and test applications on virtual and physical devices for the next generation of Software Defined Vehicles (SDV).',
          },
          {
            label: 'Browser Based control',
            description: 'View and interact with remote device\'s screen effortlessly through your browser.',
          },
        ],
        src: '../../../../../assets/images/chipset-categories/automotive-chipset.webp',
        imgAlt: 'Automotive Platform',
      },
    ];

  valuePropositions: { iconImage: string, header: string, content: string }[] = [
    {
      iconImage: '../../../../../assets/images/gradient-icons/monitor-icon.webp',
      header: 'Latest and Prerelease Devices',
      content: 'Be the first to innovate with the latest and unreleased Snapdragon® and Qualcomm® devices',
    },
    {
      iconImage: '../../../../../assets/images/gradient-icons/alarm-clock-check-icon.webp',
      header: 'Try for Free',
      content: 'Dive into your next project with full access to our extensive device offerings - all at no cost',
    },
    {
      iconImage: '../../../../../assets/images/gradient-icons/badge-check-icon.webp',
      header: 'Real Devices',
      content: 'Conveniently access real, ready-to-use devices - remotely',
    },
  ]

  gettingStartedCards: { icon: IconData, title: string, content: string, redirectPath: string }[] = [
    {
      icon: this.icons.UserPlus, title: 'Sign Up',
      content: 'Create accounts for you and your team to share minutes and monitor test results.',
      redirectPath: environment.production ? 'https://myaccount.qualcomm.com' : 'https://myaccount-tst.qualcomm.com',
    },
    {
      icon: this.icons.AlarmPlus, title: 'Request Minutes',
      content: 'Browse our device catalog and request minutes for testing.',
      redirectPath: '/devices',
    },
    {
      icon: this.icons.MonitorSmartphone, title: 'Start Testing',
      content: 'Choose from our large device library, including early access to unreleased chipsets.',
      redirectPath: '/home',
    },
  ];

  faqItems: { label: string, content: string, secondaryContent?: string }[] = [
    {
      label: 'Is Qualcomm® Device Cloud free to use?',
      content: 'Yes. Please refer to How do I get started using devices on Qualcomm® Device Cloud? regarding how to get started.',
      secondaryContent: undefined,
    },
    {
      label: 'How do I get started using devices on Qualcomm® Device Cloud?',
      content: 'Using Qualcomm® Device Cloud requires you create and login with your Qualcomm® ID. Clicking "Get Started" on the landing page will take you to the Qualcomm® ID login/sign up page. Once you login to Qualcomm® Device Cloud with your Qualcomm® ID, you can start to browse the device catalog, request free minutes for a device of interest, and create interactive sessions or automated jobs to use the devices.',
      secondaryContent: 'We are currently promoting Snapdragon® X Elite devices, and new users get free pre-provisioned minutes on Snapdragon® X Elite upon first login for a limited time.',
    },
    {
      label: 'How does the minutes allocation process work?',
      content: 'Based on type of user, free minutes are either provisioned at the user level or organization level. If you are from an organization, the minutes will be shared across users of your organization.',
      secondaryContent: undefined,
    },
    {
      label: 'How do I add more minutes once my remaining minutes runs low?',
      content: 'Go to Devices page, click on the device you are interested in, click “Request Minutes”, fill out the form, and a request will be sent to us. We will review your request and get in touch with you. Once approved, additional free minutes will be provided.',
      secondaryContent: undefined,
    },
    {
      label: 'Does QDC use simulators and emulators or a real device?',
      content: 'QDC uses real devices which are QRDs, MTPs, and reference designs targeted for Mobile, IOT and Compute applications. All these devices are secured in the Qualcomm® cloud network.',
      secondaryContent: undefined,
    },

  ];

  showIntroVideo: boolean = false;
  devicesUrl: string = '';
  getStartedTargetUrl: string = '';
  viewDevicesTargetUrl: string = '';

  responsiveCarouselOptions = [
    {
      breakpoint: '2600px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1850px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1025px',
      numVisible: 1,
      numScroll: 1,
    },
  ];


  constructor(
    private router: Router,
    public sessionService: SessionService,
    private authService: AuthService,
    public userService: UserService,
    public strings: Strings,
    public newFeatureService: NewFeatureService,
    public icons: Icons) {
    this.hideLogin = userService.getUsername() !== undefined;
    // Reset 'redirectFromLandingPage' on initializing component.
    // If user clicks on one of the public landing buttons('redirectFromLandingPage' gets set to that route)
    // and goes back and then clicks Login,
    // page will get redirected to the route, if this is not reset.
    sessionStorage.setItem('redirectFromLandingPage', '');
    this.devicesUrl = this.userService.isLoggedIn() ? '/devices' : '/devicecatalog'
  }

  ngOnInit(): void {

    // Set the get started url
    if (CommonUtility.stringIsNullOrEmpty(this.userService.getUsername()) &&
      !this.userService.isLoggedIn()) {
      this.getStartedTargetUrl = APIUtility.isProdEnv() ? 'https://myaccount.qualcomm.com' : 'https://myaccount-tst.qualcomm.com'
      this.viewDevicesTargetUrl = this.devicesUrl;
    } else {
      this.getStartedTargetUrl = `${window.location.origin}/home`
      this.viewDevicesTargetUrl = `${window.location.origin}${this.devicesUrl}`
    }
  }

  ngAfterViewInit(): void {
    // Video settings
    if (document.querySelector('video')) {
      // Mute video so it can be autoplayed
      document.querySelector('video')!.muted = true;
      // Play the video
      document.querySelector('video')!.play();
    }
  }

  onLoginClicked(): void {
    this.sessionService.loginRequested();
  }

  routeApp(route: string): void {
    if (route.includes('http' || 'https')) {
      window.location.href = route;
    } else {
      this.router.navigateByUrl(route);
      // If user is not logged in, redirect to login
      // Save the redirectUrl, so user is automatically redirected after login
      if (CommonUtility.stringIsNullOrEmpty(this.userService.getUsername()) &&
        !this.userService.isLoggedIn()) {
        // If user clicks on one of the public landing buttons set 'redirectFromLandingPage' to that route
        sessionStorage.setItem('redirectFromLandingPage', route);
        this.sessionService.loginRequested();
      }
    }
  }

  openSupportTab(): void {
    window.open(this.strings.docs.docsHome);
  }

  viewDevices(category?: string): void {
    if (category) {
      this.router.navigateByUrl(`${this.devicesUrl}/${category}`);
    } else {
      this.router.navigateByUrl(`${this.devicesUrl}`);
    }
  }

  loadBrightcoveVideo(): void {
    this.showIntroVideo = true;
    let percentTime = 0;
    const milestoneMap = new Map<string, boolean>();
    milestoneMap.set('started', false);
    milestoneMap.set('25', false);
    milestoneMap.set('50', false);
    milestoneMap.set('75', false);
    milestoneMap.set('completed', false);
    brightcovePlayerLoader({
      refNode: document.querySelector('#brightcovePlayer'),
      refNodeInsert: 'replace',
      accountId: '1414329538001',
      playerId: '4JiZQnWhg',
      embedId: 'default',
      videoId: '6353294082112',
      embedOptions: {
        tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
      },
    }).then((success: any) => {
      const bcPlayer = success.ref;
      bcPlayer.el_.style.width = 'inherit'
      bcPlayer.el_.style.height = 'inherit'
      bcPlayer.play();

      // Detect Video Progress
      bcPlayer.on('play', () => {
        if (milestoneMap.get('started')) {
          this.pushAAVideoEvent('play');
        } else {
          milestoneMap.set('started', true);
          this.pushAAVideoEvent('start');
          // Reset values on restart
          percentTime = 0;
          milestoneMap.set('completed', false);
        }
      })
      bcPlayer.on('pause', () => {
        // Filter out pause events that happen near video completion
        if (percentTime < 100) {
          this.pushAAVideoEvent('pause');
        }
      })
      bcPlayer.on('ended', () => {
        milestoneMap.set('completed', true);
        this.pushAAVideoEvent('complete');
        // Reset milestone flags
        milestoneMap.set('started', false);
        milestoneMap.set('25', false);
        milestoneMap.set('50', false);
        milestoneMap.set('75', false);
      })
      bcPlayer.on('timeupdate', (event: any) => {
        // Find and compute current video time as a percentage
        const playerCache = event.target.player.cache_
        const duration = Math.floor(playerCache.duration);
        percentTime = Math.round(playerCache.currentTime * 100 / duration);
        // Handle time percentage events
        switch(percentTime) {
          case 25:
            if (!milestoneMap.get('25')) {
              milestoneMap.set('25', true);
              this.pushAAVideoEvent('25%');
            }
            break;
          case 50:
            if (!milestoneMap.get('50')) {
              milestoneMap.set('50', true);
              this.pushAAVideoEvent('50%');
            }
            break;
          case 75:
            if (!milestoneMap.get('75')) {
              milestoneMap.set('75', true);
              this.pushAAVideoEvent('75%');
            }
            break;
          default:
            break;
        }
      })
    }).catch((error: any) => {
      this.showIntroVideo = false;
    })
  }

  pushAAVideoEvent(action: string): void {
    window.adobeDataLayer.push({
      'event': AAEvent.Video,
      'eventInfo': {
        'action': action,
        'label': 'Introducing Device Cloud',
        'location': 'brightcove',
      },
    });
  }

}
