import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AAEvent } from '../enums';

@Directive({
  selector: '[qdcClick]',
  standalone: true,
})
export class ClickDirective implements OnInit, OnDestroy {

  @Input() targetUrl: string = '';

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.elRef.nativeElement.addEventListener('click', this.onClick);
  }

  ngOnDestroy(): void {
    this.elRef.nativeElement.removeEventListener('click', this.onClick);
  }

  @HostListener('click', ['$event']) onClick($event: PointerEvent): void {
    const label = this.elRef.nativeElement.id;
    this.onClickAA(label, this.targetUrl);
  }

  onClickAA(label: string, location: string): void {
    window.adobeDataLayer.push({
      'event': AAEvent.CTAGeneric,
      'eventInfo': {
        'action': 'click',
        'label': label,
        'location': location,
      },
    });
  }
}
