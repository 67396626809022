import { AuthService, LogService, SessionService, TermAcceptanceService, UserService } from 'app/core/services';
import { ILogger, ISignedTermData, ITermAcceptResponse, ITermAcceptance } from 'app/core/interfaces';
import { Component } from '@angular/core';
import { ErrorMsgType } from 'app/core/enums';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'qdc-term-acceptance',
  templateUrl: './term-acceptance.component.html',
  styleUrls: ['./term-acceptance.component.scss'],
})
export class TermAcceptanceComponent {

  // Vars related to terms and agreements
  showTerm = false;
  termIndex = 0;
  termsAndAgreements: ITermAcceptance = {
    termSetName: '',
    redirectUrl: '',
    terms: [],
  };
  termAndAgreementText: string = '';
  disableAcceptAndDeclineBtn = false;

  // Notify user with a message to select latest terms and acceptance
  showMessage = false;

  isBusy = false;

  log: ILogger;

  constructor(
    private termAcceptanceService: TermAcceptanceService,
    private sessionService: SessionService,
    private authService: AuthService,
    private userService: UserService,
    logService: LogService,
  ) {

    this.log = logService.get('Terms Acceptance');

    this.termAcceptanceService.getUserTermAndAgreements().subscribe({
      next: (data) => {
        if (data) {
          this.termsAndAgreements = data;
          this.termAndAgreementText = this.termsAndAgreements.terms[this.termIndex].termtext;
          if (!this.termAndAgreementText) {
            this.userService.setError({
              devErrorMsg: `Terms&Agreements error:
                            ${new Date()}
                            Term text is null`,
              customErrorMsg: 'We are unable to locate Qualcomm® Device Cloud Terms for your user account.',
              errorMsgType: ErrorMsgType.Terms,
              errorStatus: '',
            });
            this.showTerm = false;
            this.authService.isAuthorizedSubject$.next(false);
          } else {
            this.disableAcceptAndDeclineBtn = true;
            this.showTerm = true;
          }
        }
      },
      error: (error) => {
        this.userService.setError({
          devErrorMsg: `Terms&Agreements error:
                        ${new Date()}
                        ${JSON.stringify(error)}`,
          customErrorMsg: 'We are unable to locate Qualcomm® Device Cloud Terms for your user account.',
          errorMsgType: ErrorMsgType.Terms,
          errorStatus: error.status.toString(),
        });

        this.authService.isAuthorizedSubject$.next(false);
      },
    });

  }

  async onClickAcceptTerm(): Promise<void> {

    this.isBusy = true;
    this.disableAcceptAndDeclineBtn = true;

    // Check whether user has selected all the terms or not
    if (this.termIndex <= (this.termsAndAgreements.terms.length - 1)) {
      const accept: ISignedTermData = {
        termId: this.termsAndAgreements.terms[this.termIndex].termId,
        revId: this.termsAndAgreements.terms[this.termIndex].revId,
      }

      // Increase term index by  1
      this.termIndex += 1;

      const termAcceptResponse: ITermAcceptResponse | undefined = await lastValueFrom(this.termAcceptanceService
        .acceptTermAndAgreements(accept)).catch((error) => {
        this.userService.setError({
          devErrorMsg: `Terms&Agreements error:
                          ${new Date()}
                          ${JSON.stringify(error)}`,
          customErrorMsg: 'We are unable to accept Qualcomm® Device Cloud Terms for your user account.',
          errorMsgType: ErrorMsgType.Terms,
          errorStatus: error.status.toString(),
        });
        return undefined;
      });

      if (termAcceptResponse) {
        if (termAcceptResponse.accept) {
          // Once the accept api is called for all terms, last recall the /signed
          // Api to confirm user has accepted all the available terms for qdc
          if (this.termIndex === (this.termsAndAgreements.terms.length)) {
            if (termAcceptResponse.complete) {
              this.showTerm = false;
              this.termAcceptanceService.acceptedAllTermsAndAgreements(true);
            }
          } else {
            this.termAndAgreementText = this.termsAndAgreements.terms[this.termIndex].termtext;
            if (!this.termAndAgreementText) {
              this.userService.setError({
                devErrorMsg: `Terms&Agreements error:
                  ${new Date()}
                  Term text is null`,
                customErrorMsg: 'We are unable to locate Qualcomm® Device Cloud Terms for your user account.',
                errorMsgType: ErrorMsgType.Terms,
                errorStatus: '',
              });
              this.showTerm = false;
              this.authService.isAuthorizedSubject$.next(false);
            }
            // Move dialog scrollbar to the top after user accepts the term and load the other term
            document.getElementsByClassName('terms-text-container')[0].scrollTo(0, 0);
          }
        }
      } else {
        // If the accept api fails, show user unauthorized screen
        this.authService.isAuthorizedSubject$.next(false);
      }

      this.isBusy = false;
    } else {
      this.termAcceptanceService.acceptedAllTermsAndAgreements(true);
    }
  }

  onClickDeclineTerm(): void {
    this.sessionService.logoutRequested();
  }

  onScroll(event: any): void {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.disableAcceptAndDeclineBtn = false;
    }
  }

}
