import { DialogService, SessionService, UserService } from 'app/core/services';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExecutionsService extends QDCApiService<any> {

  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService,
    protected dialogService: DialogService) {
    super(http, environment.executionsApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      if (url.includes('extend')) {
        throw error.error;
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  extendSession(id: number, minutesAdded: number): Observable<void> {
    return super.submit({minutes: minutesAdded}, `${environment.executionsApi}/${id}/extend`);
  }
}
