// Angular Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';

// QDC Modules
// N/A

// QUI Modules
// N/A

// PrimeNG Modules
// N/A

// Services
// N/A

// Guards
// N/A

// UnauthorizedModule Components
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: UnauthorizedComponent },
];

@NgModule({
  declarations: [
    UnauthorizedComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,

    // Routing Modules
    QuicklinkModule,
    RouterModule.forChild(routes),

    // QDC Modules
    // N/A

    // QUI Modules
    // N/A

    // PrimeNG Modules
    // N/A
  ],
  exports: [
    UnauthorizedComponent,
    QuicklinkModule,
    RouterModule,
  ],
})
export class UnauthorizedModule { }
