// Angular Modules
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';

// Routing Modules
// N/A

// QDC Modules
// N/A

// QUI Modules
// N/A

// PrimeNG Modules
// N/A

// Services
// N/A

// Guards
// N/A

// BusyableModule Components
import { BusyableComponent } from './components/busyable.component';

@NgModule({
  declarations: [
    BusyableComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FlexLayoutModule,

    // Routing Modules
    // N/A

    // QDC Modules
    // N/A

    // QUI Modules
    // N/A

    // PrimeNG Modules
    // N/A
  ],
  exports: [
    BusyableComponent,
  ],
})
export class BusyableModule { }
