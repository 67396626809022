import {IJobTarget, IPagedCollection} from '../interfaces';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicApiService {
  baseUrl = environment.qdcApi;
  constructor(private http: HttpClient) {
  }

  public getPublicCatalog():  Observable<IPagedCollection<IJobTarget>> {
    const params = '?$page=1&$pagesize=300';
    return this.http.get<IPagedCollection<IJobTarget>>(`${this.baseUrl}${environment.publicSkusCatalogApi}${params}`)
  }
}
