import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DialogService, SessionService, UserService } from 'app/core/services';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ICreateSharedSession, IDictionary, IJobLite, IJobReport, IJobSubmission, IJobTarget, IPagedCollection, ISharedSession } from 'app/core/interfaces';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { JobType } from 'app/core/enums';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobService extends QDCApiService<IJobLite> {

  // Used to restrict Job submission while a file is being uploaded
  jobBusySource = new Subject<boolean>();
  jobBusy$ = this.jobBusySource.asObservable();
  jobQuickTourSettingSource = new Subject<{ enabled: boolean, platform: string }>();
  jobQuickTourSetting$ = this.jobQuickTourSettingSource.asObservable();

  public jobTargetsSubject$ = new BehaviorSubject<IJobTarget[]>([]);

  public get allJobTargets(): Observable<IJobTarget[]>{
    return this.jobTargetsSubject$.asObservable();
  }

  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService,
    protected dialogService: DialogService) {
    super(http, environment.jobsApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      if (url.includes('sharedsession')) {
        throw error.error;
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  protected /* override */ getAnyQuery(query: any, path?: string, isAbsolutePath?: boolean): Observable<IJobLite> {
    return this.getAny(path, isAbsolutePath, query);
  }

  submit(submission: IJobSubmission): Observable<any> {
    return super.submit(submission);
  }

  cancelJob(id: number): Observable<IJobLite> {
    return super.submit({}, `${environment.jobsApi}/${id}/abort`);
  }

  endSession(id: number): Observable<IJobLite> {
    return super.submit({}, `${environment.jobsApi}/${id}/complete`);
  }

  getJobTargets(jobType?: JobType): Observable<any> {
    const query: IDictionary = {
      ['$pageSize']: defaults.maxPageSize,
    };

    switch (jobType) {
      case JobType.Automated:
        query['AutomatedJob'] = true;
        break;
      case JobType.Interactive:
        query['InteractiveJob'] = true;
        break;
    }

    return super.getAny('/targets', undefined, query).pipe(
      tap((res) => {
        // Only save unfiltered job targets
        if (!jobType) {
          this.jobTargetsSubject$.next(res.data);
        }
      }));
  }

  getChipsetDeviceSerialNumbers(chipset: string): Observable<IPagedCollection<string>> {
    const query: IDictionary = {
      ['$pageSize']: defaults.pageSize,
    };
    return super.getAny(`/targets/${chipset}/devices`, undefined, query);
  }

  getJobReport(id: number): Observable<IJobReport> {
    return super.getAny(`/${id}/report`);
  }

  // Lightweight job call used for polling
  getJobLite(id: number): Observable<IJobLite> {
    return super.getAny(`/${id}`);
  }

  userHasJobs(user: string): Observable<boolean> {
    return this.getPaged(
      1, 1, undefined, undefined, undefined, undefined,
      [{ key: 'submitter', value: user, matchMode: 'eq' }]).pipe(map(x => x?.data?.length > 0));
  }

  getUsersLatestJobs(jobType: string, jobCount: number): Observable<IPagedCollection<IJobLite>> {
    return this.getPaged(
      1, jobCount, undefined, undefined, undefined, undefined,
      [{ key: 'type', matchMode: 'eq', value: jobType }]);
  }

  getJobs(page: number, pageSize: number, submitter: string,
    jobType: string | undefined, date: string): Observable<IPagedCollection<IJobLite>> {

    const query: IDictionary = {
      ['$page']: page,
      ['$pageSize']: pageSize,
      ['submitter']: submitter,
      ['type']: jobType,
      ['submittedAfter']: date,
    };
    return super.getAny('', false, query);
  }

  // Do we want this here or should we make a crash service?
  // Since this is a shared function between job details and auto job report, i moved this here temporarily
  // Job details should NOT extend job report, which is why this got moved from there to here.
  reportSystemCrash(executionSystemCrashes: any[]): void {
    // Format dialog message
    const singleMessage = `System crash found during testing. <br/>Qualcomm® Device Cloud Team is tracking this crash with the following id: <br/><br/>`;
    const multipleMessage = `${executionSystemCrashes.length} system crashes found during testing. <br/>Qualcomm® Device Cloud Team is tracking these crashes with the following id's: <br/><br/>`;
    let message = executionSystemCrashes.length > 1 ? multipleMessage : singleMessage;
    executionSystemCrashes.forEach((crash) => {
      message = message.concat(`<div class="p-chip system-crash-uuid" style="margin: 10px 0px;">${crash.uuid}</div> <br/>`);
    });
    this.dialogService?.showOkDialog(`${message}`, 'Qualcomm® Device Cloud System Crashes Detected');
  }

  getRecentJobTargets(jobType?: JobType): Observable<any> {
    const query: IDictionary = {
      ['$pageSize']: defaults.maxPageSize,
    };

    switch (jobType) {
      case JobType.Automated:
        query['AutomatedJob'] = true;
        break;
      case JobType.Interactive:
        query['InteractiveJob'] = true;
        break;
    }
    return super.getAny('/targets/recentusage', undefined, query);
  }

  createSharedSession(id: number, payload: ICreateSharedSession): Observable<ISharedSession> {
    return super.submit(payload, `${environment.jobsApi}/${id}/sharedsession`);
  }

  deleteSharedSession(id: number, sessionId: number): Observable<HttpResponse<void>> {
    return super.delete(`${environment.jobsApi}/${id}/sharedsession/${sessionId}`);
  }
}
