import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { QDCApiService } from 'app/core/services/qdc-api.service';

import {
  LogService,
  SessionService,
  UserService,
} from 'app/core/services';
import { APIUtility } from 'app/core/utils/api-utility';
import { Injectable } from '@angular/core';
import { ItemPriceResponse } from 'app/core/interfaces';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ItemService extends QDCApiService<ItemPriceResponse> {
  protected baseUrl: string = APIUtility.isLocalEnv() ?
    APIUtility.getLocalBillingServiceApi() : environment.qdcApi;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(
      http,
      environment.itemApi,
      logService,
      router,
      oauthService,
      sessionService,
      userService,
    );
  }

  protected /* override */ customErrorHandler(
    mode: string,
    url: string,
    error: HttpErrorResponse,
  ): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  getItemPrice(itemId: number): Observable<ItemPriceResponse> {
    return super.submit({ itemId: itemId }, `${environment.itemApi}/price`);
  }
}
