/* eslint-disable no-console */
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { QDCLogService, UserService } from 'app/core/services';
import { APIUtility } from 'app/core/utils/api-utility';
import { HttpErrorResponse } from '@angular/common/http';
import { IQDCLog } from 'app/core/interfaces';
import { QDCLogLevel } from 'app/core/enums';
import { Router } from '@angular/router';
import { defaults } from '../constants/defaults';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(protected injector: Injector, protected qdcLogService: QDCLogService,
    protected userService: UserService) { }

  // Generic Error if not explicitly caught by the UI
  handleError(error: any) {
    // Don't post to API if running UI locally
    if (APIUtility.isLocalHost()) {
      return;
    }

    const router = this.injector.get(Router);

    let log;

    if (error instanceof HttpErrorResponse) {
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      // console.error('Backend returned status code: ', error.status);
      // console.error('Response body:', error.message);

      let apiSource = 'QDC API';
      if (error.message.toLowerCase().includes('apigee')) {
        apiSource = 'Apigee';
      }
      // TODO: Need distinctive strings from DM/UM to be able to set as the source.

      const httpErrorResponseFields = {
        'componentRoute': router.url,
        'httpStatus': error.status.toString(),
        'httpError': JSON.stringify(error.error),
        'httpStatusText': error.statusText,
        'url': error.url,
      } as Record<string, string>;

      log = this.buildJsonLog(
        apiSource, QDCLogLevel.error, JSON.stringify(error), undefined, httpErrorResponseFields);

    } else {
      //A client-side or network error occurred.
      // console.error('An error occurred:', error.message);

      const extras = {
        'componentRoute': router.url,
      } as Record<string, string>;

      log = this.buildJsonLog('QDC UI', QDCLogLevel.error, error.message, error.stack, extras);
    }

    this.qdcLogService.postJsonLog(log).subscribe();
  }

  protected buildJsonLog(source: string, level: QDCLogLevel, message: string, stack?: string,
    additionalFields?: Record<string, string>): IQDCLog {
    const openIdTokenInfo = this.userService.getOpenIdAccessTokenInfo();
    const log = <IQDCLog>{
      category: 'UI',
      timestamp: new Date().toISOString(),
      content: message,
      level: QDCLogLevel[level],
      user: this.userService.getUsername() ?? 'No user',
      openIdUser: openIdTokenInfo?.user_name ?? 'No user',
      openIdTokenIssued: openIdTokenInfo?.issued_at ? formatDate(openIdTokenInfo?.issued_at!, defaults.dateFormatWithTimeZone, defaults.dateFormatUS, defaults.dateFormatUSTimeZone) : '',
      openIdTokenExpires: openIdTokenInfo?.expires_in ? formatDate(openIdTokenInfo?.expires_in!, defaults.dateFormatWithTimeZone, defaults.dateFormatUS, defaults.dateFormatUSTimeZone) : '',
      hostname: navigator.userAgent,
      source: source,
      exception: stack ?? '',
    };

    if (additionalFields) {
      Object.keys(additionalFields).forEach((key: string) => {
        log[key] = additionalFields[key];
      });
    }

    return log;
  }

  protected buildJsHttpJsonLog(owner: string, level: QDCLogLevel, requestMethod: string, url: string, payload?: string,
    additionalFields?: Record<string, string>): IQDCLog {
    const openIdTokenInfo = this.userService.getOpenIdAccessTokenInfo();
    const log = <IQDCLog>{
      category: 'UI',
      timestamp: new Date().toISOString(),
      content: `HTTP Request: ${requestMethod}`,
      level: QDCLogLevel[level],
      user: this.userService.getUsername() ?? 'No user',
      openIdUser: openIdTokenInfo?.user_name ?? 'No user',
      openIdTokenIssued: openIdTokenInfo?.issued_at ? formatDate(openIdTokenInfo?.issued_at!, defaults.dateFormatWithTimeZone, defaults.dateFormatUS, defaults.dateFormatUSTimeZone) : '',
      openIdTokenExpires: openIdTokenInfo?.expires_in ? formatDate(openIdTokenInfo?.expires_in!, defaults.dateFormatWithTimeZone, defaults.dateFormatUS, defaults.dateFormatUSTimeZone) : '',
      hostname: navigator.userAgent,
      source: owner,
    };

    log['requestMethod'] = requestMethod;
    log['url'] = url;
    if (payload) {
      // Safety check to ensure payload is a string
      if(typeof payload !== 'string') {
        payload = JSON.stringify(payload);
      }
      log['payload'] = payload;
    }
    if (additionalFields) {
      Object.keys(additionalFields).forEach((key: string) => {
        log[key] = additionalFields[key];
      });
    }

    return log;
  }
}
