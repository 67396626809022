import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { IDictionary, ILogger, ISignedResponse, IUser, IUserAuth, IUserProfile } from 'app/core/interfaces';
import { OAuthEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { APIUtility } from 'app/core/utils/api-utility';
import { CommonUtility } from 'app/core/utils/common-utility';
import { ErrorMsgType } from '../enums';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '.';
import { Router } from '@angular/router';
import { TermAcceptanceService } from './term-acceptance.service';
import { UserService } from './user.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Used to deteremine if user is logged in
  // This gets set to true when we have a valid oidc token
  // and we are logged into the oidc server
  private isLoggedInSubject$ = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSubject$.asObservable();

  // Used to determine if user is authorized
  // Gets set to true when we have a valid user from UM
  public isAuthorizedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthorized$ = this.isAuthorizedSubject$.asObservable();

  // Used to keep track of when login process has started / ended
  private isLoginCompleteSubject$ = new BehaviorSubject<boolean>(false);
  public isLoginComplete$ = this.isLoginCompleteSubject$.asObservable();

  // Used to signal that login has already been initiated by oauth
  // and that we have been redirected back to the UI from the login site
  private isLoginInitiatedSubject$ = new BehaviorSubject<boolean>(false);
  public isLoginInitiated$ = this.isLoginInitiatedSubject$.asObservable();

  private isLogoutInitiatedSubject$ = new BehaviorSubject<boolean>(false);
  public isLogoutInitiated$ = this.isLogoutInitiatedSubject$.asObservable();

  private isSessionExpiredSubject$ = new BehaviorSubject<boolean>(false);
  public isSessionExpired$ = this.isSessionExpiredSubject$.asObservable();

  private hasLoginFailedSubject$ = new BehaviorSubject<boolean>(false);
  public hasLoginFailed$ = this.hasLoginFailedSubject$.asObservable();

  private showTermsSubject$ = new BehaviorSubject<boolean>(false);
  showTerms$ = this.showTermsSubject$.asObservable();
  private allTermsAcceptedSubject$ = new BehaviorSubject<boolean>(false);
  allTermsAccepted$ = this.allTermsAcceptedSubject$.asObservable();

  readonly OAUTH_LOGOUT_KEY = 'OAUTH-LOGOUT';
  readonly HAS_TOKEN = 'OAUTH_LOGIN';

  protected readonly log: ILogger;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private termAcceptanceService: TermAcceptanceService,
    logService: LogService,
  ) {
    this.log = logService.get('QDC UI - Auth Service');

    window.addEventListener('storage', (event) => {
      // Check if another tab sends a logout event
      // so all open sessions can logout as well
      if (event.key === this.OAUTH_LOGOUT_KEY) {
        this.authLogout();
      }
    });

    this.oauthService.events.subscribe(async (event: OAuthEvent) => {
      switch (event.type) {
        case 'session_terminated':
        case 'session_error':
          this.authLogout();
          break;
        case 'token_refresh_error':
          // Send notification to subscribers that session has expired
          this.userService.setIsSessionValid(false);
          this.isSessionExpiredSubject$.next(true);
          break;
        case 'discovery_document_loaded':
          if ((<OAuthSuccessEvent>event).info != null && !this.isLoginInitiatedSubject$.value) {
            if (this.isAuthUrl()) {
              // User logging in
              this.isLoginInitiatedSubject$.next(true);
              this.loginRequested();
            } else if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
              // User has already logged in, reauthorize user
              await this.getAuthentication().then(async () => {
                this.isLoggedInSubject$.next(true);
                this.isLoginInitiatedSubject$.next(true);
                await this.startLoginFlow();
              });
            }
          }
          break;
        case 'token_received':
        case 'token_refreshed':
          {
            // Store OpenID token information
            const openIdToken = this.oauthService.getAccessToken();
            if (openIdToken) {
              this.userService.setOpenIdAccessTokenInfo(openIdToken);
            }
          }
          break;
        default:
          break;
      }
    });

    // This gets triggered after user has signed all the terms
    // once signed, they will then run through the check terms
    // logic again (ensures all the latest terms are signed) to be logged in
    this.termAcceptanceService.acceptedAllTerms$.subscribe((value) => {
      if (value) {
        // Remove terms dialog
        this.showTermsSubject$.next(false);
        this.isLoginCompleteSubject$.next(false);
        this.checkTermsAndLogin();
      }
    });
  }

  init(): void {
    // If we are coming back from openid (auth url)
    // user will have valid token so just log them in
    if (this.isAuthUrl()) {
      this.isLoginInitiatedSubject$.next(true);
      this.loginRequested();
    } else if (this.isAuthErrorUrl()) {
      this.handleOpenIdFailure({error: 'openid redirect fail', status: 500});
    } else if (APIUtility.isDevEnv() || !CommonUtility.stringIsNullOrEmpty(sessionStorage.getItem('redirectUrl'))) {
      // If on dev or being redirected, automatically send user to openid to login
      this.oauthService.loadDiscoveryDocumentAndLogin()
        .catch(error => this.handleOpenIdFailure(error));
    } else {
      // Check to see if we can automatically log in user
      // this will trigger the event in the constructor above
      // (discover_document_loaded)
      const hasToken = localStorage.getItem(this.HAS_TOKEN);
      if (hasToken === 'true') {
        this.oauthService.loadDiscoveryDocumentAndLogin()
          .catch(error => this.handleOpenIdFailure(error));
      } else {
        this.oauthService.loadDiscoveryDocument()
          .catch(error => this.handleOpenIdFailure(error));
        this.loginComplete();
      }
    }

    this.oauthService.setupAutomaticSilentRefresh();
  }

  handleOpenIdFailure(error: any): void {
    this.userService.setError({
      devErrorMsg: `Open Id Error:
                      ${new Date()}
                      ${JSON.stringify(error)}`,
      customErrorMsg: undefined,
      errorMsgType: ErrorMsgType.OpenId,
      errorStatus: error.status.toString(),
    });
    this.hasLoginFailedSubject$.next(true);
    this.userService.setLoginFailed(true);
    this.loginComplete();
  }

  isAuthUrl(): boolean {
    const queryString = window.location.search?.substring(1);
    return queryString?.includes('code') && queryString?.includes('nonce');
  }

  isAuthErrorUrl(): boolean {
    const queryString = window.location.search?.substring(1);
    const searchParams = new URLSearchParams(queryString);
    return searchParams.has('error') && searchParams.has('error_description');
  }

  // Login Methods
  async loginRequested(): Promise<void> {
    this.isLoggedInSubject$.next(false);
    this.isAuthorizedSubject$.next(false);
    this.isLoginCompleteSubject$.next(false);

    // First try to login the user
    this.isLoggedInSubject$.next(await this.openAuthLogin());

    if (this.isLoggedInSubject$.value) {
      this.startLoginFlow();
    } else {
      this.loginComplete();
    }
  }

  async startLoginFlow(): Promise<void> {
    // Once logged in, make sure user has access to qdc
    const isAuth = await this.authorizeUserFromUm();
    this.isAuthorizedSubject$.next(isAuth);
    this.userService.setIsAuthorized(isAuth);

    // If user is authorized, check they have signed
    // the latest qdc terms and try to log them in
    if (isAuth) {
      this.checkTermsAndLogin();
    } else {
      this.router.navigate(['/unauthorized']);
      this.loginComplete();
    }
  }

  async checkTermsAndLogin(): Promise<void> {
    // Check to make sure user has accepted the latest terms if not running API locally
    const termsSigned = APIUtility.isLocalHost() ? true : await this.checkUserTerms();
    this.allTermsAcceptedSubject$.next(termsSigned);

    if (termsSigned) {
      // Init user from qdc api
      const initSuccess = await this.qdcInit();

      if (initSuccess) {
        // User has successfully logged in
        this.userService.setIsLoggedIn(true);
        this.userService.setIsSessionValid(true);

        // Check if user was directed to or from within qdc (gets set in app component)
        let redirectUrl = sessionStorage.getItem('redirectUrl');
        // Set default login route to home if user is not coming from anywhere in qdc
        // or unauthorized pages
        if (CommonUtility.stringIsNullOrEmpty(redirectUrl) ||
          redirectUrl === '/landing' || redirectUrl === '/unauthorized') {
          redirectUrl = '/home';
        } else if (redirectUrl === '/sessionexpired') {
          // If session is expired redirect to landing page for login
          redirectUrl = '/landing';
        }
        // Check for 'redirectFromLandingPage' before redirecting to landing page
        // 'redirectFromLandingPage' only gets set if redirecting from public landing before logging in.
        if (!CommonUtility.stringIsNullOrEmpty(sessionStorage.getItem('redirectFromLandingPage'))) {
          this.router.navigate([sessionStorage.getItem('redirectFromLandingPage')]).then(() => {
            this.loginComplete();
          });
        } else if (!CommonUtility.stringIsNullOrEmpty(redirectUrl)) {
          if (redirectUrl?.includes('?')) {
            // Pass query params separately if any
            // Needed for Adobe launch
            const queryUrl = redirectUrl.split('?');
            redirectUrl = queryUrl[0];
            const allQueryParams = queryUrl[1].split('&');
            const queryParams: IDictionary = {};
            allQueryParams.forEach((keyValue) => {
              const parameter = keyValue.split('=');
              queryParams[parameter[0]] = parameter[1];
            });
            this.router.navigate([redirectUrl], { queryParams: queryParams }).then(() => {
              this.loginComplete();
            });
          } else {
            this.router.navigate([redirectUrl]).then(() => {
              this.loginComplete();
            });
          }
        }

        // Remove any redirect urls on successful login
        sessionStorage.setItem('redirectUrl', '');
        sessionStorage.setItem('redirectFromLandingPage', '');
      } else {
        this.loginComplete();
      }
    } else {
      // If user has not signed the latest terms, make them
      this.showTermsSubject$.next(true);
      this.loginComplete(); // Otherwise busyable is always true in app component and terms are not shown.
    }
  }

  async checkUserTerms(): Promise<boolean> {
    const termsAccepted = await lastValueFrom(this.termAcceptanceService.hasUserSignedTerms())
      .catch((error) => {
        this.userService.setError({
          devErrorMsg: `Terms&Agreements Error:
                        ${new Date()}
                        Unable to parse response object ${JSON.stringify(error)}`,
          customErrorMsg: 'We are unable to locate Qualcomm® Device Cloud Terms for your user account.',
          errorMsgType: ErrorMsgType.Terms,
          errorStatus: error.status.toString(),
        });
        return undefined;
      });

    // If terms has a value the api returned successfully
    if (termsAccepted) {
      return (termsAccepted as ISignedResponse).signed;
    } else {
      // This will get hit when api throws an error getting
      // the signed user terms
      this.isAuthorizedSubject$.next(false);
      return false;
    }
  }

  async authorizeUserFromUm(): Promise<boolean> {
    this.userService.setIsAuthorizationInProgress(true);
    const baseUrl = APIUtility.isLocalEnv() ? APIUtility.getLocalUmApi() : environment.qdcApi;
    const url = baseUrl + environment.umApi;

    const authorizedUm = await lastValueFrom(this.http.get<IUserAuth>(`${url}/users/authorization`))
      .catch((error: any) => {
        this.userService.setError({
          devErrorMsg: `Authorization Error:
                          ${new Date()}
                          ${JSON.stringify(error)}`,
          customErrorMsg: undefined,
          errorMsgType: ErrorMsgType.Auth,
          errorStatus: error.status.toString(),
        });
      });

    this.userService.setIsAuthorizationInProgress(false);

    if (authorizedUm) {
      const splitName = authorizedUm.fullName.split(',');
      const userProfile: IUserProfile = {
        username: authorizedUm.username,
        email: authorizedUm.email,
        fullName: authorizedUm.fullName,
        firstName: splitName[1].trim(),
        lastName: splitName[0].trim(),
        token: authorizedUm.token,
        tokenExpireTime: authorizedUm.tokenExpireTime,
        isLru: authorizedUm.isLru,
        isInternal: authorizedUm.isInternal,
        tid: authorizedUm.tid,
        tenantName: authorizedUm.tenantName,
        uid: authorizedUm.uid,
        systemRole: authorizedUm.systemRole,
      };

      this.userService.setUserProfile(userProfile);
      this.userService.setAdobeUserInfo(userProfile.token);
      return true;
    }

    return false;
  }

  // Init user for qdc
  async qdcInit(): Promise<boolean> {
    const token = this.userService.getNucleusToken();
    if (token) {
      const url = environment.qdcApi + environment.usersApi;
      const authorizedUser = await lastValueFrom(this.http.put<IUser>(`${url}/init`, undefined))
        .catch((error) => {
          this.userService.setError({
            devErrorMsg: `Initialization Error:
                          ${new Date()}
                          ${JSON.stringify(error)}`,
            customErrorMsg: undefined,
            errorMsgType: ErrorMsgType.Init,
            errorStatus: error.status.toString(),
          });

          // Qdc init api failed, show user unauthorized page
          this.isAuthorizedSubject$.next(false);
        });

      // If auth is valid it returns an object but we only care about a valid
      // value for now so just return true / false
      if (authorizedUser) {
        this.userService.setIsDeveloper((<IUser>authorizedUser).isDeveloper);
      }

      // If authorizedUser has a value, means init call was successful
      return authorizedUser !== undefined;
    }

    return false;
  }

  async getAuthentication(): Promise<boolean> {
    // Parse query parameters to fetch token with
    const queryString = window.location.search?.substring(1);
    const query: string[] = [];
    queryString.split('&').forEach((q) => {
      query.push(q.substring(q.lastIndexOf('=') + 1))
    });

    const noncee = sessionStorage.getItem('nonce') ?? query[0];
    const codee = sessionStorage.getItem('authorization_code') ?? query[1];
    const statee = sessionStorage.getItem('state') ?? query[2];

    let token;
    if (this.oauthService.getRefreshToken()) {
      // Check to make sure token is still valid
      token = await this.oauthService.refreshToken().catch((error) => {

        // If we hit here, means the current token we have is invalid
        // logic here needs to be improved to check the string but it
        // is currently in spanish. we have a ticket open here tracking
        // this issue https://qualcomm.service-now.com/sp/?sys_id=9c08e8621b66e5d8bebadac2cd4bcbb8&view=sp&id=ticket&table=incident
        this.oauthService.logOut();
      });
    }

    // If we fail getting the refresh token try using the grant
    if (token === undefined) {
      // This saves the token information in your browser session DO NOT REMOVE
      token = await this.oauthService.fetchTokenUsingGrant('authorization_code', { nonce: noncee, code: codee, state: statee });
    }

    if (token) {
      return true;
    }

    return false;
  }

  async openAuthLogin(): Promise<boolean> {
    if (this.isLoginInitiatedSubject$.value) {
      // Returning from login process, now get the authentication
      // from the url route to complete login process
      return await this.getAuthentication();
    } else {
      // Start oauth login process
      this.oauthService.initCodeFlow(sessionStorage.getItem('state') ?? undefined);
    }

    return Promise.resolve(false);
  }

  // Logout Methods
  logoutRequested(): void {
    this.isLogoutInitiatedSubject$.next(true);
    // NotifyLogout notifies
    // that user logged out from another
    // tab so end all active sessions
    // across tabs
    this.notifyLogout();

    // Calls oauth logout methods
    this.authLogout();
  }

  authLogout(): void {
    this.oauthService.logOut();
    this.resetLogin();
  }

  notifyLogin(): void {
    localStorage.setItem(this.HAS_TOKEN, 'true');
  }

  notifyLogout(): void {
    localStorage.setItem(this.OAUTH_LOGOUT_KEY, 'true');
    localStorage.removeItem(this.OAUTH_LOGOUT_KEY);

    localStorage.setItem(this.HAS_TOKEN, 'false');
    localStorage.removeItem(this.HAS_TOKEN);
  }

  resetLogin(): void {
    this.userService.setIsLoggedIn(false);
    this.userService.setIsSessionValid(false);
    this.userService.logoutUser();
    this.isLoggedInSubject$.next(false);
    this.isLoginCompleteSubject$.next(false);
    this.isAuthorizedSubject$.next(false);

    sessionStorage.setItem('redirectUrl', '');
    this.router.navigate(['']);
  }

  loginComplete(): void {
    this.isLoginInitiatedSubject$.next(false);
    this.isLoginCompleteSubject$.next(true);
  }
}
