import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Strings } from 'app/core/constants/strings';

@Component({
  selector: 'qdc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

  title = 'Confirm'
  message = 'message';
  cancelText = 'Cancel';
  okText = 'OK';

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>, private strings: Strings,
    @Optional() @Inject(MAT_DIALOG_DATA) data?: any) {
    if (data) {
      this.title = data.title ?? this.title;
      this.message = data.message;
      this.okText = data.okText || this.strings.dialog.ok;
      this.cancelText = data.cancelText || this.cancelText;
    }
  }
}
