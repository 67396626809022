import { DialogService, SessionService, UserService } from 'app/core/services';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IBuildConfiguration, IDictionary, IPagedCollection, IQDCBuild } from 'app/core/interfaces';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QDCBuildService extends QDCApiService<IQDCBuild> {

  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService,
    protected dialogService: DialogService) {
    super(http, environment.qdcBuildsApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  getBuildsFromChipset(chipset: string, buildType?: number): Observable<IPagedCollection<IQDCBuild>> {
    const query: IDictionary = {
      BuildType: buildType,
      Chipset: chipset,
      ['$pageSize']: defaults.maxPageSize,
    };
    return super.getAny('/public', undefined, query);
  }

  getBuildConfigurationByChipset(chipset: string): Observable<IPagedCollection<IBuildConfiguration>> {
    return super.getAny(`/buildconfiguration/${chipset}`);
  }

}
