<!-- used for qualcomm disclaimer information -->
<div class="app-footer flex flex-col">
  <div class="flex pl-[16px]">
    <span class="section-subheading">
      <a class="anchor" href="http://www.qualcomm.com/site/terms-of-use" target="_blank">Terms of Use</a>
    </span>
    <span class="section-subheading">
      <a class="anchor" href="http://www.qualcomm.com/privacy-policy" target="_blank">Privacy Policy</a>
    </span>
    <span class="section-subheading">
      <a class="anchor" href="http://www.qualcomm.com/cookie-policy" target="_blank">Cookie Policy</a>
    </span>
    <span class="section-subheading">
      <a class="ot-sdk-show-settings cookie-anchor" id="ot-sdk-btn">Cookie Settings</a>
    </span>
    <span class="section-subheading">
      <a class="anchor" [href]="this.strings.docs.contactUs" target="_blank">Contact Us</a>
    </span>
  </div>

  <div class="copyright flex flex-col">
    <p style="line-height: 15px; margin: 0; ">
      <span class="material-icons ui-icon-copyright" style="font-size: 1em;"></span>
      2024 ©Qualcomm Technologies, Inc. and/or its affiliated companies. All rights reserved.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 10px;">
      References to "Qualcomm" may mean Qualcomm Incorporated, or subsidiaries or business units within the Qualcomm corporate structure, as applicable.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 0px;">
      Qualcomm Incorporated includes Qualcomm's licensing business, QTL, and the vast majority of its patent portfolio.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 0px;">
      Qualcomm Technologies, Inc., a wholly-owned subsidiary of Qualcomm Incorporated, operates, along with its subsidiaries, substantially all of Qualcomm's engineering, research and development functions, and substantially all of its products and services businesses.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 10px;">
      Qualcomm products referenced on this page are products of Qualcomm Technologies, Inc. and/or its subsidiaries.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 10px;">
      Materials that are as of a specific date, including but not limited to press releases, presentations, blog posts and webcasts, may have been superseded by subsequent events or disclosures.
    </p>
    <p style="line-height: 15px; margin: 0px 0px 10px;">
      Nothing in these materials is an offer to sell any of the components or devices referenced herein.
    </p>
  </div>
</div>
