export class HttpStatus {
  static readonly ok = 200;
  static readonly created = 201;
  static readonly noContent = 204;
  static readonly badRequest = 400;
  static readonly unauthorized = 401;
  static readonly forbidden = 403;
  static readonly notFound = 404;
  static readonly methodNotAllowed = 405;
  static readonly locked = 423;
  static readonly failedDependency = 424;
  static readonly internalServerError = 500;
  static readonly notImplemented = 501;
  static readonly badGateway = 502;
  static readonly serviceUnavailable = 503;
  static readonly corsError = 0;

  static isInformational(status: number): boolean {
    return status >= 100 && status < 200;
  }
  static isSuccess(status: number): boolean {
    return status >= 200 && status < 300;
  }
  static isRedirection(status: number): boolean {
    return status >= 300 && status < 400;
  }
  static isClientError(status: number): boolean {
    return status >= 400 && status < 500;
  }
  static isServerError(status: number): boolean {
    return status >= 500;
  }
}
