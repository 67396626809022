import { Injectable, OnDestroy } from '@angular/core';
import { ILogger } from '../interfaces';
import { LogService } from './log.service';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Subject } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class WebsocketService implements OnDestroy {
  stompClient?: Stomp.Client;
  responseSubject = new Subject<Stomp.Message>();
  reconnectionAttempts = 0;
  maxReconnectionAttempts = 10;
  log: ILogger;
  connected: boolean = false;

  constructor(private logService: LogService, private userService: UserService) {
    this.log = this.logService.get('Websocket Connection');
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  connect(webSocketEndpoint: string, topic = ''): void {
    const ws = SockJS(webSocketEndpoint);
    this.stompClient = Stomp.over(ws);

    // Disable debug console messages
    this.stompClient.debug = () => { };

    this.stompClient!.connect(this.getHeaders(),
      () => this.connectCallback(topic),
      (error: Stomp.Frame | string) => this.errorCallBack(error, webSocketEndpoint, topic),
    );
  }

  // On successful connection
  connectCallback(topic: string): void {
    const _this = this;
    _this.stompClient!.subscribe(topic, function (messageOutput: Stomp.Message) {
      _this.onMessageReceived(messageOutput);
      messageOutput.ack();
    }, { ack: 'client' },
    );
    this.connected = this.stompClient?.connected ?? false;
  }

  // On error, schedule a reconnection attempt
  // Note: reconnection will be attempted only maxReconnectionAttempts times
  errorCallBack(error: Stomp.Frame | string, webSocketEndpoint: string, topic: string): void {
    if (this.reconnectionAttempts < this.maxReconnectionAttempts) {
      setTimeout(() => {
        this.reconnectionAttempts++;
        this.connect(webSocketEndpoint, topic);
      }, 5000);
    } else {
      this.connected = this.stompClient?.connected ?? false;
    }
  }

  disconnect(): void {
    if (this.stompClient) {
      this.stompClient.disconnect(() => { });
      this.connected = this.stompClient?.connected;
    }
  }

  send(topic: string, message: any): void {
    this.stompClient?.send(topic, this.getHeaders(), JSON.stringify(message));
  }

  onMessageReceived(message: Stomp.Message): void {
    this.responseSubject.next(message);
  }

  getHeaders(): any {
    return {'X-QCOM-ServiceAuthorization' : this.userService.getNucleusToken()!};
  }
}
