import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IApiKey, IDictionary, ILogger, IPagedCollection } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { APIUtility } from '../utils/api-utility';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from 'app/core/services/qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from '../constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UMService extends QDCApiService<IApiKey> {

  protected readonly log: ILogger;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    sessionService: SessionService,
    userService: UserService) {
    super(http, environment.umApi + '/' + defaults.apiKeys, logService, router, oauthService, sessionService, userService);
    this.baseUrl = APIUtility.isLocalEnv() ? APIUtility.getLocalUmApi() : environment.qdcApi;
    this.log = logService.get('UM Service');
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      // Throw the error if it is a POST request.
      // Error handling is done as part of subscribe error callback.
      if (mode === this.postRequest) {
        throw error.error;
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  generateNewApiKey(): Observable<IApiKey> {
    return super.submit({});
  }

  deleteApiKey(id: string): Observable<void> {
    return super.deleteItem(id);
  }

  getApiKeys(page?: number, pageSize?: number, user?: string): Observable<IPagedCollection<IApiKey>> {
    const queryParams: IDictionary = {
      ['pageNum']: page ?? 0,
      ['pageSize']: pageSize ?? defaults.pageSize,
      ['username']: user,
    };
    return super.getAnyQuery(queryParams);
  }

}
