import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Strings } from 'app/core/constants/strings';

@Component({
  selector: 'qdc-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss'],
})
export class OkDialogComponent {

  okText = 'OK';
  title = 'Title';
  message = 'message';
  styleClass = '';

  constructor(
    private dialogRef: MatDialogRef<OkDialogComponent>, private strings: Strings,
    @Optional() @Inject(MAT_DIALOG_DATA) private data?: any) {
    if (data) {
      this.title = data.title || '';
      this.okText = data.okText || this.strings.dialog.ok;
      this.message = data.message;
      this.styleClass = data.styleClass;
    }
  }
}
