import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IBuildRecompileRequest, IBuildRecompileResponse, ICreatePackageRequest, ICreatePackageResponse, ICreateSnapshotRequest, ICreateSnapshotResponse, IDictionary, IPackageHistory, IPagedCollection } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { APIUtility } from '../utils/api-utility';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from '../constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BuildRequestService extends QDCApiService<IBuildRecompileRequest> {

  protected baseUrl: string = APIUtility.isLocalEnv() ?
    APIUtility.getLocalBuilderApi() : environment.qdcApi;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    sessionService: SessionService,
    userService: UserService,
  ) {
    super(http, environment.buildRequestApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  createBuildPackage(payload: ICreatePackageRequest): Observable<HttpResponse<ICreatePackageResponse>> {
    return super.submit(payload, environment.buildRequestApi + '/createpackage');
  }

  createOverriddenBuild(payload: IBuildRecompileRequest): Observable<HttpResponse<IBuildRecompileResponse>> {
    return this.submit(payload, environment.buildRequestApi + '/recompile');
  }

  createSnapshot(snapshotRequest: ICreateSnapshotRequest): Observable<HttpResponse<ICreateSnapshotResponse>> {
    return super.submit(snapshotRequest, `${environment.buildRequestApi}/createsnapshot`);
  }

  getPackageHistory(page: number = 1, pageSize: number = defaults.pageSize, buildProcessType: string):
    Observable<IPagedCollection<IPackageHistory>> {
    const query: IDictionary = {
      ['buildProcessType']: buildProcessType,
      ['$page']: page,
      ['$pageSize']: pageSize,
    };
    return this.getAny('/packageshistory', undefined, query);
  }
}
