<div class="warning-dialog">
  <div mat-dialog-content>
    <q-inline-alert color="warning" [label]="message"></q-inline-alert>
  </div>
  <br />
  <div mat-dialog-actions class="flex justify-end items-end content-end">
    <q-button data-testid="ok" variant="fill" color="primary" [mat-dialog-close]="true">
      {{okText}}
    </q-button>
  </div>
</div>
