import { Component, Input } from '@angular/core';

@Component({
  selector: 'qdc-busyable',
  templateUrl: './busyable.component.html',
  styleUrls: ['./busyable.component.scss'],
})
export class BusyableComponent {
  private _isBusy = false;
  get isBusy(): boolean {
    return this._isBusy;
  }
  @Input()
  set isBusy(value: boolean) {
    if (this._isBusy !== value) {
      this._isBusy = value;
    }
  }
  @Input() text?: string;
}
