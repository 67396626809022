export enum OperationMode {
  MIRRORONLY = 0,
  SSHMIRROR = 1,
  SSHONLY = 2,
}

export enum JobState {
    Undefined = 0,
    Submitted = 1,
    Dispatched = 2,
    Running = 3,
    Completed = 4,
    Aborted = 5,
    Setup = 6,
}

export enum JobResult {
    Undefined,
    Pending,
    Successful,
    Unsuccessful,
    Canceled,
    Error,
}

export enum JobType {
    Undefined,
    Automated,
    Interactive,
}

export enum JobMode {
    Undefined,
    Application,
    AIModel,
    StableDiffusion,
}

export enum TestFramework {
    Undefined,
    Monkey,
    Appium,
    PowerShell,
}

export enum ArtifactType {
    Undefined,
    MetaBuild,
    TestPackage,
    TestScript,
    TestLog,
    CrashDump,
    MetaBuildJson,
    TaskLog,
    BuildImage,
}

export enum StorageType {
    Undefined,
    OnPrem,
    AWS,
}

export enum Region {
    Undefined,
    NA,
}

export enum ExecutionError {
    Undefined,
    NoError,
    DeviceError,
    HostError,
    BuildError,
    TestError,
    SystemError,
    FailedToStartVirtualPlatform = 7,
    FailedToInstallPlatformFiles = 8,
    FailedToConfigureDevice = 9,
    FailedToStartDeviceStreaming = 10,
    FailedToValidateDevice = 11,
    FailedToInstallAppPackage = 12,
    FailedDueToDeviceCrash = 13,
    TimedOut = 14,
    FatalBuildError = 15,
    FailedToExtractPackage = 16,
    CrashedDuringSetup = 17
}

export enum TestResultType {
    Undefined,
    Result,
    SubResult,
    IterationResult,
}

export enum DeviceState {
    Undefined,
    Ready,
    Offline,
    Dispatched,
    Running,
    Disabled,
    HostMaintenance,
    HostError,
}

export enum HostState {
    Undefined,
    Ready,
    Offline,
    Maintenance,
    Error,
}

export enum DeviceAction {
    None = 0,
    Cancel = 1,                // Server side execution cancel
    Maintenance = 2,           // Device in Maintenance state
    Moved = 3                  // Device has been moved to a different host
}

export enum CrashType {
    None = 'NONE',
    System = 'System',
    ANR = 'ANR',
    TombStone = 'TombStone',
}

export enum CrashProcessingState {
    Undefined = 0,
    CrashDetected = 1,
    CrashdumpCollected = 2,
    CrashdumpUploaded = 3,
    CrashdumpUploadFailed = 4
}

export enum ToastSeverity {
    Undefined = 'undefined',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
    Success = 'success',
}

export enum OS {
    Android = 0,
    Ubuntu = 1,
    Windows = 2,
    QNX = 3,
    Linux = 4,
}

export enum DeviceType {
  OnPremDevice = 0,
  VirtualDevice = 1,
}

export enum RibbonType {
    New = 'New',
    CameraCapability = 'CameraCapability',
    ComingSoon = 'ComingSoon',
    EarlyAccess = 'EarlyAccess',
    VirtualPlatform = 'VirtualPlatform',
}

export enum TargetState {
    Undefined = 0,
    AvailableNow = 1,
    DevicesBusy = 2,
    Unavailable = 3,
}

export enum DeviceMinutesRequestState {
  Form = 0,
  Loading = 1,
  Success = 2,
  Error = 3,
}

export enum LogUploadStatus {
    Undefined = 0,
    InProgress = 1,
    Completed = 2,
    Failed = 3,
    NoLogs = 4,
}

export enum ChipsetCategory {
    Undefined = 0,
    Mobile = 1,
    Compute = 2,
    IOT = 3,
    Automotive = 4,
}

export enum OrderType {
    QdcUser,
    Corp,
}

export enum QuickTourTypes {
    MobileInteractive,
    ComputeInteractive,
}

export enum AnnouncementType {
  Neutral = 'neutral',
  Informative = 'informative',
  Positive = 'positive',
  Warning = 'warning',
  Negative = 'negative',
}

export enum DeviceStreamingState {
  Offline = 'Offline',
  Online = 'Online',
  Crashed = 'Crashed',
}

export enum BuildType {
    MetaBuild = 0,
    UserCreatedBuild = 1,
    DeviceSnapshot = 2
}

export enum ErrorMsgType {
  Undefined,
  Auth,
  Init,
  Terms,
  OpenId,
}

export enum OSCodeFlag {
    LA = 1,
    UBUN = 2,
    WP = 4,
    LE = 8,
}

export enum NotesLevel {
  Developer = 'Developer',
  User = 'User',
  Streaming = 'Streaming',
  Setup = 'Setup',
}

export enum AAEvent {
  // Megamenu

  // CTA
  CTAGeneric = 'cta',
  CTABlog = 'blog',
  CTATab = 'tab',
  CTABreadcrumb = 'breadcrumb',

  // Form Tracking

  // Other
  Video = 'video',
  SocialShare = 'socialShare',
}
