import { AuthService, LogService, RouterService, SessionService } from './core/services';
import { NavigationStart, Router } from '@angular/router';
import { Component } from '@angular/core';
import { DarkModeService } from '@qui/angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { first } from 'rxjs';

@Component({
  selector: 'qdc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LogService],
})
export class AppComponent {
  title = 'device-cloud';
  // Used to determine if user is logged in
  loggedIn = false;
  // Used to determine if user is authorized
  authorized = false;
  // Used to keep track of when login process has started / ended
  loginComplete = false;
  // Used to signal that login has already been initiated by oauth
  // and that we have been redirected back to the UI from the login site
  loginInitiated = false;
  // Used to show busyable on logout
  logoutInitiated = false;
  // Used to determine when a session gets marked as invalid from the api
  sessionExpired = false;
  // Used to determine when login failed from open id
  loginFailed = false;

  // Used to wait until user has accepted all the terms for the first time
  allTermsAccepted = false;
  showTerms = false;

  isBusy = true;
  busyableText: string = 'Logging into Qualcomm® Device Cloud...';
  skipRedirectUrl: string[] = ['/', '/sessionexpired', '/unauthorized'];

  constructor(
    public oauthService: OAuthService,
    public sessionService: SessionService,
    private routerService: RouterService,
    private authService: AuthService,
    private darkModeService: DarkModeService,
    private router: Router) {

    // When we initially load qdc, save the url so we can redirect
    // the user after login is complete
    this.router.events
      .pipe(
        // Only subscribe till we hit the first navigation start instance
        first(event => event instanceof NavigationStart),
      )
      .subscribe((navStart: any) => {
        const url = navStart.url;
        // Only want to save the url if it is not the default
        // landing, sessionexpired, unauthorized page or auth url with nonce param
        if (!this.skipRedirectUrl.includes(url) && !this.authService.isAuthUrl() && !url.startsWith('/devicecatalog')) {
          sessionStorage.setItem('redirectUrl', url);
        }

        // Init login service after we have determined if we
        // have come from a qdc link or just loading the landing page
        this.authService.init();
      });

    // Fetch user saved mode
    this.darkModeService.getMode();

    // Listen for events when user wants to login / logout
    this.sessionService.login$.subscribe((_) => {
      this.authService.loginRequested();
    });

    this.sessionService.logout$.subscribe((_) => {
      this.authService.logoutRequested();
      this.busyableText = 'Logging out of Qualcomm® Device Cloud...';
    });

    this.sessionService.unauth$.subscribe((_) => {
      this.authService.resetLogin();
    });

    this.sessionService.expired$.subscribe((_) => {
      this.sessionExpired = true;
      this.router.navigate(['/sessionexpired']);
    });

    // Listen for events from the auth service so we can
    // show the appropriate UI components
    this.authService.isLoggedIn$.subscribe((value) => {
      if (value !== this.loggedIn) {
        this.loggedIn = value;
      }
    });
    this.authService.isAuthorized$.subscribe((value) => {
      if (value !== this.authorized) {
        this.authorized = value;
        if (!this.authorized) {
          this.router.navigate(['/unauthorized']);
        }
      }
    });
    this.authService.isLoginComplete$.subscribe((value) => {
      if (value !== this.loginComplete) {
        this.loginComplete = value;
      }
    });
    this.authService.isLoginInitiated$.subscribe((value) => {
      if (value !== this.loginInitiated) {
        this.loginInitiated = value;
      }
    });
    this.authService.isLogoutInitiated$.subscribe((value) => {
      if (value !== this.logoutInitiated) {
        this.logoutInitiated = value;
      }
    });

    this.authService.showTerms$.subscribe((value) => {
      if (value !== this.showTerms) {
        this.showTerms = value;
      }
    });

    this.authService.allTermsAccepted$.subscribe((value) => {
      if (value !== this.allTermsAccepted) {
        this.allTermsAccepted = value;
      }
    });

    this.authService.hasLoginFailed$.subscribe((value) => {
      if (value) {
        this.loginFailed = value;
      }
    });

    this.authService.isSessionExpired$.subscribe((value) => {
      if (value) {
        this.sessionExpired = true;
        this.router.navigate(['/sessionexpired']);
      }
    });
  }

}
