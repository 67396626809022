export const environment = {
  production: false,
  name: 'dev',
  qdcApi: 'https://dev.apigwx-aws.qualcomm.com',
  streamingUrl: 'user.vm.qdc-dev.qualcomm.com/',
  internalSshRoute53Alias: 'ssh.nginx.qdc-internal-dev.qualcomm.com',
  sshRoute53Alias: 'user.ssh.qdc-dev.qualcomm.com',

  // Apigee basepaths
  storageApi: '/qdc/v1/storage',
  storedArtifactsApi: '/qdc/v1/storedartifacts',
  hostsApi: '/qdc/v1/hosts',
  usersApi: '/qdc/v1/users',
  jobsApi: '/qdc/v1/jobs',
  listfilesApi: '/qdc/v1/listfiles',
  logsApi: '/qdc/v1/logs',
  executionsApi: '/qdc/v1/executions',
  settingsApi: '/qdc/v1/settings',
  clearCacheApi: '/qdc/v1/clearcache',
  devicesApi: '/qdc/v1/Devices',
  skusApi: '/qdc/v1/skus',
  publicSkusCatalogApi: '/qdc/v1/skus/catalog',
  accountApi: '/qdc/v1/account',
  agreementsApi: '/qdc/v1/agreements',
  umApi: '/qdc/v1/um',
  cartApi: '/qdc/v1/cart',
  itemApi: '/qdc/v1/item',
  salesOrderApi: '/qdc/v1/salesorder',
  announcementsApi: '/qdc/v1/announcements',
  qdcBuildsApi: '/qdc/v1/builds',
  builderServiceApi: '/qdc/v1/build',
  buildRequestApi: '/qdc/v1/buildrequest',
  couponApi: '/qdc/v1/coupon',
  sshKeyApi: '/qdc/v1/SshPublicKey',
  nmWsApi: '/qdc/nm/ws',
  notificationApi: '/qdc/nm/v1',

  qdcUrlLocal: 'http://localhost:4200/',
  qdcUrlLocalRedirect: 'http://localhost.com:4200/',
  qdcUrlDev: 'https://ui.qdc-internal-dev.qualcomm.com/',
  qdcUrlProd: 'https://ui.qdc-internal-demo.qualcomm.com/',

  // Used to configure apigee headers
  apigeeConfig: {
    appName: 'QDC-UI',
    clientType: 'UI',
    tokenType: 'OIDC',
    clientId: 'zzcWWfnpLiKi1PPOC3oQwAV2eJwnYiNvhtLGO0Je8Hqyh1BE',
  },
  oauthConfig: {
    issuer: 'https://test.openid.qualcomm.com/affwebservices/CASSO/oidc/QDC',
    tokenEndpoint: 'https://test.openid.qualcomm.com/affwebservices/CASSO/oidc/QDC/token',
    userinfoEndpoint: 'https://test.openid.qualcomm.com/affwebservices/CASSO/oidc/QDC/userinfo',
    revocationEndpoint: 'https://test.openid.qualcomm.com/affwebservices/CASSO/oidc/QDC/revoke',
    clientId: '000b3cc9-95f1-1332-a899-90550a310000',
    dummyClientSecret: 'bAeLfTlkJlFuJeI5T8hJ4+8O/yGdtyDRqcjLI8KH0aQ=',

    // Hostname could be localhost or localhost.com when hosting locally
    redirectUri: '',
    logoutUrl: '',

    responseType: 'code',
    scope: 'openid profile',
  },

  // Stripe
  stripePublishableKey: 'pk_test_51MjFmpGq5cAz4Ynv9KoTlUPXOArWqqfpRDJfL5b1EFnqhHzgzmYQqOkFdYPfuljtXoDARSYGHWgq55ngZ7MF8l3s00ybEeO8FC',

  // Docs site
  docsUrl: 'https://ui.qdc-internal-dev.qualcomm.com/support',
};

// Set the base qdc url from browser as redirect url
environment.oauthConfig.redirectUri = window.location.hostname.includes('localhost') ? environment.qdcUrlLocalRedirect : window.location.origin + '/';
environment.oauthConfig.logoutUrl = `https://test.openid.qualcomm.com/affwebservices/redirectjsp/logout.jsp?redirect=https://ext.qclogintest.qualcomm.com/logoff_blank.html?returnURL=${environment.oauthConfig.redirectUri}`;
