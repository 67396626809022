<div class="flex justify-start items-start gap-[1rem] p-[8rem] flex-1">
  <div class="flex w-1/2 justify-center items-start pt-[6rem] flex-1">
    <div class="flex flex-col gap-[20px]">
      <div class="flex justify-start items-center gap-[10px]">
        <img class="device-cloud-img" src="../../../../../assets/images/device-cloud-icon.webp" alt="QDC logo" />
        <span class="text font-medium pb-[4px]">Qualcomm® Device Cloud</span>
      </div>

      <span class="text largest">Session Expired</span>

      <span class="text mediumV2">Looks like your session has expired. <br>
        Click below to logout of your current session and relogin to continue.</span>

      <q-button data-test-id="logout" variant="fill" color="primary" [startIcon]="icons.LogOut" (click)="returnHome()">
        Logout
      </q-button>
    </div>

  </div>
  <div class="flex flex-1 w-1/2 justify-center items-start">
    <img style="width: 500px" src="../../../../../assets/images/figma-devices/default-device.webp" alt="Default device" />
  </div>
</div>
