export enum QDCLogLevel {
  undefined,
  debug,
  info,
  warn,
  error,
  fatal,
  success,
  httpjs,
}
