import {Injectable} from '@angular/core';
import {QuickTourTypes} from '../enums';

@Injectable({
  providedIn: 'root',
})
export class QuickTourService {

  _showHomePageTour: boolean = false;
  _showMobileInteractiveTour: boolean = false;
  _showComputeInteractiveTour: boolean = false;

  _retakeTourElement?: HTMLElement;

  getShowTour(tourType?: QuickTourTypes): boolean {
    switch(tourType) {
      case QuickTourTypes.MobileInteractive:
        return this._showMobileInteractiveTour;
      case QuickTourTypes.ComputeInteractive:
        return this._showComputeInteractiveTour;
      default:
        return false;
    }
  }

  setShowTour(value: boolean, tourType?: QuickTourTypes): void {
    switch(tourType) {
      case QuickTourTypes.MobileInteractive:
        this._showMobileInteractiveTour = value;
        break;
      case QuickTourTypes.ComputeInteractive:
        this._showComputeInteractiveTour = value;
        break;
      default:
        break;
    }
  }

  getRetakeTourElement(): HTMLElement | undefined {
    return this._retakeTourElement;
  }

  setRetakeTourElement(retakeTourElement: HTMLElement): void {
    this._retakeTourElement = retakeTourElement;
  }
}
