import { Observable, lastValueFrom } from 'rxjs';
import {
  ConfirmDialogComponent,
} from 'app/shared/components/confirm-dialog/confirm-dialog.component'; // Import directly
import { ILogger } from 'app/core/interfaces';
import { Injectable } from '@angular/core';
import { LogService } from 'app/core/services/log.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { OkDialogComponent } from 'app/shared/components/ok-dialog/ok-dialog.component';
import { Strings } from 'app/core/constants/strings';
import { TextDialogComponent } from 'app/shared/components/text-dialog/text-dialog.component';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';
import { defaults } from 'app/core/constants/defaults';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  private log: ILogger;

  constructor(
    logService: LogService, private strings: Strings,

    public dialog: MatDialog, private messageService: MessageService) {
    this.log = logService.get('Dialog Service');
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

  private isPromise(obj: any): boolean {
    return obj && typeof (obj.then) === 'function';
  }

  private processCallback(callback: () => (any | Promise<void>), success: string, failure: string): void {
    try {
      let result = callback();

      if (this.isPromise(result)) {

        result = <Promise<void>>result;

        result.then(() => {
          this.showMessage(success);
        }).catch((reason: string | undefined) => {
          if (reason) {
            this.showMessage(failure, reason);
          } // Else, the promiser decided to swallow error
        });

      } else {
        this.showMessage(success);
      }
    } catch (ex) {
      this.log.fatal(failure, ex);
    }
  }

  showMessage(
    message: string, details?: string, duration: number = defaults.snackBarTimeout): any {
    const text = `${message}${details ? ' ' : ''}${details || ''}`;
    this.log.debug(text);
  }

  showConfirmDialog(message: string, okText?: string, cancelText?: string, title?: string): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        message,
        okText: okText || this.strings.dialog.ok,
        cancelText: cancelText || this.strings.dialog.cancel,
      },
      disableClose: true,
    }).afterClosed();
  }

  showOkDialog(message: string, title?: string, styleClass?: string): Promise<void> {
    const okDiag$ = this.dialog.open(OkDialogComponent, {
      data: {
        message,
        title,
        styleClass,
      },
    }).afterClosed();
    return lastValueFrom(okDiag$);
  }

  // Returns a promise that has 2 fields:
  // Confirmed: true if user clicked OK, false otherwise
  // Text: the text at the time of user cliking OK or Cancel
  showTextDialog(
    message: string, text?: string, okText?: string, cancelText?: string):
    Promise<{ confirmed: boolean, text: string }> {

    return new Promise<{ confirmed: boolean, text: string }>((resolve, reject) => {

      const data = {
        message,
        okText: okText || this.strings.dialog.ok,
        cancelText: cancelText || this.strings.dialog.cancel,
        text: text || '',
      };

      const diagClosed$ = this.dialog.open(TextDialogComponent, {data}).afterClosed();
      lastValueFrom(diagClosed$).then((confirmed) => {
        resolve({ confirmed, text: data.text });
      });

    });
  }

  showWarningDialog(message: string, okText?: string, styleClass?: string): Promise<void> {
    const warnDiag$ = this.dialog.open(WarningDialogComponent, {
      data: {
        message,
        styleClass,
        okText: okText || this.strings.dialog.ok,
      },
    }).afterClosed()
    return lastValueFrom(warnDiag$);
  }
}
