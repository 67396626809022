import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Strings } from 'app/core/constants/strings';

@Component({
  selector: 'qdc-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss'],
})
export class TextDialogComponent {

  okText = 'OK';
  cancelText = 'Cancel';
  message = '';

  constructor(
    private dialogRef: MatDialogRef<TextDialogComponent>, private strings: Strings,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any) {
    if (data) {
      this.okText = data.okText || this.strings.dialog.ok;
      this.cancelText = data.cancelText || this.cancelText;
      this.message = data.message;
    }
  }
}
