<div id="busyable" fxFlexFill>
  <ng-content></ng-content>
  <div id="mask" *ngIf="isBusy">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <span *ngIf="text" class="text larger busyable-text">{{text}}</span>
    </div>
  </div>
</div>
