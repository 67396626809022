export enum FilterOps {
  eq, // Equal
  equals, // Equal for nonlazy grids for primeng filtering
  lt, // Less than
  le, // Less than or equal
  lte, // Less than or equal for nonlazy grids for primeng filtering
  gt, // Greater than
  ge, // Greater than or equal
  gte, // Greater than or equal for nonlazy grids for primeng filtering
}
