import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LogService, UserService } from '../services';
import { Observable, from, lastValueFrom } from 'rxjs';
import { APIUtility } from '../utils/api-utility';
import { ILogger } from '../interfaces';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../../environments/environment';

// HttpInterceptor to attach all non-auth headers and tracingID to API calls.
// (Auth header gets added in authHttpInterceptor)
// For openid calls, trackingId is added as a parameter(adding as header throws error).
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private readonly openIdApi = 'openid';
  protected readonly log: ILogger;

  constructor(
    logService: LogService,
    protected oauthService: OAuthService,
    protected userService: UserService) {
    this.log = logService.get('QDC UI - Header Interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    // Skip Public API calls
    if (req.url.includes(environment.publicSkusCatalogApi)) {
      return await lastValueFrom(next.handle(req));
    }
    // For openid calls, trackingId is added as a parameter(adding as header throws error).
    if (req.url.includes(this.openIdApi)) {
      // Clone request and replace URL updated with tracing ID as param for tracking call
      const updatedReq = req.clone({
        // Append to params if any or add new param
        url: req.url.includes('?') ? `${req.url}&X-QCOM-TracingID=${uuidv4()}` :
          `${req.url}?X-QCOM-TracingID=${uuidv4()}`,
      });
      return await lastValueFrom(next.handle(updatedReq));
    }
    const headers = this.getHeaders(req.headers);

    // Clone request and replace headers updated with nucleus token
    const authReq = req.clone({
      headers: headers,
    });

    return await lastValueFrom(next.handle(authReq));
  }

  getHeaders(additionalHeaders?: HttpHeaders): HttpHeaders {
    let headers = additionalHeaders ?? new HttpHeaders();
    // Local env bypasses apigee so specify headers for qdcApi calls here
    if (APIUtility.isLocalEnv()) {
      const user = this.userService.getUsername();
      headers = headers
        .set('Content-Type', 'application/json')
        .set('X-QCOM-User', user!);
    } else {
      return APIUtility.buildHeaders(this.oauthService.getAccessToken(), headers);
    }
    return headers;
  }

}
