<div class="confirm-dialog">
  <span mat-dialog-title style="padding-bottom: 10px; margin-top: -10px;">{{title}}</span>
  <mat-dialog-content>
    <div class="spanText fontFamily pb-[25px] whitespace-pre-line">{{message}}</div>
  </mat-dialog-content>
  <div mat-dialog-actions class="flex items-center content-center gap-[10px]">
    <q-button data-testid="cancel" variant="fill" color="neutral" [mat-dialog-close]="false">
      {{cancelText}}
    </q-button>

    <q-button data-testid="ok" variant="fill" color="primary" [mat-dialog-close]="true">
      {{okText}}
    </q-button>
  </div>
</div>
