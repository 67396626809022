import { AuthService, LogService, SessionService, UserService } from '../services';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { ILogger } from '../interfaces';
import { Injectable } from '@angular/core';
import { defaults } from '../constants/defaults';
import { environment } from 'environments/environment';

// HttpInterceptor to add Nucleus auth token. Also fetches new auth token if current token is about to expire
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly allowedApis = [environment.umApi, 'openid', 'savejsonlog'];
  private readonly allowedUMApi = defaults.apiKeys;
  protected readonly log: ILogger;
  private readonly minutesToRefreshToken: number = 5;

  constructor(protected authService: AuthService,
    logService: LogService,
    protected sessionService: SessionService,
    protected userService: UserService) {
    this.log = logService.get('QDC UI - Auth Interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }
  // Skip Public API calls
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes(environment.publicSkusCatalogApi)) {
      return await lastValueFrom(next.handle(req));
    }

    // Do not intercept calls that are part of allowedApis[].
    // UM Keys API need authorization token for validation, so continue adding Auth token for it.
    if (!req.url.includes(this.allowedUMApi) &&
      this.allowedApis.some(api => req.url.toLowerCase().includes(api))) {
      return await lastValueFrom(next.handle(req));
    }

    while (this.userService.getIsAuthorizationInProgress()) {
      // Wait till an in progress 'users/authorization' call to get new token is complete
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    let timeToExpire = new Date(this.userService.getNucleusTokenExpireTime()! * 1000).getTime() - Date.now();
    // Convert to mins
    timeToExpire = (timeToExpire / 1000)/60;

    // Fetch new token if current token's time to expire is less than minutesToRefreshToken.
    if (timeToExpire < this.minutesToRefreshToken) {
      const isAuth = await this.authService.authorizeUserFromUm();
      // If authorization call to get new token fails redirect users to session expired page
      if (!isAuth) {
        this.sessionService.sessionExpired();
      }
    }

    const headers = this.getHeadersWithAuthToken(req.headers);
    // Clone request and replace headers updated with nucleus token
    const authReq = req.clone({
      headers: headers,
    });

    return await lastValueFrom(next.handle(authReq));
  }

  getHeadersWithAuthToken(additionalHeaders?: HttpHeaders): HttpHeaders {
    let headers = additionalHeaders ?? new HttpHeaders();
    const nucleusToken = this.userService.getNucleusToken();
    headers = headers.set('X-QCOM-ServiceAuthorization', nucleusToken!);
    return headers;
  }
}
