import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpCancelService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';

// HttpInterceptor to cancel pending HTTP calls on route change
@Injectable()
export class CancelInterceptor implements HttpInterceptor {

  private readonly allowedApis = [environment.agreementsApi, environment.umApi, environment.usersApi, 'openid'];
  private readonly allowedRoutes = ['/forbidden', '/unauthorized'];
  skipCancel: boolean = false;

  constructor(private router: Router,
    private httpCancelService: HttpCancelService) {
    router.events.subscribe((event) => {
      // If user is on one of the allowedRoutes, skip cancelling pending calls,
      // This allows logging error responses.
      if (event instanceof NavigationEnd) {
        if (this.allowedRoutes.some(url => url.includes(event.url))) {
          this.skipCancel = true;
        }
      }
      if (event instanceof ActivationStart && !this.skipCancel) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Skip openid urls
    if (this.allowedApis.some(api => req.url.includes(api))) {
      return next.handle(req);
    }
    return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}
