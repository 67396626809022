import { ArtifactType, Region, StorageType } from 'app/core/enums';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ILogger, IStoredArtifact } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from 'app/core/services/qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class ArtifactService extends QDCApiService<IStoredArtifact> {
  protected readonly storedArtifactsBasePath: string = environment.qdcApi + environment.storedArtifactsApi;
  protected readonly storageBasePath: string = environment.qdcApi + environment.storageApi;

  protected readonly fileUpload = '/upload';
  protected readonly startUpload = '/upload/start';
  protected readonly continueUpload = '/upload/continue';
  protected readonly endUpload = '/upload/end';
  protected readonly uploadStatus = '/upload/status';
  protected readonly storedArtifacts = '/storedartifacts';

  // Max file size vars
  readonly maxUploadSizeMB: number = 2000; // 2GB
  readonly maxUploadSizeBytes: number = this.maxUploadSizeMB * 1024 * 1024;
  readonly chunkSizeMB: number = 5;
  readonly chunkSizeBytes: number = this.chunkSizeMB * 1024 * 1024;

  protected readonly log: ILogger;

  private user: string;

  constructor(protected http: HttpClient, logService: LogService, router: Router, userService: UserService,
    protected oauthService: OAuthService, protected sessionService: SessionService) {
    super(http, environment.storedArtifactsApi, logService, router, oauthService, sessionService, userService);
    if (environment.name === 'local') {
      this.baseUrl = '';
    }
    this.log = logService.get('Artifact Service');
    this.user = userService.getUsername()!;
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      throw error;
    }
    return '';
  }

  upload(file: File, fileName: string, artifactType: ArtifactType): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': file.type,
    });

    if (environment.name === 'local') {
      headers = headers.set('X-QCOM-User', this.user!);
      return this.http.post(`${environment.storageApi}${this.fileUpload}?virtualPath=${fileName}&category=${artifactType}`, file, {headers});
    }

    return this.submit(file, `${environment.storageApi}${this.fileUpload}?virtualPath=${fileName}&category=${artifactType}`, headers, false);
  }

  startChunkedUpload(fileName: string, artifactType: ArtifactType): Observable<any> {
    if (environment.name === 'local') {
      const headers = new HttpHeaders({
        'X-QCOM-User': this.user!,
      });
      return this.http.post(`${environment.storageApi}${this.startUpload}?virtualPath=${fileName}&category=${artifactType}`,
        {}, {headers});
    }
    // Save common tracing ID when chunked upload starts
    this.userService.setWorkflowTracingId('chunkedUpload', uuidv4());

    // Use common tracing ID for chunked upload
    const savedTracingId = this.userService.getWorkflowTracingId('chunkedUpload');
    let headers = new HttpHeaders();
    if (savedTracingId) {
      headers = headers.set('X-QCOM-TracingID', savedTracingId);
    }
    return this.submit({}, `${environment.storageApi}${this.startUpload}?virtualPath=${fileName}&category=${artifactType}`, headers);
  }

  // Unlike upload(), storage API requires the virtual path for multipart file uploads.
  // This is returned in the response from startChunkedUpload().
  continueChunkedUpload(path: string, artifactType: ArtifactType, offset: number, part: number, size: number,
    chunk: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    if (environment.name === 'local') {
      headers = headers.set('X-QCOM-User', this.user!);
      return this.http.post(`${environment.storageApi}${this.continueUpload}?virtualPath=${path}&category=${artifactType}&offset=${offset}&part=${part}&size=${size}`, chunk, {headers});
    }

    // Use common tracing ID for chunked upload
    const savedTracingId = this.userService.getWorkflowTracingId('chunkedUpload');
    if (savedTracingId) {
      headers = headers.set('X-QCOM-TracingID', savedTracingId);
    }
    return this.submit(chunk, `${environment.storageApi}${this.continueUpload}?virtualPath=${path}&category=${artifactType}&offset=${offset}&part=${part}&size=${size}`, headers, false);
  }

  // Unlike upload(), storage API requires the virtual path for multipart file uploads.
  // This is returned in the response from startChunkedUpload().
  endChunkedUpload(path: string, artifactType: ArtifactType): Observable<any> {
    let headers = new HttpHeaders();
    if (environment.name === 'local') {
      headers = headers.set('X-QCOM-User', this.user!);
      return this.http.post(`${environment.storageApi}${this.endUpload}?virtualPath=${path}&category=${artifactType}`, {}, {headers});
    }

    // Use common tracing ID for chunked upload
    const savedTracingId = this.userService.getWorkflowTracingId('chunkedUpload');
    if (savedTracingId) {
      headers = headers.set('X-QCOM-TracingID', savedTracingId);
    }
    return this.submit({}, `${environment.storageApi}${this.endUpload}?virtualPath=${path}&category=${artifactType}`, headers);
  }

  // Use this endpoint if a chunked file upload fails midway and we want resume the chunked upload
  // with only the non-uploaded chunks. From UI, we just restart the whole upload from the beginning for now.
  getUploadStatus(file: File): Observable<any> {
    // TODO: Add into the retry strategy for chunked uploads so we can clear the partial upload
    // of the same file on the S3 side.
    const query = {
      ['path']: `${file.name}`,
    };

    return this.getAny(`${this.storageBasePath}${this.uploadStatus}`, true, query);
  }

  createStoredArtifact(artifactName:string, artifactType: ArtifactType, artifactPath: string): Observable<any> {
    if (environment.name === 'local') {
      return this.submit(
        {
          name: artifactName,
          type: artifactType,
          createdByUser: this.user,
          locations: [{
            storageType: StorageType.AWS,
            region: Region.NA,
            path: artifactPath,
          }],
        },
        `${environment.qdcApi}${this.storedArtifacts}`,
      );
    }
    return this.submit(
      {
        name: artifactName,
        type: artifactType,
        createdByUser: this.user,
        locations: [{
          storageType: StorageType.AWS,
          region: Region.NA,
          path: artifactPath,
        }],
      },
    );
  }

  getStoredArtifactsForUser(artifactType: ArtifactType): Observable<any> {
    // Hardcoding a large pagesize since API doesn't support non-paged responses.
    const query = {
      ['type']: artifactType ?? '',
      ['$pageSize']: defaults.maxPageSize,
    };

    if (environment.name === 'local') {
      return this.getAny(`${environment.qdcApi}${this.storedArtifacts}`, true, query);
    }

    return this.getAny(undefined, undefined, query);
  }

}
