import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IDictionary, IKey, IPagedCollection } from '../interfaces';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { UserService } from './user.service';
import { defaults } from '../constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SshKeyService extends QDCApiService<IKey> {

  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService) {
    super(http, environment.sshKeyApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      // Throw POST errors to display in inline alert
      if (mode === 'POST') {
        throw error.error;
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  GetSshKeys(active: boolean, page?: number, pageSize?: number):
  Observable<IPagedCollection<IKey>> {
    const query: IDictionary = {
      ['$page']: page ?? 1,
      ['$pageSize']: pageSize ?? defaults.maxPageSize,  // Default used for ssh key dropdown in test configuration
      ['active']: active,
    };

    return super.getAny('', false, query);
  }

  CreateSshKey(newKey: IKey): Observable<IKey> {
    if (newKey.key) {
      newKey.key = encodeURIComponent(newKey.key);
    }
    return super.submit(newKey);
  }

  DeleteSshKey(keyId: number): Observable<void> {
    return super.deleteItem(keyId);
  }

}
