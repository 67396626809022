<div style="height: 100%;">
  <ng-container>
    <qui-root>
      <qdc-busyable [isBusy]="!loginComplete" [text]="busyableText">

        <!-- user is logged into OIDC -->
        <ng-container *ngIf="loggedIn && loginComplete">
          <!-- if user has not accepted all the terms, show terms ui -->
          <ng-container *ngIf="showTerms && authorized">
            <qdc-busyable [isBusy]="isBusy">
              <div class="layout-main">
                <qdc-term-acceptance></qdc-term-acceptance>
              </div>
            </qdc-busyable>
          </ng-container>

          <!-- show ui if user passes all checks (authorized && all terms accepts) -->
          <ng-container *ngIf="authorized && allTermsAccepted && !sessionExpired">
            <qdc-top-bar [showLoggedInMenuItems]="true"></qdc-top-bar>
            <div class="layout-main">
              <router-outlet></router-outlet>
            </div>
          </ng-container>

          <!--
            show session expired page when api needs refreshed credentials
            show unauthorized page if user is logged in but does not have access to qdc
          -->
          <ng-container *ngIf="sessionExpired || !authorized">
            <qdc-top-bar [showLoggedInMenuItems]="false" [showLogoutButton]="true"></qdc-top-bar>
            <div class="layout-main">
              <router-outlet></router-outlet>
            </div>
          </ng-container>
        </ng-container>

        <!-- if user is not logged in because we have not tried yet, show landing page -->
        <ng-container *ngIf="!loggedIn && loginComplete && !loginFailed">
          <qdc-top-bar [showLoggedInMenuItems]="false"></qdc-top-bar>
          <div class="layout-main">
            <router-outlet></router-outlet>
          </div>
        </ng-container>

        <!-- if user is not logged in because login failed, show unauthorized page -->
        <ng-container *ngIf="!loggedIn && loginComplete && loginFailed">
          <qdc-top-bar [showLoggedInMenuItems]="false"></qdc-top-bar>
          <div class="layout-main">
            <qdc-unauthorized></qdc-unauthorized>
          </div>
        </ng-container>

        <qdc-app-footer></qdc-app-footer>
      </qdc-busyable>
    </qui-root>
  </ng-container>
</div>
