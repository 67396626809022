<p-dialog *ngIf="showTerm" appendTo="body" [(visible)]="showTerm" [resizable]="false" [blockScroll]="true"
  [draggable]="false" [resizable]="false" [style]="{ width: '65vw' }" [focusTrap]="false"
  [modal]="true" [closable]="false">
  <qdc-busyable [isBusy]="isBusy">
    <div class="flex flex-col gap-[20px] justify-center items-center">
      <span class="text largest">Terms & Conditions</span>
      <div class="terms-container">
        <div *ngIf="termsAndAgreements.terms?.length" class="terms-text-container" (scroll)="onScroll($event)">
            <div class="html-container" [innerHtml]="termAndAgreementText"></div>
        </div>
      </div>
    </div>
  </qdc-busyable>
  <div class="flex gap-[8px] pt-[30px] justify-end">
    <q-button data-testid="decline-term" variant="outline" [disabled]="disableAcceptAndDeclineBtn"
    (click)="onClickDeclineTerm()">Decline</q-button>
    <q-button data-testid="accept-term" variant="fill" color="primary" [disabled]="disableAcceptAndDeclineBtn"
    (click)="onClickAcceptTerm()">Accept</q-button>
  </div>
</p-dialog>


<p-dialog appendTo="body" [(visible)]="showMessage" [resizable]="false" [blockScroll]="true"
  [draggable]="false" [resizable]="false" [focusTrap]="false"
  [modal]="true" [closable]="false">
    <div>
      Please accept the latest Terms and Agreements to continue accessing Qualcomm® Device Cloud.
    </div>
    <div class="flex gap-[8px] pt-[30px] justify-end">
      <q-button data-testid="latest-term-error" variant="fill" color="primary" (click)="onClickDeclineTerm()">Ok</q-button>
    </div>
</p-dialog>
