import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISignedResponse, ISignedTermData, ITermAcceptResponse, ITermAcceptance } from 'app/core/interfaces';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'app/core/services';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TermAcceptanceService {
  baseUrl = '';
  headers!: HttpHeaders;
  constructor(private http: HttpClient, private oauthService: OAuthService,
    private userService: UserService) {

    this.baseUrl = environment.qdcApi + environment.agreementsApi;
  }

  // Observable sources
  private acceptedAllTerms = new BehaviorSubject<boolean>(false);
  private latestTermsSigned = new BehaviorSubject<boolean>(true);

  // Observable streams
  acceptedAllTerms$ = this.acceptedAllTerms.asObservable();
  latestTermsAccepted$ = this.latestTermsSigned.asObservable();

  hasUserSignedTerms(): Observable<ISignedResponse> {
    return this.http.get<ISignedResponse>(`${this.baseUrl}/signed`)
  }

  getUserTermAndAgreements(): Observable<ITermAcceptance> {
    return this.http.get<ITermAcceptance>(`${this.baseUrl}/terms`);
  }

  acceptTermAndAgreements(data: ISignedTermData): Observable<ITermAcceptResponse> {
    return this.http.post<ITermAcceptResponse>(`${this.baseUrl}/accept`, data);
  }

  acceptedAllTermsAndAgreements(data: boolean): void {
    this.acceptedAllTerms.next(data);
  }

  isLatestTermsSigned(data: boolean): void {
    this.latestTermsSigned.next(data);
  }

}
