import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LogService, UserService } from '../services';
import { Observable, catchError, finalize, from, lastValueFrom } from 'rxjs';
import { APIUtility } from '../utils/api-utility';
import { ILogger } from '../interfaces';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

//  HttpInterceptor to log all outgoing API calls, error responses, and time taken to complete API call
@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private readonly openIdApi = 'openid';
  protected readonly log: ILogger;

  constructor(
    logService: LogService,
    protected userService: UserService) {
    this.log = logService.get('QDC UI - Logging Interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    // Skip Public API calls
    if (req.url.includes(environment.publicSkusCatalogApi)) {
      return await lastValueFrom(next.handle(req));
    }
    // Do not intercept openid calls since we dont have OIDC token till we login
    if (req.url.includes(this.openIdApi)) {
      return await lastValueFrom(next.handle(req));
    }
    const tracingId = req.headers.has('X-QCOM-TracingID') ? req.headers.get('X-QCOM-TracingID') : null;
    // Log outgoing HTTP calls,
    // but don't log outgoing save log call
    // as this will cause an infinite loop trying to log savejsonlog calls.
    if (!req.url.toLowerCase().includes('savejsonlog')) {
      this.logHttpCall(req.method, req.url, tracingId, undefined, req.body);
    }

    let isErrorLogged = false;
    const startTime = Date.now();
    return await lastValueFrom(next.handle(req).pipe(
      catchError((e) => {
        // Log error response,
        // but don't log error response when logging api fails as this will cause an infinite loop
        // trying to log an api that keeps failing.
        if (!req.url.toLowerCase().includes('savejsonlog')) {
          isErrorLogged = true;
          const timeTakenToCompleteCall = Date.now() - startTime;
          this.logHttpError(req.method, req.url, e, tracingId, timeTakenToCompleteCall);
        }
        throw e;
      }),
      finalize(() => {
        const timeTakenToCompleteCall = Date.now() - startTime;
        // Log total time taken to complete call
        if (!isErrorLogged && !req.url.toLowerCase().includes('savejsonlog')) {
          this.logHttpCall(req.method, req.url, tracingId, timeTakenToCompleteCall);
        }
      }),
    ));
  }

  logHttpCall(requestMethod: string, url: string, tracingId: string | null, time?: number, payload?: any): void {
    const additionalFields: Record<string, string> = {};
    if (tracingId != null) {
      additionalFields['X-QCOM-TracingID'] = tracingId;
    }
    if (time) {
      additionalFields['timeToCompleteMS'] = `${time.toString()} ms`;
    }
    if (payload) {
      payload = JSON.stringify(payload);
      this.log.httpjs(requestMethod, url, payload, additionalFields);
    } else {
      this.log.httpjs(requestMethod, url, undefined, additionalFields);
    }
  }

  logHttpError(mode: string, url: string, error: HttpErrorResponse, tracingId: string | null, time?: number): void {
    // Log error response as httpjs log
    const additionalFields: Record<string, string> = {};
    if (error) {
      additionalFields['error'] = JSON.stringify(error);
      if (error.status !== undefined) {
        additionalFields['errorStatus'] = error.status.toString();
      }
      if (error.error) {
        additionalFields['errorMessage'] = APIUtility.getApiErrorMessage(error.error);
      }
      if (tracingId != null) {
        additionalFields['X-QCOM-TracingID'] = tracingId;
      }
      if (time) {
        additionalFields['timeToCompleteMS'] = `${time.toString()} ms`;
      }
    }
    this.log.httpjs(mode, url, undefined, additionalFields);
  }
}
