import { Component } from '@angular/core';
import { Strings } from 'app/core/constants/strings';

@Component({
  selector: 'qdc-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {

  constructor(protected strings: Strings) { }

}
