import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  // Observable sources
  private loginSource = new Subject<void>();
  private logoutSource = new Subject<void>();
  private unauthSource = new Subject<void>();
  private expiredSource = new Subject<void>();

  // Observable streams
  // Subscribe to these to perform actions when the source changes
  login$ = this.loginSource.asObservable();
  logout$ = this.logoutSource.asObservable();
  unauth$ = this.unauthSource.asObservable();
  expired$ = this.expiredSource.asObservable();

  // Service message commands
  // Use these when updating/changing the source for subscribers to do actions on
  loginRequested(): void {
    this.loginSource.next();
  }

  logoutRequested(): void {
    this.logoutSource.next();
  }

  userUnauthorized(): void {
    this.unauthSource.next();
  }

  sessionExpired(): void {
    this.expiredSource.next();
  }
}
