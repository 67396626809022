import { ILogEvent, ILogService, ILogger } from 'app/core/interfaces';
import { Injectable, Injector } from '@angular/core';
import { QNotification, QNotificationService } from '@qui/angular';
import { APIUtility } from '../utils/api-utility';
import { GlobalErrorHandlerService } from 'app/core/services/global-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from 'app/core/classes/logger';
import { OAuthService } from 'angular-oauth2-oidc';
import { QDCLogLevel } from 'app/core/enums';
import { QDCLogService } from 'app/core/services/qdc-log.service';
import { ReplaySubject } from 'rxjs';
import { UserService } from 'app/core/services';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';

@Injectable()
export class LogService extends GlobalErrorHandlerService implements ILogService {

  private readonly registry = new Map<string, ILogger>();

  // This can be subscribed to by UI to show all the logs.
  readonly logs = new ReplaySubject<ILogEvent>();

  private readonly selfLogger: ILogger;

  constructor(
    protected qdcLogService: QDCLogService, private http: HttpClient,
    private oauthService: OAuthService, protected injector: Injector,
    protected userService: UserService, private qNotificationService: QNotificationService) {
    super(injector, qdcLogService, userService);

    this.selfLogger = new Logger(LogService.name, this);
    this.registry.set(LogService.name, this.selfLogger);

    const firstLog = 'QDC Log Service is initialized.';
    this.selfLogger.debug(firstLog);
  }

  // As owner, the typename of the class using the logger may be provided (e.g. classTypeName.type).
  // Note: If multiple instances of the same type will be active at the same time,
  // You should provide a unique name (i.e. suffix with Date.now())
  get(owner: string): ILogger {

    if (owner === LogService.name) {
      this.selfLogger.fatal(`The log owner '${LogService.name}' is reserved.`);
    }

    let logger: ILogger | undefined = this.registry.get(owner);
    if (logger === undefined) {
      logger = new Logger(owner, this);

      this.selfLogger.debug(`Registering ${owner}`);
      this.registry.set(owner, logger);
    }

    return logger;
  }

  debug(owner: string, message: string, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.debug, owner, message, undefined, additionalFields);
  }

  success(owner: string, message: string, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.success, owner, message, undefined, additionalFields);
  }

  info(owner: string, message: string, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.info, owner, message, undefined, additionalFields);
  }

  warn(owner: string, message: string, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.warn, owner, message, undefined, additionalFields);
  }

  error(owner: string, message: string, error?: any, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.error, owner, message, error, additionalFields);
  }

  fatal(owner: string, message: string, error?: any, additionalFields?: Record<string, string>): void {
    this.log(QDCLogLevel.fatal, owner, message, error, additionalFields);
  }

  httpjs(owner: string, requestMethod: string, url: string, payload?: string,
    additionalFields?: Record<string, string>): void {
    this.logJsHttp(owner, QDCLogLevel.httpjs, requestMethod, url, payload, additionalFields);
  }

  private checkOwnerExists(owner: string): void {
    if (!this.registry.has(owner)) {
      const message = `Log owner '${owner}' wasn't registered.`;
      if (environment.production) {
        this.selfLogger.error(message);
      } else {
        this.selfLogger.fatal(message);
      }
    }
  }

  private log(level: number, owner: string, message: string, error?: any,
    additionalFields?: Record<string, string>): void {

    this.checkOwnerExists(owner);

    const log = this.buildJsonLog(owner, level, message, error?.stack, additionalFields);

    if ([QDCLogLevel.error, QDCLogLevel.fatal].includes(level)) {
      this.qdcLogService.postJsonLog(log).subscribe();
    }

    // Show Notifications
    // Use Info for positive comments
    if (message) {
      // Make sure message is a string and not an object
      if (typeof message !== 'string') {
        message = JSON.stringify(message);
      }

      const notification: QNotification = {
        id: message,
        autoHide: defaults.autoHideTime,
        dismissable: true,
        label: message,
      };

      switch (level) {
        case QDCLogLevel.debug:
        case QDCLogLevel.info:
          notification.color = 'informative';
          break;
        case QDCLogLevel.success:
          notification.color = 'positive';
          break;
        case QDCLogLevel.warn:
          notification.color = 'warning';
          break;
        case QDCLogLevel.error:
        case QDCLogLevel.fatal:
          notification.color = 'negative';
          break;
        default:
          break;
      }

      // Notify for all levels in non-prod environments.
      if (!([QDCLogLevel.undefined, QDCLogLevel.debug].includes(level))) {

        // Do not notify if message is already present in queue
        if (!this.qNotificationService.find(notification.id!)) {
          // If env is prod downgrade the error message and have the user try again
          // the real error message will get logged above
          if (environment.production && [QDCLogLevel.error, QDCLogLevel.fatal].includes(level)) {
            this.qNotificationService.notify({
              color: 'warning',
              autoHide: defaults.autoHideTimeMed,
              dismissable: true,
              label: 'An unexpected issue occurred, please try again. If the issue persists, please visit our Qualcomm® Device Cloud support site.',
            });
          } else {
            // For non prod env can show any message
            this.qNotificationService.notify(notification);
          }
        }
      }
    }
    // Developer utility, revisit when we want to enhance logging across the whole app, not just uncaught errors.
    // const consoleMessage = `QUALCOMM DEVICE CLOUD [${log.source}] ${owner} >> ${message}`;

    // // Log to console before anything else
    // const msg = `Invalid log level ${level} used by ${owner}. Original message: ${consoleMessage}`;
    // switch (level) {
    //   case QDCLogLevel.debug: console.debug(consoleMessage); break;
    //   case QDCLogLevel.info: console.info(consoleMessage); break;
    //   case QDCLogLevel.warn: console.warn(consoleMessage); break;
    //   case QDCLogLevel.error: console.error(consoleMessage); break;
    //   case QDCLogLevel.fatal: console.error(consoleMessage); break;
    //   default:
    //     if (environment.production) {
    //       this.selfLogger.error(msg);
    //       this.qdcLogService.postJsonLog(log).subscribe();
    //     } else {
    //       this.selfLogger.fatal(msg);
    //       this.qdcLogService.postJsonLog(log).subscribe();
    //     }
    //     break;
    // }
  }

  private logJsHttp(owner: string, level: number, requestMethod: string, url: string, payload?: any,
    additionalFields?: Record<string, string>): void {
    // Don't post to API if running UI locally
    if (APIUtility.isLocalHost()) {
      return;
    }

    this.checkOwnerExists(owner);
    if (level === QDCLogLevel.httpjs) {
      const log = this.buildJsHttpJsonLog(owner, level, requestMethod, url, payload, additionalFields);
      this.qdcLogService.postJsonLog(log).subscribe();
    }
  }
}
