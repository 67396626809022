<div class="flex justify-start items-start gap-[1rem] px-[8rem] py-[5rem]">
  <div class="flex flex-col gap-[20px] w-[30%] pt-[6rem] pr-[2rem]">
    <div class="flex justify-start items-center gap-[10px]">
      <img class="device-cloud-img" src="../../../../../assets/images/device-cloud-icon.webp" alt="QDC logo" />
      <span class="text font-medium pb-[4px]">Qualcomm® Device Cloud</span>
    </div>

    <span class="text largest whitespace-pre-wrap">{{errorDetails?.errorHeading}}</span>
    <span class="text mediumV2">{{errorDetails?.customErrorMsg}}</span>
    <span class="text mediumV2 whitespace-pre-wrap">{{errorDetails?.supportMsg}}</span>

    <span *ngIf="showError" class="text mediumV2 break-all">{{userService.getError()?.devErrorMsg}}</span>

  </div>
  <div class="w-[70%]">
    <img style="width: 1200px" src="../../../../../assets/images/interactive_unauthorized.webp" alt="Unauthorized interactive session" />
  </div>
</div>