// Additional types grabbed from other files not using qdc-table
export enum IColumnDefinitionTypes {
  // Main table body.component.ts types
  AgeInDays = 'ageInDays',
  Color = 'color',
  Custom = 'custom',
  DateOnly = 'dateOnly',
  EditableCell = 'editableCell',
  Empty = 'empty',
  Enum = 'enum',
  JobNumberLink = 'jobNumberLink',
  JobResult = 'jobResult',
  JobState = 'jobState',
  Link = 'link',
  MultiSelect = 'multiSelect',
  Number = 'number',
  NumberLink = 'numberLink',
  Percentage = 'percentage',
  SecondsToDays = 'secondsToDays',
  SecondsToHHMM = 'secondsToHHMM',
  ShortDate = 'shortDate',
  TestArtifact = 'testArtifact',
  TimeAgo = 'timeAgo',
  TitleCase = 'titleCase',
  TitleSubtitle = 'titleSubtitle',
  YesNo = 'yesNo',
  Log = 'log',

  // Extra from body.component.ts (resolveAsTooltip method)
  TimeLeft = 'timeLeft',

  // Extra file.component.ts types
  Actions = 'actions',
  CopyLink = 'copyLink',
  FilePath = 'filePath',
  FileSize = 'fileSize',

}
