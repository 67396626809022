import {
  AlarmPlus, AlertTriangle, ArrowLeft, ArrowRight, ArrowUpDown,
  Ban, Book,
  CalendarPlus, CalendarX, Check, CheckCircle, ChevronDown, ChevronRight,
  ChevronUp, Clock, Code, Cog, Copy, CreditCard,
  DatabaseBackup,
  DownloadCloud,
  Edit2,
  File, FileX, Flag, FolderEdit, FolderOpen,
  HelpCircle, Home,
  Image, Info,
  KeyRound,
  LifeBuoy, Loader, LogOut,
  MessageCircle, Minus, Monitor, MonitorSmartphone, Moon, MoreHorizontal,
  MoreVertical,
  Play, PlayCircle, Plus,
  RefreshCw, RotateCw,
  ScreenShareOff, Send, Server, Share2, Shield, ShieldAlert, ShoppingCart, Smartphone, StopCircle, SunMedium,
  TerminalSquare, Timer, TimerOff, TimerReset, Trash2,
  Undo2, Unlock, Unplug, UploadCloud, User, UserPlus, Users,
  Video,
  X, XCircle,
  Zap,
} from 'lucide-angular';
import { Injectable } from '@angular/core';

@Injectable()
export class Icons {
  AlarmPlus = AlarmPlus;
  AlertTriangle = AlertTriangle;
  ArrowLeft = ArrowLeft;
  ArrowRight = ArrowRight;
  ArrowUpDown = ArrowUpDown;
  Ban = Ban;
  Book = Book;
  CalendarPlus = CalendarPlus;
  CalendarX = CalendarX;
  Check = Check;
  CheckCircle = CheckCircle;
  ChevronDown = ChevronDown;
  ChevronRight = ChevronRight;
  ChevronUp = ChevronUp;
  Clock = Clock;
  Code = Code;
  Cog = Cog;
  Copy = Copy;
  CreditCard = CreditCard;
  DatabaseBackup = DatabaseBackup;
  DownloadCloud = DownloadCloud;
  Edit2 = Edit2;
  File = File;
  FileX = FileX;
  Flag = Flag;
  FolderEdit = FolderEdit;
  FolderOpen = FolderOpen;
  HelpCircle = HelpCircle;
  Home = Home;
  Image = Image;
  Info = Info;
  KeyRound = KeyRound;
  LifeBuoy = LifeBuoy;
  Loader = Loader;
  LogOut = LogOut;
  MessageCircle = MessageCircle;
  Minus = Minus;
  Monitor = Monitor;
  MonitorSmartphone = MonitorSmartphone;
  Moon = Moon;
  MoreHorizontal = MoreHorizontal;
  MoreVertical = MoreVertical;
  Play = Play;
  PlayCircle = PlayCircle;
  Plus = Plus;
  RefreshCw = RefreshCw;
  RotateCw = RotateCw;
  ScreenShareOff = ScreenShareOff;
  Send = Send;
  Server = Server;
  Share2 = Share2;
  Shield = Shield;
  ShieldAlert = ShieldAlert;
  ShoppingCart = ShoppingCart;
  Smartphone = Smartphone;
  StopCircle = StopCircle;
  SunMedium = SunMedium;
  TerminalSquare = TerminalSquare;
  Timer = Timer;
  TimerOff = TimerOff;
  TimerReset = TimerReset;
  Trash2 = Trash2;
  Undo2 = Undo2;
  Unlock = Unlock;
  Unplug = Unplug;
  UploadCloud = UploadCloud;
  User = User;
  Users = Users;
  UserPlus = UserPlus;
  Video = Video;
  X = X;
  XCircle = XCircle;
  Zap = Zap;
}
