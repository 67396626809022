import { Component, OnInit } from '@angular/core';
import { SessionService, UserService } from 'app/core/services';
import { ErrorMsgType } from 'app/core/enums';
import { HttpStatus } from 'app/core/classes/http-status';
import { IErrorMessage } from 'app/core/interfaces';
import { Icons } from 'app/core/constants/icons';
import { Strings } from 'app/core/constants/strings';
import { environment } from 'environments/environment';

@Component({
  selector: 'qdc-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {

  showError = ['dev', 'stg', 'demo', 'local', 'tst'].includes(environment.name);
  ErrorMsgTypeEnum = ErrorMsgType;
  errorDetails: IErrorMessage | undefined;

  constructor(
    private sessionService: SessionService,
    public strings: Strings,
    public icons: Icons,
    public userService: UserService) {
  }

  ngOnInit(): void {
    this.setErrorMessage();
  }

  logout(): void {
    this.sessionService.logoutRequested();
  }

  setErrorMessage(): void {
    this.errorDetails = this.userService.getError();
    if (!this.errorDetails) {
      this.errorDetails = {
        devErrorMsg: '',
        customErrorMsg: '',
        errorMsgType: ErrorMsgType.Undefined,
        errorStatus: '',
      }
    }

    // General login failure
    this.errorDetails.errorHeading = 'Device Cloud Login Unsuccessful';
    this.errorDetails.supportMsg = 'Please try again later. \nIf this problem still persists, please email qdc.support@qti.qualcomm.com to report this issue.';


    switch (this.errorDetails?.errorMsgType) {
      case ErrorMsgType.Auth:
        if (this.errorDetails?.errorStatus.startsWith('4')) {
          if (this.errorDetails.errorStatus === HttpStatus.locked.toString()) {
            this.errorDetails.errorHeading = 'Device Cloud Login Failure';
            this.errorDetails.customErrorMsg = 'Your Qualcomm® Device Cloud user account is still being provisioned.';
            this.errorDetails.supportMsg = 'Please try again in a few minutes. \nIf this problem still persists, please email qdc.support@qti.qualcomm.com to report this issue.';
          } else {
            // Treat all 400 errors as unauthorized, show user qdc invite only message
            this.errorDetails.errorHeading = 'Device Cloud Access Denied';
            this.errorDetails.customErrorMsg = 'There seems to be an issue with your Qualcomm® Account.';
            this.errorDetails.supportMsg =
              'Please email qdc.support@qti.qualcomm.com with your Qualcomm® account to verify access.';
          }
        } else if (this.errorDetails?.errorStatus.startsWith('5')) {
          this.errorDetails.errorHeading = 'Device Cloud Account Verification Unsuccessful';
          // Treat all 500 errors as unexpected, show user there was an issue while trying to log them in
          this.errorDetails.customErrorMsg = 'There was an issue verifying your account with Qualcomm® Device Cloud.';
        }
        break;
      case ErrorMsgType.Init:
        this.errorDetails.errorHeading = 'Device Cloud Initialization Unsuccessful';
        this.errorDetails.customErrorMsg = 'An error occurred during login initialization.';
        break;
      case ErrorMsgType.Terms:
        this.errorDetails.errorHeading = 'Device Cloud Terms and Conditions';
        this.errorDetails.customErrorMsg = this.userService.getError()?.customErrorMsg;
        break;
      case ErrorMsgType.OpenId:
        this.errorDetails.errorHeading = 'Device Cloud Authorization Unsuccessful';
        this.errorDetails.customErrorMsg = 'An issue occurred while logging you into Qualcomm® Device Cloud.';
        break;
      default:
        this.errorDetails.customErrorMsg = 'An issue occurred while logging you into Qualcomm® Device Cloud.';
    }
  }
}
