/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Observable, of as observableOf } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ICategorySearchService, IDictionary, IEditable, IFilter, ILogger, IPagedCollection } from 'app/core/interfaces';
import { catchError, map } from 'rxjs/operators';

import { APIUtility } from 'app/core/utils/api-utility';
import { HttpStatus } from 'app/core/classes/http-status';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { UserService } from './user.service';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';

const maxStackFrames = 5;

export class QDCApiService<T> implements ICategorySearchService<T> {

  readonly getRequest = 'GET';
  readonly patchRequest = 'PATCH';
  readonly postRequest = 'POST';
  readonly putRequest = 'PUT';
  readonly deleteRequest = 'DELETE';

  protected baseUrl: string = environment.qdcApi;
  protected readonly log: ILogger;
  suppressLogger = false;

  constructor(
    protected http: HttpClient,
    protected path: string,
    logService: LogService,
    protected router: Router,
    protected oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    this.log = logService.get('QDC UI - QDC API Service');
  }

  public errorHandler(mode: string, url: string, error: HttpErrorResponse): boolean {

    // Do not show error msg for new LRU case
    if (error.error?.errorMessage === 'Customer account does not exist for this user.') {
      return true;
    }
    // Do not show error message on minutes page for price api
    if (error.error?.errorMessage === 'There is an issue getting price for some item(s).') {
      return true;
    }

    let forbidden = false;

    if (!this.suppressLogger) {
      this.log.debug(error.message);
    }

    let errorMsg: string;
    let stackMsg: string | undefined = undefined;
    const status = error.status;
    const isServerError = HttpStatus.isServerError(status);

    // First check the status for unauthorized, having it here
    // makes sure that its handled at the top level and devs do not
    // have to check for unauthorized if they are using a custom error
    // handler
    if (status === HttpStatus.unauthorized) {
      // Send notification to subscribers that session has expired
      this.sessionService.sessionExpired();

      // Can hide the logger since we redirect the user to the session
      // expired page
      this.suppressLogger = true;
    }

    if(status === HttpStatus.forbidden) {
      forbidden = true;
    }

    if (status === HttpStatus.corsError) {
      this.log.warn('Looks like there was an issue processing your request, please refresh the page to try again.');
      return true;
    }

    if (!(errorMsg = this.customErrorHandler(mode, url, error))) {
      if (status) {
        const e = error.error;
        switch (status) {
          case HttpStatus.forbidden:
            errorMsg = `Qualcomm® Device Cloud refused unauthorized access to the data.`;
            break;
          case HttpStatus.failedDependency:
            errorMsg = `There was an issue verifying current user session.`;
            break;
          case HttpStatus.notFound:
            errorMsg = `The requested item was not found.`;
            break;
          case HttpStatus.methodNotAllowed:
            errorMsg = `The functionality that you are trying to access is currently disabled.`;
            break;
          case HttpStatus.badRequest:
            errorMsg = `Server rejected input as invalid.`;
            break;
          case HttpStatus.serviceUnavailable:
            errorMsg = `Qualcomm® Device Cloud is currently undergoing unplanned maintenance`;
            break;
          default:
            if (e) {
              let stack: string[];

              if (Array.isArray(e.errors) && e.errors.length) {
                errorMsg = e.errors[0].message;
                if (errorMsg?.length > defaults.maxLengthMedium) {
                  errorMsg = errorMsg.includes('. ') ? errorMsg.split('. ')[0] :
                    errorMsg.substring(0, defaults.maxLengthMedium);
                }
                stack = e.errors[0].stacktrace;
              } else {
                errorMsg = e.message.substring(0, defaults.maxLengthMedium);
                stack = e.stacktrace;
              }

              if (Array.isArray(stack)) {
                stackMsg = stack.slice(0, maxStackFrames).join('\n');
              }
            }

            if (!errorMsg) {
              errorMsg = isServerError ? errorMsg = `Qualcomm® Device Cloud failed the request. Please try again. (${status})` :
                error.statusText;
            }
            break;
        }
      } else {
        errorMsg = `Cannot access Qualcomm® Device Cloud. Please check your network and try again.`;
      }
    }

    if (this.suppressLogger) {
      console.error(errorMsg || error.toString());
      if (error.url) {
        console.debug(error.url);
      }
      if (stackMsg) {
        console.debug(stackMsg);
      }
    } else {
      if (!isServerError) {
        // Demote non-server errors to warning.
        this.log.warn(errorMsg || error.message);
      } else {
        this.log.error(errorMsg || error.message, stackMsg ? { stack: stackMsg } : undefined);
      }
    }

    if (forbidden) {
      this.router.navigateByUrl('/forbidden', { replaceUrl: true });
    }

    // Error is handled
    return true;
  }

  protected getErrorMessage(error: any): string {
    return APIUtility.getApiErrorMessage(error);
  }

  // Override for custom error message creation. Return truthy string if override, else return empty string
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    return '';
  }

  protected staticQuery(datafile: string): Observable<T[] | undefined> {
    const path = `data/${datafile}`;
    return this.http.get<T[]>(path).pipe(catchError((e) => {
      if (this.errorHandler(this.getRequest, path, e)) {
        return [undefined];
      }
      throw e;
    }));
  }

  // GET
  // Prefer to use getAnyQuery if possible.
  protected getAny(path?: string, isAbsolutePath?: boolean, query?: IDictionary): Observable<any> {

    path = path || '';

    let getPath: string = isAbsolutePath ? path : `${this.baseUrl}${this.path}${path}`;

    if (getPath.indexOf('?') >= 0) {
      this.log.fatal('Query parameters must be passed in separately.');
      return observableOf(undefined);
    }

    const queryString = this.buildQueryString(query, true);
    if (queryString) {
      if (getPath.endsWith('/')) {
        getPath = getPath.substring(0, getPath.length - 1);
      }
      getPath += `?${queryString}`;
    }

    return this.http.get(getPath)
      .pipe(
        catchError((e) => {
          if (this.errorHandler(this.getRequest, getPath, e)) {
            return [undefined];
          }
          throw e;
        }));
  }

  protected getAnyTextResponse(path?: string, isAbsolutePath?: boolean, query?: IDictionary): Observable<string> {

    path = path || '';

    let getPath: string = isAbsolutePath ? path : `${this.baseUrl}${this.path}/${path}`;

    if (getPath.indexOf('?') >= 0) {
      this.log.fatal('Query parameters must be passed in separately.');
      return observableOf('');
    }

    const queryString = this.buildQueryString(query, true);
    if (queryString) {
      getPath += `${(getPath.endsWith('/') ? '' : '/')}?${queryString}`;
    }

    return this.http.get(getPath,
      {
        responseType: 'text',
      })
      .pipe(
        catchError((e) => {
          if (this.errorHandler(this.getRequest, getPath, e)) {
            return [''];
          }
          throw e;
        }));
  }

  // Convenience method
  protected getAnyQuery(query: IDictionary, path?: string, isAbsolutePath?: boolean, isEncrypted?: boolean):
    Observable<any> {
    if (isEncrypted) {
      return this.getAnyTextResponse(path, isAbsolutePath, query);
    }
    return this.getAny(path, isAbsolutePath, query);
  }

  protected getAnyExpandedQuery(
    expand: string[],
    sort?: string[],
    paramsObject?: any,
    prefix?: string): Observable<any> {

    const query = this.buildQueryObject(
      undefined,
      undefined,
      expand,
      undefined,
      sort,
      undefined,
      undefined,
      paramsObject,
    );

    return this.getAnyQuery(query, prefix);
  }

  private buildQueryObject(
    page?: number,
    pageSize?: number,
    expand?: string[],
    fields?: string[],
    sort?: string[],
    search?: string,
    filters?: IFilter[],
    paramsObject?: any,
  ): any {

    let query: any = {
      $page: page,
      $pagesize: pageSize,
      $expand: expand && expand.length ? expand.join() : undefined,
      $field: fields && fields.length ? fields.join() : undefined,
      $sort: sort && sort.length ? sort.join() : undefined,
      $search: search ? encodeURIComponent(search) : search,
    };

    for (const unused in query) {
      if (query[unused] === undefined) {
        delete query[unused];
      }
    }

    if (filters) {
      filters.forEach((filter) => {

        const matchMode = filter.matchMode || 'ct'; // Default to contains filter
        const key = matchMode === 'eq' ? filter.key : `${filter.key}__${matchMode}`;

        // TODO: Validate key, i.e. check for invalid characters
        query[key] = (filter.value && filter.value !== null) ? encodeURIComponent(filter.value) : filter.value;
      });
    }

    if (paramsObject) {
      query = Object.assign(query, paramsObject);
    }

    return query;
  }

  getNonPaged(
    expand?: string[],
    fields?: string[],
    sort?: string[],
    search?: string,
    filters?: IFilter[],
    paramsObject?: any,
    prefix?: string,
  ): Observable<T[]> {
    const query = this.buildQueryObject(
      undefined,
      undefined,
      expand,
      fields,
      sort,
      search,
      filters,
      paramsObject,
    );

    return this.getAnyQuery(query, prefix);
  }

  getPaged(
    page = 1,
    pageSize: number = defaults.pageSize,
    expand?: string[],
    fields?: string[],
    sort?: string[],
    search?: string,
    filters?: IFilter[],
    paramsObject?: any,
    path?: string,
    isAbsolute?: boolean,
    isEncrypted?: boolean,
  ): Observable<IPagedCollection<T>> {
    const query = this.buildQueryObject(
      page || 1,
      pageSize || defaults.pageSize,
      expand,
      fields,
      sort,
      search,
      filters,
      paramsObject,
    );
    return this.getAnyQuery(query, path, isAbsolute, isEncrypted);
  }

  getPagedAsText(
    page = 1,
    pageSize: number = defaults.pageSize,
    expand?: string[],
    fields?: string[],
    sort?: string[],
    search?: string,
    filters?: IFilter[],
    paramsObject?: any,
    path?: string,
    isAbsolute?: boolean,
    isEncrypted?: boolean,
  ): any {
    const query = this.buildQueryObject(
      page || 1,
      pageSize || defaults.pageSize,
      expand,
      fields,
      sort,
      search,
      filters,
      paramsObject,
    );
    return this.getAnyQuery(query, path, isAbsolute, isEncrypted);
  }

  // Paged Query to return only the data field of the paged response
  getPagedData(
    page = 1,
    pageSize: number = defaults.pageSize,
    expand?: string[],
    fields?: string[],
    sort?: string[],
    search?: string,
    filters?: IFilter[],
    paramsObject?: any,
    path?: string,
    isAbsolute?: boolean,
  ): Observable<T[]> {
    return this.getPaged(
      page,
      pageSize,
      expand,
      fields,
      sort,
      search,
      filters,
      paramsObject,
      path,
      isAbsolute,
    ).pipe(
      map((response) => {
        if (response.errors && response.errors.length) {
          throw response.errors;
        }
        return response.data;
      }),
    );
  }

  getItem(id: number, expand: string[] | undefined = undefined, fields: string[] | undefined): Observable<T> {

    const query = this.buildQueryObject(
      undefined,
      undefined,
      expand,
      fields,
      undefined,
      undefined,
      undefined,
      undefined,
    );

    return this.getAnyQuery(query, id.toString());
  }

  getItemRevision(id: number, revision: number, expand: string[] |
    undefined = undefined, fields: string[] | undefined): Observable<T> {

    const query = this.buildQueryObject(
      undefined,
      undefined,
      expand,
      fields,
      undefined,
      undefined,
      undefined,
      undefined,
    );

    return this.getAnyQuery(query, `${id}/${revision}`);
  }

  // POST
  // Note: path argument is a relative path that overrides this.path.
  submit(submission: any, path?: string, headers?: HttpHeaders, stringifyPayload = true): Observable<any> {

    const postPath = `${this.baseUrl}${path === undefined ? this.path : path}`;

    if (stringifyPayload) {
      submission = JSON.stringify(submission);
    }

    return this.http.post(postPath, submission,
      {headers})
      .pipe(catchError((e) => {
        if (this.errorHandler(this.postRequest, postPath, e)) {
          return [undefined];
        }
        throw e;
      }));
  }

  search(
    text: string,
    page = 1,
    pageSize: number = defaults.pageSize,
    sort?: string[]): Observable<IPagedCollection<T>> {
    return this.getPaged(page, pageSize, undefined, undefined, sort, text);
  }

  searchAndReturnAsText(
    text: string,
    page = 1,
    pageSize: number = defaults.pageSize,
    sort?: string[],
    isEncrypted?: boolean): Observable<string> {
    return this.getPagedAsText(
      page,
      pageSize,
      undefined,
      undefined,
      sort,
      text,
      undefined,
      undefined,
      undefined,
      undefined,
      isEncrypted);
  }

  protected getUpdateUrl(id: number, path?: string): string {
    return `${this.baseUrl}${path === undefined ? this.path : path}/${id}`;
  }

  // PATCH
  // TODO: Remove.  Updated to use PUT HTTP verb until patch is supported.
  updateItemPartial(update: any, path?: string): Observable<any> {
    const url = this.getUpdateUrl(update.id, path);

    const payload = JSON.stringify(update);
    return this.http.put(url, payload)
      .pipe(catchError((e) => {
        if (this.errorHandler(this.patchRequest, url, e)) {
          return [undefined];
        }
        throw e;
      }));
  }

  // DELETE
  delete(path: string): Observable<any> {

    const deletePath = `${this.baseUrl}${path === undefined ? this.path : path}`;

    return this.http.delete(deletePath, { observe: 'response' })
      .pipe(
        catchError((e) => {
          if (this.errorHandler(this.deleteRequest, deletePath, e)) {
            throw undefined;
          }
          throw e;
        }));
  }

  deleteItem(id: number | string): Observable<any> {
    return this.delete(`${this.path}/${id}`);
  }

  // PUT
  updateItem(update: any, path?: string, expand?: string[]): Observable<any> {

    const expandOb: any = Object({ $expand: expand && expand.length ? expand.join() : undefined });
    const url = `${this.getUpdateUrl(update.id, path)}?${this.buildQueryString(expandOb)}`;
    const payload = JSON.stringify(update);

    return this.http.put(url, payload)
      .pipe(catchError((e) => {
        if (this.errorHandler(this.putRequest, url, e)) {
          return [undefined];
        }
        throw e;
      }));
  }

  // PUT
  updateAny(update: any, path?: string, expand?: string[]): Observable<any> {

    const expandOb: any = Object({ $expand: expand && expand.length ? expand.join() : undefined });
    const url = `${this.baseUrl}${path === undefined ? this.path : path}?${this.buildQueryString(expandOb)}`;

    const payload = JSON.stringify(update);
    return this.http.put(url, payload)
      .pipe(catchError((e) => {
        if (this.errorHandler(this.putRequest, url, e)) {
          return [undefined];
        }
        throw e;
      }));
  }

  // PATCH
  patchItem(id: number, update: any, path?: string, expand?: string[]): Observable<any> {

    const expandOb: any = Object({ $expand: expand && expand.length ? expand.join() : undefined });
    const url = `${this.getUpdateUrl(id, path)}?${this.buildQueryString(expandOb)}`;

    const payload = JSON.stringify(update);
    return this.http.patch(url, payload)
      .pipe(catchError((e) => {
        if (this.errorHandler(this.patchRequest, url, e)) {
          return [undefined];
        }
        throw e;
      }));
  }

  private buildQueryString(query?: IDictionary, get?: boolean): string {
    let queryString = '';
    let first = true;

    if (get) {
      query = query || {};
    }

    if (query) {
      for (const key of Object.keys(query)) {
        const value = query[key];
        if (value !== undefined) {
          queryString += `${first ? '' : '&'}${key}=${value}`;
          first = false;
        }
      }
    }

    return queryString;
  }

  // **** IMPORTANT **** any new headers added here will need to also be
  // Added in the "Access-Control-Allow-Headers" section for each
  // Web config file
  protected buildHeaders(get?: boolean): HttpHeaders {
    let headers = new HttpHeaders();
    const user = this.userService.getUsername();

    if (!get) {
      headers = headers.set('Authorization', `Basic ${user}`);
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  archive(item: IEditable): Observable<IEditable> {
    if (!item.isDeleted) {
      item.isDeleted = true;

      const ob = this.updateItem(item);
      return ob;
    }

    return observableOf(item);
  }

  restore(item: IEditable): Observable<IEditable> {
    if (item.isDeleted) {
      item.isDeleted = false;
      const ob = this.updateItem(item);
      return ob;
    }

    return observableOf(item);
  }

  getFileContents(path: string): Observable<string> {
    return this.http.get(path,
      {
        responseType: 'text',
      },
    );
  }

  downloadFileContent(path: string): Observable<HttpResponse<Blob>> {
    return this.http.get(path,
      {
        observe: 'response',
        responseType: 'blob',
      },
    );
  }

  logHttpCall(requestMethod: string, url: string, tracingId: string | null, payload?: string): void {
    const additionalFields: Record<string, string> = {};
    if (tracingId != null) {
      additionalFields['X-QCOM-TracingID'] = tracingId;
    }
    if (payload) {
      this.log.httpjs(requestMethod, url, payload, additionalFields);
    } else {
      this.log.httpjs(requestMethod, url, undefined, additionalFields);
    }
  }

}
