import { ICounter, ISort } from 'app/core/interfaces';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class defaults {

  static readonly loginDelayLong: number = 500;  // Milliseconds
  static readonly loginDelayShort: number = 0;  // Milliseconds
  static readonly searchHintDelay: number = 1000; // Milliseconds (aka Debounce)
  static readonly filterStringSearchDelay: number = 1000; // Milliseconds (aka Debounce)
  static readonly formInputVerifyDelay: number = 500; // Milliseconds (aka Debounce)

  static readonly disableTooltips: boolean = false;
  static readonly disableConfirmNavigate: boolean = false;
  static readonly tooltipShowDelay: number = 250; // Milliseconds
  static readonly snackBarTimeout = 5000; // Milliseconds

  // General
  static readonly textEncoding: string = 'UTF-8';

  // Dates
  static readonly dateFormat: string = 'MM/dd/yy h:mm:ss a';
  static readonly dateFormatWithTimeZone: string = 'MM/dd/yy h:mm:ss a z';
  static readonly dateFormatUSTimeZone: string = 'America/los_angeles';
  static readonly dateFormatLong: string = 'MMMM d, y';
  static readonly dateFormatUS: string = 'en-US';

  // Table
  static readonly columnHidden: boolean = false;
  static readonly maxPageSize: number = 300;
  static readonly pageSize: number = 20;
  static readonly minPageSize: number = 10;
  static readonly pageSize5: number = 5;
  static readonly newJobMinutes: number = 5;

  static readonly columnChooserDialogHeight = 500; // Pixels

  // These should match the values in Database\Models\Constants.cs
  static readonly maxLengthShort = 32;
  static readonly maxLength64 = 64;
  static readonly maxLength128 = 128;
  static readonly maxLengthMedium = 256;
  static readonly maxLength512 = 512;
  static readonly maxLengthLong = 2048;
  static readonly maxLengthLong4K = 4096;
  static readonly maxLengthLong8K = 8000;

  // Job Abort Limit
  static readonly jobAbortLimit: number = 50;

  // Logs
  static readonly logDelimiter = ',';
  static readonly defaultLogSort: ISort = { field: '', order: 0, sort: '' };
  static readonly maxViewFileSize = 0x3000000; // At ~50MB
  static readonly logPollIntervalDuration = 1000 * 60;  // 1000 ms * 60 s = 1 min
  static readonly videoStreamingUrlDuration = 2;  // TTL in hours

  // Log Tree
  static readonly treePageSize: number = 100;

  // Counter
  static readonly defaultTime: ICounter = { min: 0, sec: 0 };
  static readonly pendingPollingTimeMS: number = 15000; // 15 seconds
  static readonly runningPollingTimeMS: number = 10000; // 10 seconds

  // Job Submission
  static readonly autoJobMaxSelectedDevices: number = 1;
  static readonly interactiveSessionMaxSelectedDevices: number = 1;
  static readonly minMonkeyEventCount = 1;
  static readonly maxMonkeyEventCount = 1000000;
  static readonly minTestMinsPerDevice = 10;
  static readonly defaultTestMins = 120;
  static readonly maxTestMinsPerDevice = 720;
  static readonly purchaseMinutesThreshold = 1;

  // Job Report
  static readonly preRunningStates: string[] = ['Submitted', 'Dispatched', 'Setup'];
  static readonly terminalStates: string[] = ['Canceled', 'Aborted', 'Completed'];

  // Address
  static readonly shipTo = 'SHIP_TO';
  static readonly billTo = 'BILL_TO';

  // Order Total Limit
  static readonly maxOrderTotal = 30000;

  // Notifications autoHide duration in milliseconds
  static readonly autoHideTime = 15000;
  static readonly autoHideTimeMed = 20000;

  // Top Bar
  static readonly minUsernameWidth = 1200;
  static readonly minTitleWidth = 900;

  // Q Alert Banner
  static readonly announcementQueueSize = 5;
  static readonly announcementHideSpeed = 500;

  // Device Snapshot
  static readonly minTimeLeftInSession = 5;

  // Files without extension
  static readonly tombstoneFile = 'tombstone_';
  static readonly anrFile = 'anr_';
  static readonly capabilitiesFile = 'capabilities_';

  // UM ApiKeys endpoint
  static readonly apiKeys = 'apiKeys';
}
