import { DialogService, SessionService, UserService } from 'app/core/services';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ILogger, IPagedCollection } from '../interfaces';
import { IAnnouncement } from '../interfaces/iannouncement';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService extends QDCApiService<IAnnouncement> {

  protected readonly log: ILogger;

  constructor(http: HttpClient, logService: LogService, router: Router, oauthService: OAuthService,
    protected sessionService: SessionService, protected userService: UserService,
    protected dialogService: DialogService) {
    super(http, environment.announcementsApi, logService, router, oauthService, sessionService, userService);
    this.log = logService.get('Announcement Service');
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      return error.error;
    }
    return '';
  }

  getActiveAnnouncements(): Observable<IPagedCollection<IAnnouncement>> {
    return super.getAny('/active', undefined)
  }
}
