// Angular Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// QDC Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { BusyableModule } from './features/busyable/busyable.module';
import { SessionExpiredModule } from './features/session-expired/session-expired.module';
import { TermAcceptanceModule } from './features/term-acceptance/term-acceptance.module';
import { UnauthorizedModule } from './features/unauthorized/unauthorized.module';

// QDC Components
import { AppComponent } from './app.component';

// Core classes
import { LandingModule } from './features/landing/landing.module';
import { httpInterceptorProviders } from './core/interceptors';

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    httpInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,

    AppRoutingModule,
    CoreModule.forRoot(),

    // Special case components that needed to be separated into their own modules
    // due to circular dependencies
    BusyableModule,

    // Import QDC modules whose components are referenced in app.component.html
    TermAcceptanceModule,
    SessionExpiredModule,
    UnauthorizedModule,
    LandingModule,
  ],
})
export class AppModule { }
