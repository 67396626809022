/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IListEntry, IListEntryEnvelope, IOption } from 'app/core/interfaces';

export class CommonUtility {

  //#region Regex Methods
  static filterInt(value: any): number {
    if (/^(-|\+)?([0-9]+|Infinity)$/.test(value)) {
      return Number(value);
    }
    return NaN;
  }

  static removeNonAlphaNumeric(value: string): string {
    return value.replace(/[^A-Za-z0-9]/g, '');
  }
  //#endregion

  //#region Form methods

  // Resolves a value possibly in a nested field, where the path is separated by dots, e.g. level0.level1...levelN.field
  static resolveGet(obj: any, fieldPath: string): any {

    const fields: string[] = fieldPath.split('.');

    let result: any = obj;
    for (let i = 0; result && i < fields.length; i++) {
      result = result[fields[i]];
    }

    return result === null ? undefined : result;
  }

  // Resolves a possibly nested field, where the path is separated by dots, e.g. level0.level1...levelN.field and sets
  // A value
  static resolveSet(obj: any, fieldPath: string, newValue: any): void {

    const fields: string[] = fieldPath.split('.');

    let result: any = obj;
    let i: number;
    for (i = 0; result && i < fields.length - 1; i++) {
      result = result[fields[i]];
    }

    result[fields[i]] = newValue;
  }

  //#endregion

  //#region Array Methods

  // Eventually, all option types should converge, making this method unnecessary.
  static envelope(options: ReadonlyArray<IOption> | ReadonlyArray<IListEntry>): IListEntryEnvelope<any>[] | undefined {
    if (options) {
      const envelopes = [];
      for (const i of options) {
        envelopes.push({
          label: i.label,
          value: i.value,
          disabled: i?.disabled,
        });
      }
      return envelopes;
    }
    return undefined;
  }

  // Sorts a list by the passed in field
  static sortList(item: any[], field: any, sortDesc = false): any[] {
    return item.sort((a, b) => {
      const fieldA = a[field];
      const fieldB = b[field];
      if (sortDesc) {
        if (fieldB < fieldA) {
          return -1;
        }
        if (fieldB > fieldA) {
          return 1;
        }
      } else {
        if (fieldA < fieldB) {
          return -1;
        }
        if (fieldA > fieldB) {
          return 1;
        }
      }

      // Fields must be equal
      return 0;
    });
  }

  //#endregion

  //#region Misc Methods

  static stringIsNullOrEmpty(str: string | undefined | null): boolean {
    return str === undefined || str === null || str.trim() === '';
  }

  // Non-static method so it can be accessed in HTML from a class instance
  public hasEllipsis(el: HTMLElement, width?: number): boolean {
    if (width) {
      return width > el?.offsetWidth;
    } else {
      return el?.scrollWidth > el?.offsetWidth;
    }
  }

  //#endregion

  // TODO: remove with purchase minutes component
  // If fieldsToCheck is specified only those fields are considered
  static areObjectsEqual(objA: any, objB: any, fieldsToSkip?: string[], fieldsToCheck?: string[]): boolean {
    const compareFields: string[] = fieldsToCheck || Object.keys(objA).filter(key => !fieldsToSkip?.includes(key));
    for (const field of compareFields) {
      const valueA = objA[field];
      const valueB = objB[field];
      // Handle null,undefined and '' values
      if (!valueA && !valueB) {
        continue;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (valueA.toLowerCase() !== valueB.toLowerCase()) {
          return false;
        }
      } else if (valueA !== valueB) {
        return false;
      }
    }
    return true;
  }

  // TODO: remove with purchase minutes component
  static removeWhiteSpacesFromObjectValues(obj: any): any {
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      }
    });
    return obj;
  }

  // TODO: remove with purchase minutes component
  static delayFuncAsync(delay: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => { resolve(); }, delay);
    });
  }
}
