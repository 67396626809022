// Angular Modules
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// Interceptors

// Authentication Modules
import { AuthConfig, OAuthModule } from 'angular-oauth2-oidc';

import {
  FileMetricsLogService,
  GlobalErrorHandlerService,
  LogService,
  StringService,
} from './services';

// Constants
import { Icons } from './constants/icons';
import { Strings } from './constants/strings';

// Guards
// N/A

// QUI Modules
import {
  DARK_MODE_OPTIONS,
  QButtonModule,
  QDividerModule,
  QHeaderModule,
  QIconButtonModule,
  QOverlayPanelModule,
  QTooltipModule,
  QuiRootModule,
} from '@qui/angular';

// PrimeNG Modules
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';

// CoreModule Components
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';

import { environment } from 'environments/environment';

// Angular Material Modules
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppFooterComponent,
    TopBarComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,

    // Angular Material Modules
    MatDialogModule,

    // QUI Modules
    QuiRootModule,
    QButtonModule,
    QDividerModule,
    QHeaderModule,
    QIconButtonModule,
    QOverlayPanelModule,
    QTooltipModule,

    // PrimeNG modules
    CardModule,
    DialogModule,
    SidebarModule,
    TabMenuModule,

    // Auth modules
    OAuthModule.forRoot(),
  ],
  exports: [
    // Angular modules
    CommonModule,

    // Angular Material Modules
    MatDialogModule,

    // QUI Modules
    QuiRootModule,

    // QDC Components (Exported for App Module)
    AppFooterComponent,
    TopBarComponent,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  // Guard against re-import in feature modules
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: environment.oauthConfig },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: Icons, useValue: Object.freeze(new Icons()) },
        { provide: Strings, useValue: Object.freeze(new Strings()) },
        {
          provide: DARK_MODE_OPTIONS, useValue: {
            defaultModeOverride: 'dark',
          },
        },
        FileMetricsLogService,
        LogService,
        MessageService,
        StringService,
      ],
    };
  }
}
