import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import featureFlags from 'assets/files/feature-flag.json';

@Injectable({
  providedIn: 'root',
})
export class NewFeatureService {

  constructor() {}

  checkFeatureFlag(featureName: string): boolean {
    if (featureFlags.length) {
      const index = featureFlags.findIndex(element => element.name === featureName);
      if (index !== -1) {
        const environments: string[] = featureFlags[index].supportedEnvs.split(',').map(x => x.trim());
        if (environments.includes(environment.name))
          return featureFlags[index].enable;
      }
    }
    return false;
  }
}
