import {AfterViewInit, Component, HostListener, Input, ViewChild} from '@angular/core';
import {
  AnnouncementService,
  AuthService,
  JobService,
  NewFeatureService,
  QuickTourService,
  UserService,
} from 'app/core/services';
import {DarkModeService, QAlertService} from '@qui/angular';
import {IAnnouncement} from 'app/core/interfaces/iannouncement';
import {Icons} from 'app/core/constants/icons';
import {MenuItem} from 'primeng/api';
import {QAlertColor} from '@qui/base';
import {QuickTourTypes} from '../../enums';
import {Router} from '@angular/router';
import {SessionService} from 'app/core/services/session.service';
import {Strings} from 'app/core/constants/strings';
import {Subscription} from 'rxjs';
import {defaults} from 'app/core/constants/defaults';

@Component({
  selector: 'qdc-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements AfterViewInit {

  @Input() showLoggedInMenuItems: boolean = false;
  @Input() showLogoutButton: boolean = false;

  // User
  username?: string;
  isLruUser: boolean | undefined = false;

  userOptions = [
    { label: 'Settings', value: this.icons.Cog },
    { label: 'Log Out', value: this.icons.LogOut },
  ];

  helpOptions = [
    { label: 'Support' },
    { label: 'Contact Us' },
  ];

  // Quick Tours
  @ViewChild('retakeTourElement') retakeTourElement?: any;

  readonly mobileTourNotRunningOrSelected: string = 'Must navigate to a mobile interactive session that is running.';
  readonly mobileTourNotRunning: string = 'Mobile interactive session must be running.';
  readonly computeTourNotRunningOrSelected: string = 'Must navigate to a compute interactive session that is running.';
  readonly computeTourNotRunning: string = 'Compute interactive session must be running.';

  mobileSessionTour = { label: 'Interactive Session - Mobile Devices', disabled: true,
    tooltip: this.mobileTourNotRunningOrSelected };
  computeSessionTour = { label: 'Interactive Session - Compute Devices', disabled: true,
    tooltip: this.computeTourNotRunningOrSelected };

  tourOptions = [
    this.mobileSessionTour,
    this.computeSessionTour,
  ];

  // Dark Mode
  darkMode: boolean = false;

  // Responsive Layout
  hideUsername: boolean = false;
  hideTitle: boolean = false;

  // Route
  previousUrl: string = '';
  loggedInRouteItems: MenuItem[] = [
    {
      label: 'Home',
      routerLink: '/home',
    },
    {
      label: 'Automated Jobs',
      routerLink: '/jobs/automated',
    },
    {
      label: 'Interactive Sessions',
      routerLink: '/jobs/interactive',
    },
    {
      label: 'Devices',
      routerLink: '/devices',
    },
  ];
  loggedOutRouteItems: MenuItem[] = [
    {
      label: 'Devices',
      routerLink: '/devicecatalog',
    },
  ];
  activeNavIndex: number | undefined;
  routeChangeSubscription: Subscription | undefined = undefined;

  // Listener for window width change
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hideUsername = window.innerWidth < defaults.minUsernameWidth;
    this.hideTitle = window.innerWidth < defaults.minTitleWidth;
  }

  constructor(
    private alertService: QAlertService,
    private darkModeService: DarkModeService,
    public icons: Icons,
    private announcementService: AnnouncementService,
    private jobService: JobService,
    protected quickTourService: QuickTourService,
    private router: Router,
    public sessionService: SessionService,
    public strings: Strings,
    public userService: UserService,
    public newFeatureService: NewFeatureService,
    private authService: AuthService) {

    this.authService.isLoginComplete$.subscribe((_) => {
      // Make sure user is both logged in and their session is valid
      // there can be a case where user is logged in but their session token
      // is expired making the session invalid
      if (this.userService.isLoggedIn() && this.userService.isSessionValid()) {
        this.username = this.userService.getUsername();

        // Initialize Theme, Announcements, and Top Bar layout
        this.getDarkModeValue();
        this.getActiveAnnouncements();
        this.onResize();

        this.updateMenuItemSelection(this.router.url);
        this.routeChangeSubscription = this.router.events.subscribe(
          () => {
            this.updateMenuItemSelection(this.router.url);
          },
        );
        this.isLruUser = this.userService.isLru();
        if (!this.isLruUser) {
          this.loggedInRouteItems.push(
            {
              label: 'Images',
              routerLink: '/images/builds',
            },
          );
        }
      }
    });

    this.jobService.jobQuickTourSetting$.subscribe((data: {enabled: boolean, platform: string}) => {
      this.updateTourOptions(data.enabled, data.platform)
    });
  }

  ngAfterViewInit(): void {
    this.quickTourService.setRetakeTourElement(this.retakeTourElement?.el.nativeElement)
  }

  async updateTourOptions(enabled: boolean, platform: string): Promise<void> {

    const currentUrl = this.router.url;

    // Check URL for interactive
    if (!currentUrl.includes('interactive')) {
      this.mobileSessionTour!.disabled = this.computeSessionTour!.disabled = true;
      return;
    }

    // Check URL for session id (would omit a route like interactive/add)
    const sessionId: number = Number(currentUrl.slice(currentUrl.lastIndexOf('/') + 1));
    if (sessionId === undefined || Number.isNaN(sessionId)) {
      this.mobileSessionTour!.tooltip = this.mobileTourNotRunningOrSelected;
      this.computeSessionTour!.tooltip = this.computeTourNotRunningOrSelected;
      this.mobileSessionTour!.disabled = this.computeSessionTour!.disabled = true;
      return;
    }

    if (platform === 'Mobile') {
      this.mobileSessionTour!.disabled = !enabled;
      this.mobileSessionTour!.tooltip = enabled ? 'Start the Mobile Quick Tour' : this.mobileTourNotRunning;
      this.computeSessionTour!.disabled = true;
      this.computeSessionTour!.tooltip = this.computeTourNotRunningOrSelected;
    } else if (platform === 'Compute') {
      this.computeSessionTour!.disabled = !enabled;
      this.computeSessionTour!.tooltip = enabled ? 'Start the Compute Quick Tour' : this.computeTourNotRunning;
      this.mobileSessionTour!.disabled = true;
      this.mobileSessionTour!.tooltip = this.mobileTourNotRunningOrSelected;
    }
  }

  getActiveAnnouncements(): void {
    this.alertService.config.behaviour.stacking = defaults.announcementQueueSize;
    this.alertService.config.animations.hide.speed = defaults.announcementHideSpeed;

    this.announcementService.getActiveAnnouncements().subscribe(
      (response: { data: IAnnouncement[]; }) => {
        if (response?.data) {
          response.data.forEach((announcement: IAnnouncement) => {
            this.alertService.showAlert({
              color: <QAlertColor>(announcement.type.toString().toLowerCase()),
              description: announcement.description,
              label: announcement.label,
            });
          });
        }
      },
    );
  }

  // Only update active route when url is new
  updateMenuItemSelection(currentUrl: string): void {
    if (currentUrl !== this.previousUrl) {
      // Update highlighted MenuItem based on current route
      if (currentUrl.includes('home')) {
        this.activeNavIndex = 0;
      } else if (currentUrl.includes('automated')) {
        this.activeNavIndex = 1;
      } else if (currentUrl.includes('interactive')) {
        this.activeNavIndex = 2;
      } else if (currentUrl.includes('devices')) {
        this.activeNavIndex = 3;
      } else if (currentUrl.includes('landing')) {
        this.activeNavIndex = undefined;
      } else if (currentUrl.includes('images')) {
        this.activeNavIndex = 4;
      } else {
        this.activeNavIndex = undefined;
      }
      this.previousUrl = currentUrl;
    }
  }

  routeLanding(): void {
    this.router.navigate(['landing']);
  }

  route(path: string, index: number):void {
    this.router.navigate([path]);
    this.activeNavIndex = index;
  }

  onUserOptionSelect(option: any): void {
    switch (option) {
      case this.icons.Cog:
        this.router.navigate(['usersettings']);
        break;
      case this.icons.LogOut:
        this.sessionService.logoutRequested();
        break;
    }
  }

  onHelpOptionSelect(option: string): void {
    switch (option) {
      case 'Support':
        window.open(this.strings.docs.docsHome);
        break;
      case 'Contact Us':
        window.open(this.strings.docs.contactUs);
        break;
      case 'Interactive Session - Mobile Devices':
        this.quickTourService.setShowTour(true, QuickTourTypes.MobileInteractive);
        break;
      case 'Interactive Session - Compute Devices':
        this.quickTourService.setShowTour(true, QuickTourTypes.ComputeInteractive);
        break;
    }
  }

  toggleDarkMode() {
    this.darkModeService.toggle();
    this.getDarkModeValue();
  }

  getDarkModeValue(): void {
    this.darkMode = this.darkModeService.getMode() === 'dark';
  }

  loginAndRouteToHomePage(): void {
    sessionStorage.setItem('redirectFromLandingPage', 'home');
    this.sessionService.loginRequested();
  }
}
