import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { QDCApiService } from 'app/core/services/qdc-api.service';  // IMPORT SEPARATELY

import { IAddressUpdateRequest, ICustomerAccount, ILogger, IOrderResponse } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends QDCApiService<IOrderResponse> {

  protected readonly log: ILogger;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    seesionService: SessionService,
    userService: UserService) {
    super(http, environment.accountApi, logService, router, oauthService, seesionService, userService);
    this.log = logService.get('Billing Service');
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      // Throw the error if it is a POST request.
      // Error handling is done as part of subscribe error callback.
      if (mode === this.postRequest) {
        throw error
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  getAccounts(): Observable<ICustomerAccount[]> {
    return super.getAny();
  }

  addAccount(accountDetails: ICustomerAccount): Observable<any> {
    return super.submit(accountDetails);
  }

  confirmSalesOrder(orderId: number): Observable<IOrderResponse> {
    return super.getAny(`${this.baseUrl}${environment.salesOrderApi}/qdcorder/${orderId}/confirm`, true);
  }

  updateAddress(addressDetails: IAddressUpdateRequest): Observable<ICustomerAccount> {
    return super.submit(addressDetails,`${environment.accountApi}/updateaccountaddress`);
  }

}
