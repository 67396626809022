// Angular Modules
import { NgModule } from '@angular/core';

// Routing Modules
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { RouterModule, Routes } from '@angular/router';


// Services
// N/A

// Guards
import { AuthGuard } from './core/guards/auth.guard';
import { LruRestrictedFeatureGuard } from './core/guards/lru-restricted-feature.guard';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule),
    data: { preload: true },
  },
  {
    path: '',
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule),
    data: { preload: true },
  },
  {
    path: 'terms',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/term-acceptance/term-acceptance.module').then(m => m.TermAcceptanceModule),
  },
  {
    path: 'home',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'devicecatalog',
    loadChildren: () => import('./features/devices/devices.module').then(m => m.DevicesModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'sessionexpired',
    loadChildren: () => import ('./features/session-expired/session-expired.module').then(m => m.SessionExpiredModule),
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./features/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./features/forbidden/forbidden.module').then(m => m.ForbiddenModule),
  },
  {
    path: 'jobs',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/jobs/jobs.module').then(m => m.JobsModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'reports',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/job-report/job-report.module').then(m => m.JobReportModule),
  },
  // ToDo: Add when adding minutes back
  // {
  //   path: 'minutes',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./features/minutes/minutes.module').then(m => m.MinutesModule),
  // },
  {
    path: 'devices',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/devices/devices.module').then(m => m.DevicesModule),
  },
  {
    path: 'images',
    canActivate:[AuthGuard, LruRestrictedFeatureGuard],
    loadChildren: () => import('./features/images/images.module').then(m => m.ImagesModule),
  },
  {
    path: 'checkout',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'redeem',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/coupon-redeem/coupon-redeem.module').then(m => m.CouponRedeemModule),
  },
  {
    path: 'usersettings',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/user-settings/user-settings.module').then(m => m.UserSettingsModule),
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'top',
        preloadingStrategy: QuicklinkStrategy,
      },
    ),
  ],
  exports: [QuicklinkModule, RouterModule],
})
export class AppRoutingModule { }
