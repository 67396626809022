/* eslint-disable camelcase */
// disabled rule to properly fill out adobe visitor object
import {IAdobeDataLayerVisitor, IErrorMessage, IOpenIdTokenInfo, IUserProfile} from 'app/core/interfaces';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  _user?: IUserProfile = undefined;
  _adobeVisitor?: IAdobeDataLayerVisitor = undefined;
  _isAuthorized: boolean | Observable<boolean> = false;
  _isDeveloper: boolean = false;
  _loggedIn: boolean = false;
  _isAuthCallInProgress: boolean = false;
  _tracingIdMap: Map<string, string> = new Map();
  _sessionValid: boolean = false;
  _loginFailed: boolean = false;
  _openIdTokenInfo?: IOpenIdTokenInfo = undefined;

  // Temporary logging for Oauth login errors from apigee
  _error?: IErrorMessage;

  getUsername(): string | undefined {
    return this._user?.username;
  }

  getFullName(): string | undefined {
    return this._user?.fullName;
  }

  getFirstName(): string | undefined {
    return this._user?.firstName;
  }

  getLastName(): string | undefined {
    return this._user?.lastName;
  }

  setUserProfile(user: IUserProfile): void {
    this._user = user;
  }

  setAdobeUserInfo(token: string): void {
    try {
      // Try to decode the um token and convert it to an object
      const tokenObj = JSON.parse(atob(token.split('.')[1]));
      let domain: string = '';
      if (this.isInternal()) {
        domain = 'qualcomm.com'
      } else if (tokenObj.un.split('@').length >= 1) {
        domain = tokenObj.un.split('@')[1];
      }
      this._adobeVisitor = {
        qcguid: tokenObj.qcguid ?? '',
        org_id: tokenObj.pnum ?? '',
        org_name: tokenObj.tauth?.tpname ?? '',
        user_domain: domain,
      }
    } catch {
      this._adobeVisitor = undefined;
    }
  }

  getAdobeUserInfo(): IAdobeDataLayerVisitor | undefined {
    return this._adobeVisitor;
  }

  getUserProfile(): IUserProfile | undefined {
    return this._user;
  }

  getNucleusToken(): string | undefined {
    return this._user?.token;
  }

  getNucleusTokenExpireTime(): number | undefined {
    return this._user?.tokenExpireTime;
  }

  isInternal(): boolean | undefined {
    return this._user?.isInternal;
  }

  isLru(): boolean | undefined {
    return this._user?.isLru;
  }

  isAuthorized(): boolean | Observable<boolean> {
    return this._isAuthorized;
  }

  setIsAuthorized(authorized: boolean | Observable<boolean>): void {
    this._isAuthorized = authorized;
  }

  // This method is used so we do not call the authorization method
  // multiple times (it is what gives us a valid nucleus token to pass to api)
  // in the auth-interceptor
  setIsAuthorizationInProgress(authorized: boolean): void {
    this._isAuthCallInProgress = authorized;
  }

  getIsAuthorizationInProgress(): boolean {
    return this._isAuthCallInProgress;
  }

  setIsDeveloper(isDeveloper: boolean): void {
    this._isDeveloper = isDeveloper;
  }

  isDeveloper(): boolean {
    return this._isDeveloper;
  }

  logoutUser(): void {
    this._user = undefined;
    this._isAuthorized = false;
  }

  setError(error: IErrorMessage): void {
    this._error = error;
  }

  getError(): IErrorMessage | undefined {
    return this._error;
  }

  setLoginFailed(value: boolean): void {
    this._loginFailed = value;
  }

  getLoginFailed(): boolean {
    return this._loginFailed;
  }

  setIsLoggedIn(loggedIn: boolean): void {
    this._loggedIn = loggedIn;
  }

  isLoggedIn(): boolean {
    return this._loggedIn;
  }

  setIsSessionValid(sessionValid: boolean): void {
    this._sessionValid = sessionValid;
  }

  isSessionValid(): boolean {
    return this._sessionValid;
  }

  setWorkflowTracingId(workflow: string, tracingId: string | undefined): void {
    if (this._tracingIdMap.get(workflow) && !tracingId) {
      // Remove common tracing ID after workflow is completed
      this._tracingIdMap.delete(workflow);
    } else {
      this._tracingIdMap.set(workflow, tracingId!);
    }
  }

  getWorkflowTracingId(workflow: string): string | undefined {
    return this._tracingIdMap.get(workflow);
  }

  isSuperUser(): boolean | undefined {
    return this._user?.systemRole === 'SUPERUSER';
  }

  setOpenIdAccessTokenInfo(token: string): void {
    try {
      // Try to decode the OpenID token and convert it to an object
      const tokenObj = JSON.parse(atob(token.split('.')[1]));
      this._openIdTokenInfo = {
        user_name: tokenObj.sub ?? '',
        expires_in: tokenObj.exp ? new Date(tokenObj.exp*1000).toString() : '',
        issued_at: tokenObj.iat ? new Date(tokenObj.iat*1000).toString() : '',
      }
    } catch {
      this._openIdTokenInfo = undefined;
    }
  }

  getOpenIdAccessTokenInfo(): IOpenIdTokenInfo | undefined {
    return this._openIdTokenInfo;
  }
}
