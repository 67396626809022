import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {IDeviceAccessRequest, IDeviceAction, ILogger} from '../interfaces';
import { SessionService, UserService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevicesService extends QDCApiService<IDeviceAccessRequest> {

  protected readonly log: ILogger;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(http, environment.devicesApi, logService, router, oauthService, sessionService, userService);
    this.log = logService.get('Device Service');
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      if (url.includes('requestaccess')) {
        throw error.error;
      }
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  requestAccess(payload: IDeviceAccessRequest): Observable<any> {
    return super.submit(payload, `${environment.devicesApi}/requestaccess`);
  }

  deviceActions(payload: IDeviceAction, uuid: string): Observable<{id: number}> {
    return super.submit(payload, `${environment.devicesApi}/uuid/${uuid}/actions`);
  }

}
