<h1 mat-dialog-title>Confirm</h1>
<div mat-dialog-content>
  <div class="flex flex-col gap-[10px]">
    <div>{{message}}</div>
    <textarea pInputTextarea id="message-text-area"></textarea>
    <!-- <textarea pInputTextarea id="message-text-area" [(ngModel)]="data.text"></textarea> -->
  </div>
</div>
<br />
<div mat-dialog-actions class="flex justify-end items-end content-end">
  <q-button data-testid="cancel" variant="fill" color="primary" size="l" [mat-dialog-close]="false">
    {{cancelText}}
  </q-button>
  <q-button data-testid="ok" variant="fill" color="primary" size="l" [mat-dialog-close]="true">
    {{okText}}
  </q-button>
</div>
