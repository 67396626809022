import { HttpClient } from '@angular/common/http';
import { IQDCLog } from 'app/core/interfaces';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QDCLogService {

  constructor(private http: HttpClient, private oauthService: OAuthService) {
  }

  postJsonLog(log: IQDCLog): Observable<any> {
    log = this.verifyLogFields(log);
    return this.http.post(`${environment.qdcApi}${environment.logsApi}/SaveJsonLog`,
      log);
  }

  postJsonLogs(logs: IQDCLog[]): Observable<any> {
    return this.http.post(`${environment.qdcApi}${environment.logsApi}/SaveJsonLogs`,
      logs);
  }

  // Used as a safety measure to make sure
  // every field is a string so it can
  // be properly logged in api
  verifyLogFields(log: IQDCLog): IQDCLog {
    for(const prop in log) {
      if(typeof log[prop] !== 'string') {
        log[prop] = JSON.stringify(log[prop]);
      }
    }

    return log;
  }

}
