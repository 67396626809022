import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {v4 as uuidv4} from 'uuid';

export class APIUtility {

  static isLocalHost(): boolean {
    return window.location.hostname.includes('localhost') || window.location.hostname === '127.0.0.1';
  }

  static isLocalEnv(): boolean {
    return environment.name.toLowerCase() === 'local';
  }

  static isDevEnv(): boolean {
    return environment.name.toLowerCase() === 'dev';
  }

  static isTestEnv(): boolean {
    return environment.name.toLowerCase() === 'tst';
  }

  static isProdEnv(): boolean {
    return environment.name.toLowerCase() === 'prod-internal' || environment.name.toLowerCase() === 'prod-external' ||
      environment.name.toLowerCase() === 'tst-external';
  }

  static getLocalBillingServiceApi(): string {
    return 'https://billing.qdc-internal-dev.qualcomm.com';
  }

  static getLocalUmApi(): string {
    return 'https://usermanagement.qdc-internal-dev.qualcomm.com';
  }

  static getLocalBuilderApi(): string {
    return 'https://builder.qdc-internal-dev.qualcomm.com';
  }

  static getLocalNmApi(): string {
    return 'https://notificationmanagement.qdc-internal-dev.qualcomm.com';
  }


  static buildHeaders(accessToken: string, additionalHeaders?: HttpHeaders): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Accept', '*/*')
      .set('Authorization', `Bearer ${accessToken}`)
      .set('Content-Type', 'application/json')
      .set('X-QCOM-TracingID', uuidv4())
      .set('X-QCOM-AppName', environment.apigeeConfig.appName)
      .set('X-QCOM-ClientType', environment.apigeeConfig.clientType)
      .set('X-QCOM-TokenType', environment.apigeeConfig.tokenType)
      .set('X-QCOM-ClientId', environment.apigeeConfig.clientId);

    if (additionalHeaders?.keys().length) {
      additionalHeaders?.keys().forEach((k) => {
        // If k !== 'X-QCOM-TracingID' => If tracing id is passed in header replace it with the passed value
        if (headers.has(k) && k !== 'X-QCOM-TracingID') {
          headers = headers.append(k, additionalHeaders.get(k)!);
        } else {
          headers = headers.set(k, additionalHeaders.get(k)!);
        }
      });
    }

    return headers;
  }

  static buildHeadersAsQueryParams(accessToken: string): string {
    const headers = this.buildHeaders(accessToken, new HttpHeaders());
    const keys = headers.keys();
    let queryParams = '';
    keys.forEach((key) => {
      if (key !== 'Accept' && key !== 'Content-Type') {
        const value = headers.get(key);
        if (value) {
          queryParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
        }
      }
    });
    return queryParams.slice(0, -1);
  }


  // Error message can be any of the error objects
  // returned by all of our services
  static getApiErrorMessage(error: any): string {
    try {
      // These four are the most common cases
      if (typeof error === 'string') {
        return error;
      }

      if ('errorMessage' in error) {
        return error['errorMessage'];
      }

      if ('ErrorMessage' in error) {
        return error['ErrorMessage'];
      }

      if ('message' in error) {
        return error['message'];
      }

      if ('errorResponse' in error && 'errorMessage' in error['errorResponse']) {
        return error.errorResponse['errorMessage'];
      }

      // Default error message if we cannot get a nice one from the api
      return 'An issue occurred while processing your request, please refresh and try again.';

    } catch {
      // Send default error incase an exception gets thrown fetching one
      return 'An issue occurred while processing your request, please refresh and try again.';
    }
  }
}
