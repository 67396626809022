import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IBuildRecompileRequest, IBuilderBuilds, IBuilderImages, IBuilderSoftwareProducts, IDictionary } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BuilderService extends QDCApiService<IBuildRecompileRequest> {

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    sessionService: SessionService,
    userService: UserService,
  ) {
    super(http, environment.builderServiceApi, logService, router, oauthService, sessionService, userService);
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: HttpErrorResponse): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  // Returns User-Entitled and Active Software Products
  getSoftwareProductsByChipset(chipset: string, spBaseName: string, supportedOS: string[]):
    Observable<IBuilderSoftwareProducts> {
    const query: IDictionary | undefined = supportedOS.length ? {['operatingsystems']: supportedOS.join(',')} : undefined;

    return super.getAny(`/softwareproducts/${chipset}/${spBaseName}`, undefined, query);
  }

  getEntitledBuildsBySoftwareProduct(softwareProduct: string): Observable<IBuilderBuilds> {
    return super.getAny(`/builds/${softwareProduct}`);
  }

  getImagesByBuild(build: string): Observable<IBuilderImages> {
    return super.getAny(`/images/${build}`);
  }
}
