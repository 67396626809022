import { ILogService, ILogger } from 'app/core/interfaces';

export class Logger implements ILogger {

  constructor(public readonly owner: string, private logService: ILogService) {
  }

  debug(message: string, additionalFields?: Record<string, string>): void {
    this.logService.debug(this.owner, message, additionalFields);
  }

  info(message: string, additionalFields?: Record<string, string>): void {
    this.logService.info(this.owner, message, additionalFields);
  }
  warn(message: string, additionalFields?: Record<string, string>): void {
    this.logService.warn(this.owner, message, additionalFields);
  }

  error(message: string, error?: any, additionalFields?: Record<string, string>): void {
    this.logService.error(this.owner, message, error, additionalFields);
  }

  fatal(message: string, error?: any, additionalFields?: Record<string, string>): void {
    this.logService.fatal(this.owner, message, error, additionalFields);
  }

  success(message: string, additionalFields?: Record<string, string>): void {
    this.logService.success(this.owner, message, additionalFields);
  }

  httpjs(requestMethod: string, url: string, payload?: string,
    additionalFields?: Record<string, string>): void {
    this.logService.httpjs(this.owner, requestMethod, url, payload, additionalFields);
  }
}
