import { Component } from '@angular/core';
import { Icons } from 'app/core/constants/icons';
import { SessionService } from 'app/core/services';

@Component({
  selector: 'qdc-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent {

  constructor(private sessionService: SessionService, public icons: Icons) {
  }

  returnHome(): void {
    this.sessionService.logoutRequested();
  }

}
