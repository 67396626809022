// Angular Modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule } from '@angular/router';

// QUI Modules
import {
  QBadgeModule,
  QButtonModule,
  QIconButtonModule,
  QIconModule,
  QInlineAlertModule,
  QLinkModule,
  QOverlayPanelModule,
  QProgressBarModule,
  QRadioModule,
  QSkeletonComponent,
  QStatusModule,
  QStepperModule,
  QTextAreaModule,
  QTooltipModule,
} from '@qui/angular';

// Angular Material Modules
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

// PrimeNG Modules
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';

// Services
import { DateService, LogService } from 'app/core/services';

// Shared Module Components
import { ChipComponent } from './components/chip/chip.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormComponent } from './components/form/form.component';
import { JobStatusComponent } from './components/job-status/job-status.component';
import { MessageComponent } from './components/message/message.component';
import { OkDialogComponent } from './components/ok-dialog/ok-dialog.component';
import { PagerComponent } from './components/pager/pager.component';
import { PanelComponent } from './components/panel-list/panel/panel.component';
import { PanelListComponent } from './components/panel-list/panel-list.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TableComponent } from './components/table/table.component';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';

// Shared Module Pipes
import { ColumnFilterTypePipe, CrashCountPipe, EnumPipe, FileExtensionPipe, FileNamePipe, FileSizePipe, FileTypePipe, FormModelPipe, JobResultPipe, JobStatePipe, SanitizeHtmlPipe, SanitizeResourcePipe, SanitizeUrlPipe, SplitCamelCasePipe } from './pipes';

@NgModule({
  declarations: [
    // Components
    ChipComponent,
    ConfirmDialogComponent,
    FileUploadComponent,
    FormComponent,
    JobStatusComponent,
    MessageComponent,
    OkDialogComponent,
    PagerComponent,
    PanelComponent,
    PanelListComponent,
    StepperComponent,
    TableComponent,
    TextDialogComponent,
    WarningDialogComponent,

    // Pipes
    ColumnFilterTypePipe,
    CrashCountPipe,
    EnumPipe,
    FileExtensionPipe,
    FileNamePipe,
    FileSizePipe,
    FileTypePipe,
    FormModelPipe,
    JobResultPipe,
    JobStatePipe,
    SanitizeHtmlPipe,
    SanitizeResourcePipe,
    SanitizeUrlPipe,
    SplitCamelCasePipe,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    RouterModule,

    // QUI Component module
    QBadgeModule,
    QButtonModule,
    QIconButtonModule,
    QIconModule,
    QInlineAlertModule,
    QLinkModule,
    QOverlayPanelModule,
    QProgressBarModule,
    QRadioModule,
    QStatusModule,
    QStepperModule,
    QTextAreaModule,
    QTooltipModule,
    QSkeletonComponent,

    // Angular Material Modules
    MatBadgeModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,

    // PrimeNG modules
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ChipModule,
    DataViewModule,
    DialogModule,
    DragDropModule,
    FileUploadModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    KeyFilterModule,
    ListboxModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelMenuModule,
    PanelModule,
    RadioButtonModule,
    SelectButtonModule,
    TableModule,
    ToastModule,
    ToolbarModule,
  ],
  exports: [
    // QDC Components
    ChipComponent,
    ConfirmDialogComponent,
    FileUploadComponent,
    FormComponent,
    JobStatusComponent,
    MessageComponent,
    OkDialogComponent,
    PagerComponent,
    PanelComponent,
    PanelListComponent,
    StepperComponent,
    TableComponent,
    TextDialogComponent,
    WarningDialogComponent,

    // Pipes
    ColumnFilterTypePipe,
    CrashCountPipe,
    EnumPipe,
    FileExtensionPipe,
    FileNamePipe,
    FileSizePipe,
    FileTypePipe,
    FormModelPipe,
    JobResultPipe,
    JobStatePipe,
    SanitizeHtmlPipe,
    SanitizeResourcePipe,
    SanitizeUrlPipe,
    SplitCamelCasePipe,
  ],
  providers: [
    LogService,
    DateService,
  ],
})
export class SharedModule { }
