import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Strings } from 'app/core/constants/strings';

@Component({
  selector: 'qdc-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent {

  okText = 'OK';
  message: string = '';
  styleClass: string | undefined;

  constructor(
    private dialogRef: MatDialogRef<WarningDialogComponent>, private strings: Strings,
    @Optional() @Inject(MAT_DIALOG_DATA) private data?: any) {
    if (data) {
      this.okText = data.okText || this.strings.dialog.ok;
      this.message = data.message;
      this.styleClass = data.styleClass;
    }
  }
}
