// Angular Modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule } from '@angular/router';

// QDC Modules
// N/A

// QUI Modules
import {
  QButtonModule,
  QCheckboxModule,
  QDeviceCardModule,
  QIconButtonModule,
  QIconModule,
  QInlineAlertModule,
  QProgressCircleModule,
  QSkeletonComponent,
  QStatusModule,
  QTooltipModule,
} from '@qui/angular';

// PrimeNG Modules
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
// N/A

// Services
import { LogService } from 'app/core/services';

// Guards
// N/A

// Device Card Module Components
import { DeviceCardComponent } from './components/device-card/device-card.component';
import { DeviceCardOptionsDialogComponent } from './components/device-card-options-dialog/device-card-options-dialog.component';
import {DeviceStateComponent} from './components/device-state/device-state.component';
import { RequestMinutesDialogComponent } from './components/request-minutes-dialog/request-minutes-dialog.component';
import { SharedModule } from 'app/shared/shared.module';
import { UnlockMinutesBannerComponent } from './components/unlock-minutes-banner/unlock-minutes-banner.component';
import { UnlockMinutesPageComponent } from './components/unlock-minutes-page/unlock-minutes-page.component';
@NgModule({
  declarations: [
    DeviceCardComponent,
    DeviceCardOptionsDialogComponent,
    DeviceStateComponent,
    RequestMinutesDialogComponent,
    UnlockMinutesBannerComponent,
    UnlockMinutesPageComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,

    // Routing Modules
    RouterModule,

    // QDC Modules
    SharedModule,

    // QUI Modules
    QButtonModule,
    QCheckboxModule,
    QDeviceCardModule,
    QIconButtonModule,
    QIconModule,
    QInlineAlertModule,
    QProgressCircleModule,
    QSkeletonComponent,
    QStatusModule,
    QTooltipModule,

    // PrimeNG Modules
    CardModule,
    ChipModule,
    DialogModule,
    DropdownModule,
    ProgressSpinnerModule,
  ],
  exports: [
    DeviceCardComponent,
    DeviceCardOptionsDialogComponent,
    DeviceStateComponent,
    RequestMinutesDialogComponent,
    UnlockMinutesBannerComponent,
    UnlockMinutesPageComponent,
  ],
  providers: [
    LogService,
  ],
})
export class DeviceCardModule { }
