import { HttpClient } from '@angular/common/http';
import { IFileMetric } from '../interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class FileMetricsLogService {

  constructor(private http: HttpClient) {
  }

  public logFileMetrics(requestMethod: string, owner: string, metric: IFileMetric): Observable<any> {

    const log: Record<string, string> = {
      content: `HTTP Request: ${requestMethod}`,
      timestamp: new Date().toISOString(),
      source: owner,
      hostname: navigator.userAgent,
    };

    Object.keys(metric).forEach((key: string) => {
      log[key] = metric[key as keyof typeof metric];
    });
    return this.http.post(`${environment.qdcApi}${environment.logsApi}/SaveJsonLog`, log);
  }
}
