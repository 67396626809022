import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IAdobeDataLayerPage, IAdobeDataLayerVisitor } from '../interfaces';
import { filter, map, switchMap } from 'rxjs/operators';
import { CommonUtility } from '../utils/common-utility';
import { DarkModeService } from '@qui/angular';
import { DateService } from './date.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StringService } from './string.service';
import { Strings } from '../constants/strings';
import { Title } from '@angular/platform-browser';
import { UserService } from './user.service';

const SEPARATOR = ' > ';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private stringService: StringService,
    private location: Location,
    private darkModeService: DarkModeService,
    private userService: UserService,
    private strings: Strings,
  ) {
    this.init();
  }

  private history: string[] = [];
  private previousPage: string = '';
  private previousPageIsHomePage = false;
  private titleString = '';

  init(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // If user bookmarked previous site which has #, replace the # in route
        if (event.url && event.url.startsWith('/#')) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        this.history.push(this.activatedRoute.toString());
        return this.activatedRoute;
      }), // Loaded component route
      map(route => route.firstChild), // Current component's first child from router tree
      switchMap(route => route!.data), // Resolved data of this route
      map(() =>

        // Using url to create dynamic page title
        this.router.url.split('/').reduce((acc, frag) => {
          if (acc && frag) { acc += SEPARATOR; }
          return acc + this.stringService.capitalizeString(frag);
        }),
      ))
      .subscribe((pathString) => {
        // Variables for parsing browser tab title
        const routeArray = pathString.split(SEPARATOR);
        const lastRoute = routeArray[routeArray.length - 1].trim();

        // True if title starts with 'Logs', has 4 sections and the last is a number (executionId)
        const hideExecutionId = !Number.isNaN(lastRoute) && routeArray[0].trim() === 'Logs' && routeArray.length === 4;

        if (hideExecutionId) {
          const newRoute = pathString.substring(0, pathString.length - lastRoute.length - 3); // Remove execution Id
          this.titleString = newRoute ? `${newRoute} | ${this.strings.info.qdcTitle}` : this.strings.info.qdcTitle;
        } else {
          this.titleString = pathString ? `${pathString} | ${this.strings.info.qdcTitle}` : this.strings.info.qdcTitle;
        }
        this.titleService.setTitle(this.titleString);
        // Send data to Adobe launch
        this.sendAnalytics();
      });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/home', { replaceUrl: true });
    }
  }

  /* eslint-disable camelcase */
  sendAnalytics(): void {
    const userProfile = this.userService.getAdobeUserInfo();

    let currentUrl = this.router.url;
    // CommonUtility.stringIsNullOrEmpty(currentUrl) && !userProfile --> only when loading main public site
    if ((CommonUtility.stringIsNullOrEmpty(currentUrl) && !userProfile) ||
      (!CommonUtility.stringIsNullOrEmpty(currentUrl) && this.previousPage !== currentUrl)) {

      currentUrl = currentUrl.split('?')[0]; // Remove any query params
      if (currentUrl.startsWith('/')) {
        // Remove '/' at the start of path, else first element after split('/') will be empty
        currentUrl = currentUrl.substring(1);
      }
      const pageInfo = this.getPageData(currentUrl);
      const visitorInfo = this.getVisitorData(userProfile);
      // Define trackview()
      window.trackView = function() {
        window.adobeDataLayer = window.adobeDataLayer || [];
        window.adobeDataLayer.push({
          event: 'page-load',
          page: pageInfo,
          visitor: visitorInfo,
        });
      }
      // eslint-disable-next-line no-sparse-arrays
      window.otConsentCookie = decodeURIComponent((document.cookie.match(/^(?:.*;)?\s*OptanonConsent\s*=\s*([^;]+)(?:.*)?$/) || [, 'not-set'])[1]);

      if (window.otConsentCookie != null && window.otConsentCookie.indexOf('groups=') > -1 && window.otConsentCookie.indexOf(',C0002:1') > -1) {
        //C0002:1 stands for opt-in, C0002:0 stands for opt-out
        // If user has opted in, call trackview()
        window.trackView();
      }
    }
  }

  getPageData(path: string): IAdobeDataLayerPage {
    const dateTimeNow = DateService.getFormattedDate();
    const pagedata: IAdobeDataLayerPage = {
      device_orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
      language_code: 'en',
      page_name: '',
      previous_page_name: '',
      page_type: '',
      sc_site_section_1: '',
      sc_site_section_2: '',
      sc_site_section_3: '',
      sc_site_section_4: '',
      sc_site_section_5: '',
      sc_site_section_6: '',
      sc_site_section_7: '',
      sc_site_section_8: '',
      sc_site_section_9: '',
      page_publish_date: dateTimeNow,
      page_last_modified_date: dateTimeNow,
      website_theme: `${this.darkModeService.getMode()}`,
    };
    const mainSite = 'qc:qdc';
    let previousUrl = mainSite;
    const currentUrlSplit = path.split('/');
    if (currentUrlSplit.length) {
      pagedata.previous_page_name = CommonUtility.stringIsNullOrEmpty(this.previousPage) ? '' : `${mainSite}:${this.previousPage.replace(/\//g, ':')}`;
      if (this.previousPageIsHomePage) {
        // Replace main site with 'home page'
        pagedata.previous_page_name = `${mainSite}:home page`;
        this.previousPageIsHomePage = false;
      }
      this.previousPage = path;
      pagedata.page_type = `${currentUrlSplit[currentUrlSplit.length - 1]}`;

      // Split URL and update site section
      if (currentUrlSplit.length === 1) {
        if (CommonUtility.stringIsNullOrEmpty(currentUrlSplit[0])) {
          // This is main site page == 'home page' for Abobe
          pagedata['sc_site_section_1'] = `${mainSite}:home page`;
          pagedata.page_name = `${mainSite}:home page`;
          this.previousPageIsHomePage = true;
        } else {
          pagedata['sc_site_section_1'] = `${mainSite}:${currentUrlSplit[0]}`;
          pagedata.page_name = `${mainSite}:${currentUrlSplit[0]}`;
        }
      } else {
        for (let i = 0; i < currentUrlSplit.length; i++) {
          previousUrl = `${previousUrl}:${currentUrlSplit[i]}`;
          pagedata[`sc_site_section_${i + 1}`] = `${previousUrl}`;
        }
        pagedata.page_name = `${previousUrl}`;
      }
    }
    return pagedata;
  }

  getVisitorData(userProfile?: IAdobeDataLayerVisitor): IAdobeDataLayerVisitor {
    return {
      country_code: 'us',
      session_status: userProfile ? 'logged_in' : 'not_logged_in',
      org_id: userProfile ? `${userProfile?.org_id}` : '',
      org_name: userProfile ? `${userProfile?.org_name}` : '',
      qcguid: userProfile ? `${userProfile?.qcguid}` : '',
      user_domain: userProfile ? `${userProfile?.user_domain}` : '',
    }
  }

}
