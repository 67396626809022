import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { QDCApiService } from 'app/core/services/qdc-api.service';

import { ICartline, ICartlines, IOrderCheckoutRequest, IOrderResponse } from 'app/core/interfaces';
import {
  LogService,
  SessionService,
  UserService,
} from 'app/core/services';
import { APIUtility } from 'app/core/utils/api-utility';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartService extends QDCApiService<ICartlines> {
  protected baseUrl: string = APIUtility.isLocalEnv()
    ? APIUtility.getLocalBillingServiceApi()
    : environment.qdcApi;

  public cartLinesSubject$ = new BehaviorSubject<ICartline[]>([]);

  public get cartLines(): Observable<ICartline[]>{
    return this.cartLinesSubject$.asObservable();
  }

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(
      http,
      environment.cartApi,
      logService,
      router,
      oauthService,
      sessionService,
      userService,
    );
  }

  protected /* override */ customErrorHandler(
    mode: string,
    url: string,
    error: HttpErrorResponse,
  ): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  updateCart(cartLines: ICartline[]): Observable<ICartlines> {
    const update: ICartlines = { cartLines: cartLines };
    return this.updateAny(update, `${environment.cartApi}`).pipe(
      tap((res) => {
        const cartLns = res.cartLines
        this.cartLinesSubject$.next(cartLns);
      }));
  }

  getCart(): Observable<ICartlines> {
    return this.getAny().pipe(
      tap({
        next: (res) => {
          const cartLns = res.cartLines;
          this.cartLinesSubject$.next(cartLns);
        },
        error: (error) => {
          throw error;
        },
      }));
  }

  checkoutCart(obj: IOrderCheckoutRequest): Observable<IOrderResponse> {
    return super.submit(obj, `${environment.cartApi}/checkout`);
  }
}
