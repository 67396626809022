export enum OperationMode {
  Undefined,
  MIRRORONLY,
  SSHMIRROR,
  SSHONLY,
  SHELLONLY,
  SSHSHELL,
}

export enum JobState {
    Undefined,
    Submitted,
    Dispatched,
    Running,
    Completed,
    Aborted,
    Setup,
}

export enum JobResult {
    Undefined,
    Pending,
    Successful,
    Unsuccessful,
    Canceled,
    Error,
}

export enum JobType {
    Undefined,
    Automated,
    Interactive,
}

export enum JobMode {
    Undefined,
    Application,
    AIModel,
    StableDiffusion,
}

export enum TestFramework {
    Undefined,
    Monkey,
    Appium,
    PowerShell,
    Bash,
}

export enum ArtifactType {
    Undefined,
    MetaBuild,
    TestPackage,
    TestScript,
    TestLog,
    CrashDump,
    MetaBuildJson,
    TaskLog,
    BuildImage,
}

export enum StorageType {
    Undefined,
    OnPrem,
    AWS,
}

export enum Region {
    Undefined,
    NA,
}

export enum TestResultType {
    Undefined,
    Result,
    SubResult,
    IterationResult,
}

export enum CrashType {
    None = 'NONE',
    System = 'System',
    ANR = 'ANR',
    TombStone = 'TombStone',
}

export enum OS {
    Android = 0,
    Ubuntu = 1,
    Windows = 2,
    QNX = 3,
    Linux = 4,
}

export enum DeviceType {
  OnPremDevice = 0,
  VirtualDevice = 1,
}

export enum RibbonType {
    New = 'New',
    CameraCapability = 'CameraCapability',
    ComingSoon = 'ComingSoon',
    EarlyAccess = 'EarlyAccess',
    VirtualPlatform = 'VirtualPlatform',
}

export enum TargetState {
    Undefined = 0,
    AvailableNow = 1,
    DevicesBusy = 2,
    Unavailable = 3,
}

export enum DeviceMinutesRequestState {
  Form = 0,
  Loading = 1,
  Success = 2,
  Error = 3,
}

export enum LogUploadStatus {
    Undefined = 0,
    InProgress = 1,
    Completed = 2,
    Failed = 3,
    NoLogs = 4,
}

export enum ChipsetCategory {
    Undefined,
    Mobile,
    Compute,
    IOT,
    Automotive,
}

export enum QuickTourTypes {
    MobileInteractive,
    ComputeInteractive,
}

export enum AnnouncementType {
  Neutral = 'neutral',
  Informative = 'informative',
  Positive = 'positive',
  Warning = 'warning',
  Negative = 'negative',
}

export enum DeviceStreamingState {
  Offline = 'Offline',
  Online = 'Online',
  Crashed = 'Crashed',
}

export enum BuildType {
    MetaBuild = 0,
    UserCreatedBuild = 1,
    DeviceSnapshot = 2
}

export enum ErrorMsgType {
  Undefined,
  Auth,
  Init,
  Terms,
  OpenId,
}

export enum NotesLevel {
  Developer = 'Developer',
  User = 'User',
  Streaming = 'Streaming',
  Setup = 'Setup',
}

export enum SshDialogStepNumber {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
}

export enum AAEvent {
  // Megamenu

  // CTA
  CTAGeneric = 'cta',
  CTABlog = 'blog',
  CTATab = 'tab',
  CTABreadcrumb = 'breadcrumb',

  // Form Tracking

  // Other
  Video = 'video',
  SocialShare = 'socialShare',
}
