// Angular Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';

// QDC Modules
import { ClickDirective } from '../../core/directives';
import { DeviceCardModule } from '../device-card/device-card.module';

// QUI Modules
import { QAccordionModule, QButtonModule, QDividerModule, QIconButtonModule, QIconModule } from '@qui/angular';

// PrimeNG Modules
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { SidebarModule } from 'primeng/sidebar';

// Services
import { LogService } from 'app/core/services';

// LandingModule Components
import { LandingComponent } from './components/landing/landing.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LandingComponent },
];

@NgModule({
  declarations: [
    LandingComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,

    // Routing Modules
    QuicklinkModule,
    RouterModule.forChild(routes),

    // QDC Modules
    ClickDirective,
    DeviceCardModule,

    // QUI Modules
    QAccordionModule,
    QButtonModule,
    QDividerModule,
    QIconModule,
    QIconButtonModule,

    // PrimeNG Modules
    CardModule,
    CarouselModule,
    SidebarModule,
  ],
  exports: [
    LandingComponent,
    QuicklinkModule,
    RouterModule,
  ],
  providers: [
    LogService,
  ],
})
export class LandingModule { }
