import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IDictionary, IPagedCollection, IQdcOrder } from 'app/core/interfaces';
import {
  LogService,
  QDCApiService,
  SessionService,
  UserService,
} from 'app/core/services';
import { APIUtility } from 'app/core/utils/api-utility';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SalesOrderService extends QDCApiService<IQdcOrder> {
  protected baseUrl: string = APIUtility.isLocalEnv()
    ? APIUtility.getLocalBillingServiceApi()
    : environment.qdcApi;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(
      http,
      environment.salesOrderApi,
      logService,
      router,
      oauthService,
      sessionService,
      userService,
    );
  }

  protected /* override */ customErrorHandler(
    mode: string,
    url: string,
    error: HttpErrorResponse,
  ): string {
    if (error && error.error) {
      return super.getErrorMessage(error.error);
    }
    return '';
  }

  getOrderhistory(page: number = 1):  Observable<IPagedCollection<IQdcOrder>> {
    const query: IDictionary = {
      ['$page']: page,
    };
    return this.getAny(`/qdcorder/orderhistory`, undefined, query);
  }
}
