<div class="ok-dialog">
  <span mat-dialog-title style="padding-bottom: 20px; margin-top: -10px;">{{title}}</span>
  <mat-dialog-content>
    <div class="spanText fontFamily pb-[25px]" [innerHTML]="message"></div>
  </mat-dialog-content>
  <div mat-dialog-actions class="flex items-center content-center gap-[10px]">
    <q-button data-testid="ok" variant="fill" color="primary" [mat-dialog-close]="true">
      {{okText}}
    </q-button>
  </div>
</div>
