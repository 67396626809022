// Angular Modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Routing Modules
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';

// QDC Modules

// QUI Modules
import { QButtonModule } from '@qui/angular';

// PrimeNG Modules

// Services

// Guards

// SessionExpiredModule Components
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', component: SessionExpiredComponent },
];

@NgModule({
  declarations: [
    SessionExpiredComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,

    // Routing Modules
    QuicklinkModule,
    RouterModule.forChild(routes),

    // QDC Modules

    // QUI Modules
    QButtonModule,

    // PrimeNG Modules
  ],
  exports: [
    SessionExpiredComponent,
    QuicklinkModule,
    RouterModule,
  ],
})
export class SessionExpiredModule { }
