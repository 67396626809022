/* eslint-disable */

import { Injectable } from '@angular/core';

@Injectable()
export class StringService {

  // Matches UpperCamelCase (i.e. Title Case) and lowerCamelCase
  matchCamelCase(val: string): RegExpMatchArray | null {
    return val.match(/([A-Za-z]{1})([^A-Z]+)/g);
  }

  splitCamelCase(val: string): string {
    return val.replace(/([A-Z])/g, ' $1').replace(/^./, x => x.toUpperCase());
  }

  toTitleCase(val: string): string {
    return val.replace(/\w\S*/g, x => x.charAt(0).toUpperCase() + x.substr(1).toLowerCase());
  }

  splitTitleCase(val: string, seperator?: string): string {
    if (val) {
      const camel = this.matchCamelCase(val);
      if (seperator && camel) {
        return camel.join(seperator);
      } else if (camel) {
        return camel.join(' ');
      }
    }
    return val;
  }

  duplicateNameRegExMatch(errorStr: string, val: string): RegExpMatchArray | null {
    return errorStr.match(
      new RegExp(`Cannot insert duplicate key row in object 'dbo.${val}' with unique index 'IX_NameTaxonomyIdUnique'`));
  }

  matchOnlyNumbers(val: string): RegExpMatchArray | null {
    return val.match(/(^[0-9]*$)/);
  }
  matchalphaNumericFormat(val: string): RegExpMatchArray | null{
    return val.match(/(^[A-Za-z]+-[0-9]+$)/);
  }

  convertHtmlToWiki(htmlString: string): string {
    // Parse HTML to wiki markup supported by Jira
    let wikiMarkUpString = htmlString.replace(/<p><br><\/p>/g, '\n');
    wikiMarkUpString = wikiMarkUpString.replace(/<p[^>]*>/g, '\n');
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?a[^>]*>/g, '');
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?ul[^>]*>/g, '').replace(/<li[^>]*>/g, '* ');
    wikiMarkUpString = wikiMarkUpString.replace(/<\/p[^>]*>/g, '\n');
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?strong[^>]*>/g, '*'); // Bold
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?u[^>]*>/g, '+'); // Underline
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?em[^>]*>/g, '_'); // Italic
    wikiMarkUpString = wikiMarkUpString.replace(/<\/li>/g, '\n');
    wikiMarkUpString = wikiMarkUpString.replace(/<\/?span[^>]*>/g, '');
    return wikiMarkUpString;
  }

  capitalizeString(str: string): string {
    if (!str) { return str; }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  splitTitleCaseByNewline(input: string): string {
    /* From : FormFactor: MTP; MCNRev: CAB; Storage Type: UFS; Chipset: SM8350; ChipsetRev: V2.1V2.0
     to : FormFactor: MTP
          MCNRev: CAB
          Storage Type: UFS
          Chipset: SM8350
          ChipsetRev: V2.1V2.0
     */
    return input.replace(/\;/gi, '\n');
  }

  substringAfterLastChar(str: string, char: string): string {
    switch (char) {
    // Handle special regex character \
    case '\\':
      const specialMatch = str.match(/[^\\]+$/);
      return specialMatch ? specialMatch[0] : '';
    default:
      const re = new RegExp('[^' + char + ']+$');
      const match = str.match(re);
      return match ? match[0] : '';
    }
  }

  replaceSpaceWithChar(value: string, char: string): string{
    return value ? value.replace(/\s/g, char).toLowerCase() : value;
  }

  replaceInvalidCharacters(str: string, char: string): string {
    const validCharacter = encodeURIComponent(char);
    return decodeURIComponent(encodeURIComponent(str).split('%EF%BF%BD').join(validCharacter));
  }
}
