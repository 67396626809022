import { Observable, Subject } from 'rxjs';
import { APIUtility } from 'app/core/utils/api-utility';
import { HttpClient } from '@angular/common/http';
import { INotificationTrigger } from '../interfaces';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { QDCApiService } from './qdc-api.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import Stomp from 'stompjs';
import { UserService } from './user.service';
import { WebsocketService } from './websocket.service';
import { environment } from 'environments/environment';


@Injectable()
export class NotificationService extends QDCApiService<INotificationTrigger> {
  // To Do: Add details about notifications received
  public newNotification = new Subject<Stomp.Message>;
  private wsService: WebsocketService;
  connected: boolean = false;

  constructor(
    http: HttpClient,
    logService: LogService,
    router: Router,
    oauthService: OAuthService,
    sessionService: SessionService,
    userService: UserService,
    wsService: WebsocketService,
  ) {
    super(http, environment.notificationApi, logService, router, oauthService, sessionService, userService);
    this.wsService = wsService
  }

  connect(topic?: string): void {
    this.connected = this.wsService.connected;
    if (!this.wsService.connected) {
      const baseUrl: string = APIUtility.isLocalEnv() ? APIUtility.getLocalNmApi() : environment.qdcApi;
      const webSocketEndpoint = `${baseUrl + environment.nmWsApi}?${APIUtility.buildHeadersAsQueryParams(this.oauthService.getAccessToken())}`;
      this.wsService.connect(webSocketEndpoint, topic);
      this.wsService.responseSubject.subscribe((message: Stomp.Message): void => {
        this.newNotification.next(message);
      })
    }
  }

  disconnect(): void {
    this.wsService.disconnect();
    this.connected = this.wsService.connected;
  }

  notificationTrigger(payload: INotificationTrigger): Observable<INotificationTrigger> {
    return super.submit(payload, `${environment.notificationApi}/triggers`);
  }
}
