import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonUtility } from 'app/core/utils/common-utility';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'app/core/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(
    private userService: UserService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const validUser = !CommonUtility.stringIsNullOrEmpty(this.userService.getUsername()) &&
      this.userService.isLoggedIn();

    return validUser;
  }
}
