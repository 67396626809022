import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDictionary, IStreamingURL } from 'app/core/interfaces';
import { LogService, SessionService, UserService } from 'app/core/services';
import { Observable, Subject } from 'rxjs';
import { ArtifactType } from 'app/core/enums';
import { HttpStatus } from 'app/core/classes/http-status';
import { Injectable } from '@angular/core';
import { Logs } from 'app/features/logs/classes/logs';
import { OAuthService } from 'angular-oauth2-oidc';
import { QDCApiService } from 'app/core/services/qdc-api.service';
import { Router } from '@angular/router';
import { defaults } from 'app/core/constants/defaults';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogContentService extends QDCApiService<Logs> {

  private storageBaseUrl: string = environment.qdcApi + environment.storageApi;

  private jobId = new Subject<number>();

  jobId$ = this.jobId.asObservable();

  constructor(protected http: HttpClient, protected logService: LogService, protected router: Router,
    protected oauthService: OAuthService, protected sessionService: SessionService,
    protected userService: UserService) {
    super(http, environment.listfilesApi, logService, router, oauthService, sessionService, userService);

    if (environment.name === 'local') {
      this.baseUrl = environment.storageApi;
      this.storageBaseUrl = environment.storageApi;
    }
  }

  protected /* override */ customErrorHandler(mode: string, url: string, error: any): string {
    this.suppressLogger = false;

    // Suppress all 404s
    if (error && error.status === HttpStatus.notFound) {
      this.suppressLogger = true;
      return '';
    }

    return super.customErrorHandler(mode, url, error);
  }

  setJobId(id: number): void {
    this.jobId.next(id);
  }

  getDirectoryContents(virtualPath: string, page: number, pageSize: number,
    filters: { key: string, value: string }[], sort: string, category: ArtifactType): Observable<Logs> {
    const query = this.buildQuery(page, pageSize, sort, filters,
      { virtualPath: virtualPath, category: category });
    return super.getAny('', false, query);
  }

  private buildQuery(
    page?: number,
    pageSize?: number,
    sort?: string,
    filters?: { key: string, value: string }[],
    paramsObject?: any,
  ): IDictionary {
    const object: IDictionary = {
      basePath: paramsObject.basePath,
      virtualFolder: paramsObject.virtualPath,
      page: page,
      pageSize: pageSize,
      sort: [sort],
      category: paramsObject.category,
    };

    filters?.forEach((f, i) => {
      object[`filters[${i}].key`] = f.key;
      object[`filters[${i}].value`] = f.value;
    });

    return object;
  }

  getLogFileContents(virtualPath: string, category: ArtifactType): Observable<string> {
    return super.getFileContents(this.buildContentUrl(virtualPath, category));
  }

  downloadLogFileContents(virtualPath: string, category: ArtifactType): Observable<HttpResponse<Blob>> {
    return this.downloadFileContent(this.buildContentUrl(virtualPath, category));
  }

  buildContentUrl(virtualPath: string, category: ArtifactType): string {
    return `${this.storageBaseUrl}/download?virtualPath=${encodeURIComponent(virtualPath)}&category=${category}`;
  }

  // Download Multiple files/folders using virtualPath(s) in comma delimited list
  downloadMultiple(virtualPathList: string, category: ArtifactType): Observable<HttpResponse<Blob>> {
    return this.downloadFileContent(
      `${this.storageBaseUrl}/download/files?virtualPathList=${encodeURIComponent(virtualPathList)}&category=${category}&delimiter=${defaults.logDelimiter}`);
  }

  getStreamingUrl(virtualPath: string, category: ArtifactType): Observable<IStreamingURL> {
    const query = {
      ['virtualPath']: encodeURIComponent(virtualPath),
      ['category']: category,
      ['ttlHours']: defaults.videoStreamingUrlDuration,
    };

    return this.getAny(environment.qdcApi + environment.storageApi + '/getstreamingurl', true, query);
  }
}
